import {i18n} from '@/plugins/i18n';

export const documentColumns = [
  {
    field: 'filename',
    header: i18n.t('documents.attrs.description'),
    filterLbl: i18n.t('documents.attrs.description_contains'),
    isChecked: true,
    elType: 'text',
    clickable: true,
    prependIcon: true,
    getIcon: (row) => {
      switch (row.file_ext) {
        case "xls,xlsx":
          return require('@/assets/document_xls.svg')
        case "doc,docx":
          return require('@/assets/document_doc.svg')
        case "pdf":
          return require('@/assets/document_pdf.svg')
        default:
          return require('@/assets/document_generic.svg')
      }
    },
    menus: [
      {
        id: 'd.ga.options', // user management grid action options
        icon: 'verticle-dots.svg',
        class: 'ellipsis-v',
        items: [
          {
            id: 'd.ga.options.view',
            icon: 'document_view_icon',
            label: i18n.t('documents.menu.view')
          }, {
            id: 'd.ga.options.rename',
            icon: 'rename_icon',
            label: i18n.t('documents.rename')
          }, {
            id: 'd.ga.options.delete',
            icon: 'delete_icon',
            label: i18n.t('documents.menu.delete'),
            "action_endpoint": 'document/delete',
            "action_confirmation": {
              show: true
            }
          }
        ]
      }
    ]
  }, {
    field: 'datev_dms',
    header: i18n.t('documents.attrs.datev_dms.header'),
    isChecked: true,
    colElType: 'select',
    dataSource: [{'name': i18n.t('documents.attrs.datev_dms.option_no'), 'code': '0'}, {'name': i18n.t('documents.attrs.datev_dms.option_yes'), 'code': '1'}],
    model: 'datev_dms',
  },  {
    field: 'datev_transfered_at',
    header: i18n.t('documents.attrs.datev_dms.transfered_header'),
    isChecked: true,
    clickable: (row) => {
      return row.datev_transfered_at && row.datev_transfered_at === i18n.t('documents.attrs.datev_dms.transfer_now');
    },
    model: 'datev_transfered_at',
  }, {
    field: 'visible_in_client_portal',
    header: i18n.t('documents.attrs.visible_in_client_portal'),
    colElType: 'checkbox',
    isChecked: true
  }, {
    field: 'created_by',
    header: i18n.t('documents.attrs.created_by'),
    isChecked: true,
    elType: 'user_select'
  }, {
    field: 'uploaded_at',
    header: i18n.t('documents.attrs.uploaded_at'),
    isChecked: true,
    elType: 'date_range',
    operator: 'between'
  }, {
    field: 'file_size',
    header: i18n.t('documents.attrs.file_size'),
    isChecked: true
  }
];

export const documentStandardFilters = [
  {
    selected: true,
    default: true,
    name: i18n.t('documents.standard_filters.all_documents'),
    id: 'all_documents'
  }, {
    selected: false,
    default: false,
    name: i18n.t('documents.standard_filters.today_documents'),
    id: 'today_documents'
  }, {
    selected: false,
    default: false,
    name: i18n.t('documents.standard_filters.yesterday_documents'),
    id: 'yesterday_documents'
  }, {
    selected: false,
    default: false,
    name: i18n.t('documents.standard_filters.current_month_documents'),
    id: 'current_month_documents'
  }, {
    selected: false,
    default: false,
    name: i18n.t('documents.standard_filters.last_month_documents'),
    id: 'last_month_documents'
  }, {
    selected: false,
    default: false,
    name: i18n.t('documents.standard_filters.current_year_documents'),
    id: 'current_year_documents'
  }, {
    selected: false,
    default: false,
    name: i18n.t('documents.standard_filters.last_year_documents'),
    id: 'last_year_documents'
  }
];

function getAssetPath(name) {
  return require('@/assets/' + name);
}

export const documentMenus = [
  {
    id: "d.view",
    "icon": getAssetPath('eye_green.svg'),
    "icon_disabled": getAssetPath('eye_gray.svg'),
    "title": i18n.t('documents.menu.view'),
    "disable_on_multi": true,
    "enable_on_select": true,
    "disabled": true
  }, {
    id: "d.upload",
    "icon": getAssetPath('upload_green.svg'),
    "title": i18n.t('documents.menu.upload')
  }, {
    id: "d.delete",
    "icon": getAssetPath('delete_user.svg'),
    "icon_disabled": require('@/assets/delete_user_gray.svg'),
    "title": i18n.t('documents.menu.delete'),
    "action_endpoint": 'document/delete',
    "action_confirmation": {
      show: true
    },
    "disable_on_multi": false,
    "enable_on_select": true,
    "disabled": true
  }, {
    id: "d.sendDocument",
    "icon": getAssetPath('plane.svg'),
    "icon_disabled": require('@/assets/plane_gray.svg'),
    "title": i18n.t('documents.menu.sendDocument'),
    "disable_on_multi": false,
    "enable_on_select": true,
    "disabled": true
  },
  {
    id: 'd.dmsToggle',
    canShow: false,
    icon: getAssetPath('datev_green.svg'),
    icon_disabled: require('@/assets/datev_gray.svg'),
    title: i18n.t('documents.menu.dms_toggle'),
    sub_menu: {
      items: [
        {
          id: 'd.dmsToggle.yes',
          label: i18n.t('documents.attrs.datev_dms.option_yes')
        },
        {
          id: 'd.dmsToggle.no',
          label: i18n.t('documents.attrs.datev_dms.option_no')
        }
      ]
    },
    disable_on_multi: false,
    enable_on_select: true,
    disabled: true
  }
];

export const readOnlyDocumentMenus = [
  {
    id: "d.view",
    "icon": getAssetPath('eye_green.svg'),
    "icon_disabled": getAssetPath('eye_gray.svg'),
    "title": i18n.t('documents.menu.view'),
    "disable_on_multi": true,
    "enable_on_select": true,
    "disabled": true
  }
];

export const readOnlyDocumentColumnMenus = [
  {
    id: 'd.ga.options', // user management grid action options
    icon: 'verticle-dots.svg',
    class: 'ellipsis-v',
    items: [
      {
        id: 'd.ga.options.view',
        icon: 'edit_user_icon',
        label: i18n.t('documents.menu.view')
      }
    ]
  }
];
