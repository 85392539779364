<template v-slot:body>
    <div v-if="isVisible" class="flex flex-col justify-start">
      <div class="flex justify-around mt-2 space-x-10">
        <div class="w-full mt-2 elster-result-header">
          <div class="flex flex-col justify-center items-center">
            <div
              v-if="!hasElsterErrors"
              class="finish-check-icon-wrapper"
            >
              <img src="@/assets/check_done.svg"/>
            </div>
            <img v-else src="@/assets/warning.svg">
          </div>

          <p
            v-if="!hasElsterErrors"
            class="textbold my-4"
          >
            {{ $t('declarations.detail.elster_validation.success_message') }}
          </p>
          <p
            v-else
            class="textbold my-4"
          >
            {{ $t('declarations.detail.elster_validation.failure_message') }}
          </p>
        </div>
      </div>

      <div
        v-show="elsterValidationResults.length > 0"
        class="flex justify-around mt-2 space-x-10"
      >
        <DataTable
          :value="elsterValidationResults"
          :paginator="false"
          :totalRecords="elsterValidationResults.length"
        >
          <Column
            v-for="(col, columnIndex) of selectedColumns"
            :field="col.field"
            :header="col.header"
            :key="col.field + '_' + columnIndex"
            :headerStyle="{'background-color':'#f2f2f2', 'width': col.width ? col.width : '', 'text-align': 'left'}"
          >
            <template #body="slotProps">
              <div v-if="columnIndex === 0">
                <img
                  v-if="col.field === 'type' && slotProps.data[col.field] === 'error'"
                  src="@/assets/elster_validate_error.svg"
                  width="15px"
                >
                <img
                  v-if="col.field === 'type' && slotProps.data[col.field] === 'hint'"
                  src="@/assets/warning.svg"
                  width="15px"
                >
              </div>

              <div
                v-if="columnIndex === 2"
                class="text-left"
              >
                <span v-html="renderUrlsAsLinks(slotProps.data[col.field])"/>
              </div>

              <div
                v-if="columnIndex !== 0 && columnIndex !== 2"
                class="text-left"
              >
                <span
                  v-if="col.field === 'name'"
                  v-tooltip.right="slotProps.data['field']"
                >
                  {{ slotProps.data[col.field] }}
                </span>
                <span v-else>{{ slotProps.data[col.field] }}</span>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
</template>

<script>
import { PropertyService } from '@/services/property.service';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';

export default {
  name: 'ElsterValidationResults',
  directives: {
    'tooltip': Tooltip,
  },
  components: {
    Column,
    DataTable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    elsterValidationResultsList: {
      type: Array,
      default: () => []
    },
    federalStateCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      service: new PropertyService(),
      selectedColumns: [
        {
          field: 'type',
          header:  this.$t('declarations.detail.elster_validation.colummns.type'),
          width: '80px',
          isChecked: true,
          sortable: false
        }, {
          field: 'name',
          header:  this.$t('declarations.detail.elster_validation.colummns.name'),
          width: '140px',
          isChecked: true,
          sortable: false
        }, {
          field: 'readable',
          header:  this.$t('declarations.detail.elster_validation.colummns.readable'),
          width: '100%',
          isChecked: true,
          sortable: false
        }, {
          field: 'description',
          header:  this.$t('declarations.detail.elster_validation.colummns.description'),
          width: '250px',
          isChecked: true,
          sortable: false
        }
      ],
      elsterFieldDefinitionsList: []
    }
  },
  computed: {
    isVisible() {
      if (this.visible === true) {
        this.fetchElsterFieldDefinitions();
      }

      return this.visible;
    },
    hasElsterErrors() {
      let hasErrors = false;

      this.elsterValidationResultsList.forEach((elsterResult) => {
        if (elsterResult.type === 'error') {
          hasErrors = true;
        }
      });

      return hasErrors;
    },
    elsterValidationResults() {
      let elsterValidationResults = [];

      this.elsterValidationResultsList.forEach(elsterValidationResult => {
        let fieldSheet = '';
        let fieldName = '';
        let errorFields = elsterValidationResult?.field?.split('/') || [];
        if (errorFields.length > 1) {
          fieldSheet = errorFields[1].slice(0, -3);
          fieldName = errorFields[errorFields.length - 1].slice(0, -3);
        }

        let definition = (this.elsterFieldDefinitionsList.find(d => d.sheet === fieldSheet && d.name === fieldName) || {});

        let definitionName = definition?.name || null;
        if (definitionName === null && fieldName?.startsWith('E')) {
          definitionName = fieldName;
        }

        let definitionDescription = (definition?.description || null) ?? '';

        elsterValidationResults.push({
          type: elsterValidationResult.type,
          name: definitionName,
          readable: elsterValidationResult.readable,
          description: definitionDescription,
          field: elsterValidationResult.field
        });
      });

      return elsterValidationResults;
    }
  },
  methods: {
    fetchElsterFieldDefinitions() {
      this.elsterFieldDefinitionsList = [];

      this.service.elsterFieldDefinitions(this.federalStateCode).then((elsterFieldDefinitionsResponse) => {
        if (elsterFieldDefinitionsResponse?.data?.success) {
          this.elsterFieldDefinitionsList = Object.values(elsterFieldDefinitionsResponse?.data?.elsterFieldDefinitions || {});
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
