<template>
  <div class="flex flex-row">
    <TaxCalculationSidebar
      @scrollToElementId="taxCalculationScroll"
      @navigationLoaded="taxCalculationNavigationLoaded"
      :parcelIdsWithoutTaxCalculationElement="taxCalculationNavigationExcludedElements"
      ref="taxCalculationSidebar"
    />

    <div class="h-full bg-white pl-7 w-full shrink" id="id_declaration_details_top">
      <ModalDialog
        v-show="showModalIsDeclarationWithoutSubmission"
        @close="showModalIsDeclarationWithoutSubmission = false"
        modalSize="small"
        backdropClass="backdrop_class"
      >
        <template v-slot:body>
          <div class="flex flex-col ml-4">
            <div class="achtung text-center">Achtung</div>
            <div class="mt-8 pb-8 text-center justify-center-text">
              <img class="w-1/12" src="@/assets/war_icon.svg"/>
              <p class="text-left">{{$t('declarations.detail.information_about_send_for_fee')}}</p>
              <div class="flex justify-end  mt-6 space-x-4 ">
                <Button class="w-56"
                        @click="showModalIsDeclarationWithoutSubmission = false"
                        :text="$t('buttons.cancel')"
                        :secondary="true"
                />
                <Button class="w-96"
                        :disabled="!elsterMessageValidated"
                        :text="$t('buttons.send_for_fee')"
                        @click="sendElsterMessage"
                />
              </div>
            </div>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="isModalVisibleWarn" @close="handleModalClose">
        <template v-slot:body>
          <div class="flex flex-col ml-4">
            <div class="achtung text-left">Achtung</div>
            <div class="mt-8 pb-8 text-center justify-center-text">
              <img class="w-1/12" src="@/assets/war_icon.svg"/>
              <p>Es sind nicht alle nötigen Felder gefüllt um die Steuerberechnung durchzuführen. Bitte füllen sie hier
                die fehlenden Felder aus.</p>
              <Button @close="handleModalClose" :text="$t('buttons.cancel')"
                      class="button-label text-center bg-gray text-white mt-5 ">
              </Button>
            </div>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="emailDialog" :headerText="getEmailDialogTitle" @close="handleEmailDialogClose">
        <template v-slot:body>
          <div class="flex flex-col justify-start mb-10"
              v-if="selectedClientApprovalOption === 'request_approval' || declarationData.status === 'external_approval_requested'">
            <label class="text-left">{{ $t('declarations.detail.buttons.request_client_approval.options_title') }}</label>
            <div class="flex justify-start items-center cursor-pointer mt-5" v-for="(label, opt) in clientApprovalModes"
                v-bind:key="opt"
                @click="selectClientApprovalOption(opt)">
              <RadioButton type="radio" v-model="clientApprovalMode" name="radio-button" :value="opt"
                          :disabled="approversCount > 0"/>
              <span class="ml-3 textradio">{{ label }}</span>
            </div>
            <div v-if="clientApprovalMode === 'multi_emails'" class="info no-bottom flex justify-start items-center mt-5">
              <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
              <label class="text-left ml-2"><p
                  v-html="$t('declarations.detail.buttons.request_client_approval.multi_emails_info')"></p></label>
            </div>
            <div v-if="clientApprovalMode==='client_portal' && approversCount === 0">
              <div class="flex items-center justify-between mt-5 space-x-8">
                <label class="text-left textbold flex w-full ">{{ $t('settings.user_management.attrs.email') }} :
                  <span class="ml-2" style="text-decoration: underline">{{
                      declarationData.client.clientUserEmail
                    }} </span>
                </label>
                <div class="invisible w-1/2"/>
                <inputCheck :text="$t('declarations.detail.do_not_send_email')"
                            :is-checked="!sendClientPortalApprovalEmail" @check="toggleClientEmailSend"/>
              </div>
              <div class="flex flex-col w-full justify-start ">
                <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body"
                          :label="$t('settings.user_management.personal_message')" v-model="clientDataRequestEmailBody"
                          :disabled="!sendClientPortalApprovalEmail"
                          :isMultiline="true" :rows="20"/>
              </div>
            </div>
            <div v-else-if="clientApprovalMode==='multi_emails'">
              <div class="flex justify-around mt-5 w-full" v-if="approversCount === 0">
                <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body w-full"
                          :label="$t('settings.user_management.personal_message')" v-model="clientDataRequestEmailBody"
                          :isMultiline="true" :rows="20"/>
              </div>
              <div class="flex mt-5" v-if="approversCount === 0">
                <InputText class="w-72"
                          ref="approver_email"
                          :label="$t('settings.user_management.email_recipients')"
                          v-model="approverEmail"
                          :isError="clientApproverEmailsError"
                          :errorMessage="$t('general.required')"
                />
                <Button class="flex justify-end ml-5 justify-center mt-1" :text="$t('buttons.add')"
                        @click="addApproversEmail"/>
              </div>
              <div class="approver_email w-full overflow-x-scroll" :class="approversCount > 0 ? 'mt-5' : ''">
                <div class="flex justify-items-start space-x-3" v-if="approversCount === 0 && showApproverEmailChip">
                  <Chip v-for="(email, index) in clientApproverEmails" :label="email" removable :key="index"
                        @remove="removeApproverEmail(email)"/>
                </div>
                <div class="flex justify-items-start space-x-3" v-else>
                  <Chip v-for="(detail, email) in clientApproverEmailsStatus" v-bind:key="email" :label="email"
                        :image="detail.signed_at > 0 ? getAssetPath('circle_tick.svg') : getAssetPath('hour_glass.svg')"/>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex flex-col justify-start">
            <div class="flex justify-around mt-2 space-x-10">
              <div class="w-full flex flex-col justify-start mt-2">
                <label class="text-left textbold ">{{ $t('settings.user_management.attrs.email') }} : <span
                    style="text-decoration: underline">{{ declarationData.client.clientUserEmail }} </span></label>
                <div class="flex flex-col justify-start ">
                  <InputText bodyClass="w-full non-resize-textarea h-72" class="invite_email_body"
                            :label="$t('settings.user_management.personal_message')" v-model="clientDataRequestEmailBody"
                            :isMultiline="true" :rows="20"/>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button v-if="approversCount > 0" :secondary="true"
                    :text="$t('declarations.detail.buttons.request_client_approval.reset_approval')"
                    @click="resetApproval"/>
            <Button :text="approversCount === 0 ? $t('buttons.cancel') : $t('buttons.close')" :secondary="true"
                    @click="handleEmailDialogClose"/>
            <Button v-if="approversCount === 0"
                    :text="selectedClientApprovalOption === 'request_approval' || declarationData.status === 'external_approval_requested' ? $t('buttons.save') : $t('buttons.send')"
                    :disabled="disabledRequestApproval" @click="requestDataFromClient"/>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="releaseWorkflowDialog" :headerText="getReleaseWorkflowDialogTitle"
                  @close="handleReleaseDialogClose">
        <template v-slot:body>
          <div v-if="releaseWorkflowDialog">
            <div class="flex flex-col justify-start mt-10">
              <div class="info flex justify-start items-center">
                <img class="info__icon" src="@/assets/info_gray.svg"/>
                <label class="info__text" v-if="declarationData.status === 'internal_approval_requested'">{{
                    $t('declarations.detail.release.info2')
                  }}</label>
                <label class="info__text" v-else>{{ $t('declarations.detail.release.info') }}</label>
              </div>
            </div>
            <div v-if="declarationData.status === 'internal_approval_requested'">
              <div class="flex flex-col justify-start" v-if="usersCanRelease.length > 0">
                <label class="label text-left mt-2">{{ $t('settings.user_management.user') }}</label>
                <InputSelect ref="userSelection" class="mt-2" :options="usersCanRelease" v-model="assigneeForRelease"
                            overlay-append-to="body"
                            @on-item-selected="selectedAssigneeForRelease"/>
              </div>
            </div>
            <div v-else>
              <div class="flex flex-col justify-start"
                  v-if="hasPermissionForRelease && usersCanRelease.length <= 1 && !$isBusiness">
                <label class="label text-left mt-2">{{ $t('declarations.detail.release.grant_permission') }}</label>
                <InputCheck class="mt-2 mb-2" :text="$t('general.yes')" @check="enableReleaseFlow"/>
                <label class="label text-left mt-2">{{ $t('declarations.detail.release.client_approval') }}</label>
                <InputSelect class="mt-2" :options="clientApprovalOptions"
                            overlay-append-to="body"
                            :disabled="!releaseEnabled"
                            v-model="selectedClientApprovalOption"/>
              </div>
              <div class="flex flex-col justify-start" v-else>
                <label class="label text-left mt-2">{{ $t('declarations.detail.release.change_assignee') }}</label>
                <InputSelect class="mt-2" :options="usersCanRelease" v-model="assigneeForRelease"
                            overlay-append-to="body"
                            :selectedValue="assigneeForRelease" @on-item-selected="selectedAssigneeForRelease"/>
              </div>
            </div>
          </div>
          <div class="flex mt-8 mb-5 w-full justify-end space-x-4">
            <Button :secondary="true" :text="$t('declarations.detail.release.buttons.later')"
                    @click="handleReleaseDialogClose"/>
            <Button v-if="declarationData.status === 'internal_approval_requested' && usersCanRelease.length > 0"
                    :disabled="!releaseEnabled" :text="$t('declarations.detail.release.buttons.request_approval')"
                    @click="releaseDeclaration('request_approval')"/>
            <Button v-if="declarationData.status === 'internal_approval_requested' && hasPermissionForRelease"
                    :text="$t('declarations.detail.release.buttons.release')" @click="releaseDeclaration('release')"/>
            <Button v-if="declarationData.status !== 'internal_approval_requested'" :disabled="!releaseEnabled"
                    :text="$t('declarations.detail.release.buttons.save')" @click="releaseDeclaration"/>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="dataVerificationDialog" :headerText="$t('declarations.detail.data_verification.title')"
                  @close="cancelRelease">
        <template v-slot:body>
          <div class="flex flex-col ml-4">
            <div class="mt-8 pb-8 text-left">
              <label class="label text-left">
                <p>{{ $t('declarations.detail.data_verification.info1') }}</p>
                <p class="mt-2">{{ $t('declarations.detail.data_verification.info2') }}</p>
                <p class="mt-2">{{ $t('declarations.detail.data_verification.info3') }}</p>
              </label>
            </div>
            <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
              <Button class="" :text="$t('general.no')" :secondary="true" @click="cancelRelease"/>
              <Button class="" :text="$t('general.yes')" @click="startRelease"/>
            </div>
          </div>
        </template>

      </ModalDialog>
      <ModalDialog v-show="elsterAutoSubmissionDialog" :headerText="$t('declarations.detail.progress.step5')"
                  @close="closeElsterAutoSubmissionDialog">
        <template v-slot:body>
          <div class="flex flex-col">
            <div class="mt-8 pb-8 text-left">
              <label class="label text-left">
                <p>{{ $t('declarations.detail.elster_auto_submission.info1') }}</p>
                <p class="mt-2">{{ $t('declarations.detail.elster_auto_submission.info2') }}</p>
              </label>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button class="" :text="$t('declarations.detail.elster_auto_submission.buttons.manual')" :secondary="true"
                    @click="closeElsterAutoSubmissionDialog"/>
            <Button class="" :text="$t('declarations.detail.elster_auto_submission.buttons.auto')"
                    @click="enableElsterAutoSubmission"/>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog
        v-show="elsterValidationResultDialog"
        modalSize="x-large"
        :headerText="$t('declarations.detail.elster_validation.title') + ' - ' + elsterValidationPropertyUid"
        @close="handleElsterValidationResultDialogClose"
      >
        <template v-slot:body>
          <div class="flex flex-col justify-start">
            <ElsterValidationResults
              :visible="elsterValidationResultDialog"
              :elsterValidationResultsList="elsterValidationResultsList"
              :federalStateCode="federalStateCode"
              :elsterValidationPropertyUid="declarationData.property.propertyUid"
            />

            <div class="flex justify-around mt-2 space-x-10" v-show="elsterValidationMode === 'data_completed'">
              <div class="w-full flex flex-col justify-start mt-2 text-left">
                <p>{{ $t('declarations.detail.elster_validation.release_with_error_info_1') }}</p>
                <p class="mt-5">{{ $t('declarations.detail.elster_validation.release_with_error_info_2') }}</p>
              </div>
            </div>
            <div class="flex justify-around mt-2 space-x-10" v-show="elsterValidationMode === 'request_client_approval'">
              <div class="w-full flex flex-col justify-start mt-2 text-left">
                <p>{{ $t('declarations.detail.elster_validation.client_request_elster_error_info') }}</p>
              </div>
            </div>

            <div class="flex justify-around mt-10 space-x-10" v-show="!hasElsterErrors">
              <div class="w-full flex flex-col justify-center items-center mt-2">
                <div class="flex justify-center w-full">
                  <hr class="line_document w-full"/>
                  <label class="label text-left mx-4">{{
                      $t('declarations.detail.elster_validation.documents.title')
                    }}</label>
                  <hr class="line_document w-full"/>
                </div>

                <div class="">
                  <div class=" flex cursor-pointer underline doc_title mb-5 mt-3">
                    <img class="pdf_image" :src="getAssetPath('document_pdf.svg')"
                        @click="elsterDownloadPdf('controlPrint')">
                    <img class="pdf_image" :src="getAssetPath('eye_green.svg')"
                        @click="elsterDownloadPdf('controlPrint', 'view')">
                    <span @click="elsterDownloadPdf('controlPrint')">{{
                        $t('declarations.detail.elster_validation.documents.control_print')
                      }} <small>{{ $t('declarations.detail.elster_validation.documents.control_print_subtext') }}</small></span>
                  </div>
                  <div class=" flex cursor-pointer underline doc_title mb-5 mt-3">
                    <img class="pdf_image" :src="getAssetPath('document_pdf.svg')"
                        @click="elsterDownloadPdf('elsterFormPrint')">
                    <img class="pdf_image" :src="getAssetPath('eye_green.svg')"
                        @click="elsterDownloadPdf('elsterFormPrint', 'view')">
                    <span @click="elsterDownloadPdf('elsterFormPrint')">{{
                        $t('declarations.detail.elster_validation.documents.elster_form_print')
                      }} <small>{{
                          $t('declarations.detail.elster_validation.documents.elster_form_print_subtext')
                        }}</small></span>
                  </div>
                  <div class=" flex cursor-pointer underline doc_title mb-5 mt-3">
                    <img class="pdf_image" :src="getAssetPath('document_pdf.svg')"
                        @click="elsterDownloadPdf('completedDeclaration')">
                    <img class="pdf_image" :src="getAssetPath('eye_green.svg')"
                        @click="elsterDownloadPdf('completedDeclaration', 'view')">
                    <span @click="elsterDownloadPdf('completedDeclaration')">{{
                        $t('declarations.detail.elster_validation.documents.completed_declaration')
                      }}</span>
                  </div>
                  <div class=" flex cursor-pointer underline doc_title mb-5 mt-3">
                    <img class="pdf_image" :src="getAssetPath('document_pdf.svg')" @click="downloadTaxCalculationPdf">
                    <img class="pdf_image" :src="getAssetPath('eye_green.svg')" @click="openTaxCalculationPdf">
                    <span @click="downloadTaxCalculationPdf">{{
                        $t('declarations.detail.elster_validation.documents.tax_calculation')
                      }}</span>
                  </div>
                  <div class=" flex cursor-pointer underline doc_title" v-if="!$isBusiness">
                    <img class="pdf_image" :src="getAssetPath('document_pdf.svg')" @click="downloadPropertyValuePdf">
                    <img class="pdf_image" :src="getAssetPath('eye_green.svg')" @click="openPropertyValuePdf">
                    <span @click="downloadPropertyValuePdf">{{
                        $t('declarations.detail.elster_validation.documents.property_value')
                      }}</span>
                  </div>
                </div>
                <div class="flex flex-col mt-10">
                  <label class="sub_text">
                    {{ $t('declarations.detail.elster_validation.under') }}
                    <span class="cursor-pointer hover-underline" @click="openSettings">{{
                        $t('declarations.detail.elster_validation.settings')
                      }} > {{ $t('declarations.detail.elster_validation.system_settings') }}</span>
                    {{ $t('declarations.detail.elster_validation.can_the') }}
                  </label>
                  <label class="sub_text"> {{
                      $t('declarations.detail.elster_validation.declaration_completeness')
                    }}</label>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 justify-end space-x-4" v-if="elsterValidationMode === 'data_completed'">
            <Button :text="$t('buttons.no')" :secondary="true"
                    @click="handleElsterValidationResultDialogClose"/>
            <Button :text="$t('buttons.yes')" @click="startRelease"/>
          </div>
          <div class="flex mt-5 mb-5 justify-end space-x-4" v-else-if="hasElsterErrors">
            <Button :text="$t('components.properties_menu.edit_property')"
                    :icon="getAssetPath('edit-property.svg')"
                    :secondary="true"
                    @click="editProperty"
                    v-if="hasElsterErrors"
            />
            <Button :text="$t('buttons.ok')" @click="handleElsterValidationResultDialogClose"/>
          </div>
          <div class="flex justify-center mt-5 mb-5 w-full" v-else>
            <Button :text="$t('buttons.ok')" class="w-80" @click="handleElsterValidationResultDialogClose"/>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog v-show="!communityIsValidForTax" :headerText="$t('declarations.tax_calculation.select_community')"
                  @close="closeCommunitySelection">
        <template v-slot:body>
          <div class="flex flex-col justify-start community_modal">
            <div class="flex justify-around mt-2 space-x-10">
              <div class="w-full mt-2 text-left">
                <p>{{ $t('declarations.tax_calculation.select_community_desc') }}</p>
                <p class="mt-2">{{ $t('declarations.tax_calculation.select_community_save_info') }}</p>
              </div>
            </div>
            <div class="flex justify-around mt-2 space-x-10">
              <div class="w-full flex flex-col justify-start mt-2">
                <InputCheck @check="change_save_calculation_community" :is-checked="save_calculation_community"
                            :text="$t('declarations.tax_calculation.select_community_save') "/>
              </div>
            </div>
            <div class="flex justify-around mt-2 space-x-10">

              <div class="w-full flex flex-col justify-start mt-2">
                <InputSelect
                    class="mt-2 w-full"
                    :label="$t('properties.step2.community')"
                    :options="communityList"
                    v-model="tax_community"
                    :filterable="true"
                    :auto-complete="false"
                    :search-fn="communitytListSearch"
                    :total-record-count="12000"
                    :selected-value="tax_community"
                    overlay-append-to="body"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button class="" :text="$t('buttons.ok')" @click="fetchTaxCalculationOrObjectValue"/>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog v-show="areaOfTheLandDataModalShow"
                  :headerText="$t('declarations.detail.area_of_the_land_data_modal.title')"
                  @close="areaOfTheLandDataModalShow = false"
                  modalSize="large"
      >
        <template v-slot:body>
          <div class="flex flex-col justify-start">
            <div class="flex justify-around mt-2 space-x-10">
              <div class="w-full mt-2 text-left">
                <p>{{ $t('declarations.detail.area_of_the_land_data_modal.description') }}</p>
              </div>
            </div>

            <div class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="areaOfTheLand1"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input1')"
                  placeholder=""
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="9"
              />
              <InputText
                  class="mt-2 w-full"
                  :decimalNumber="2"
                  :maxValue="maxAreaOfLandValue1"
                  :isFloat="true"
                  v-model="areaOfTheLandValue1"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input2')"
                  placeholder=""
              />
            </div>

            <div v-if="secondAreaInfoShow === true" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="areaOfTheLand2"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input1')"
                  placeholder=""
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="9"
              />
              <InputText
                  class="mt-2 w-full"
                  :decimalNumber="2"
                  :maxValue="maxAreaOfLandValue1"
                  :isFloat="true"
                  v-model="areaOfTheLandValue2"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input2')"
                  placeholder=""
              />
            </div>

            <div class="flex flex-col justify-start mt-2 mb-2" v-if="secondAreaInfoShow === true">
              <div class="flex justify-end">
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideSecondAreaInfo"/>
              </div>
            </div>

            <div class="flex justify-end mt-4 mb-4" v-if="secondAreaInfoShow === false">
              <div class="flex justify-center items-center cursor-pointer" @click="showSecondAreaInfo">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
              </div>
            </div>

            <div v-if="thirdAreaInfoShow === true" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="areaOfTheLand3"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input1')"
                  placeholder=""
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="9"
              />
              <InputText
                  class="mt-2 w-full"
                  :decimalNumber="2"
                  :maxValue="maxAreaOfLandValue1"
                  :isFloat="true"
                  v-model="areaOfTheLandValue3"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input2')"
                  placeholder=""
              />
            </div>

            <div class="flex flex-col justify-start mt-2 mb-2" v-if="thirdAreaInfoShow === true">
              <div class="flex justify-end">
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideThirdAreaInfo"/>
              </div>
            </div>

            <div class="flex justify-end mt-4 mb-4" v-if="thirdAreaInfoShow === false && secondAreaInfoShow === true">
              <div class="flex justify-center items-center cursor-pointer" @click="showThirdAreaInfo">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
              </div>
            </div>

            <div v-if="fourthAreaInfoShow === true" class="flex justify-around space-x-10 mt-4 mb-4">
              <InputText
                  class="mt-2 w-full"
                  v-model="areaOfTheLand4"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input1')"
                  placeholder=""
                  :isOnlyNumber="true"
                  :isOnlyPositive="true"
                  :maxLength="9"
              />
              <InputText
                  class="mt-2 w-full"
                  :decimalNumber="2"
                  :maxValue="maxAreaOfLandValue1"
                  :isFloat="true"
                  v-model="areaOfTheLandValue4"
                  :label="$t('declarations.detail.area_of_the_land_data_modal.input2')"
                  placeholder=""
              />
            </div>

            <div class="flex flex-col justify-start mt-2 mb-2" v-if="fourthAreaInfoShow === true">
              <div class="flex justify-end">
                <img class="delete delete-icon" src="@/assets/delete_user_green.svg" @click="hideFourthAreaInfo"/>
              </div>
            </div>

            <div class="flex justify-end mt-4 mb-4" v-if="fourthAreaInfoShow === false && thirdAreaInfoShow === true && secondAreaInfoShow === true">
              <div class="flex justify-center items-center cursor-pointer" @click="showFourthAreaInfo">
                <img class="plus" src="@/assets/plus_circle_green.svg"/>
                <label class="ml-3 btext add-more-link">{{ $t('properties.step3.add_more_area') }}</label>
              </div>
            </div>

            <div class="flex mt-8 mb-8 w-full justify-end space-x-4">
              <Button class="" :text="$t('general.cancel')" :secondary="true" @click="areaOfTheLandDataModalShow = false"/>
              <Button class="" :text="$t('general.submit')" @click="saveAreaOfTheLandData"/>
            </div>
          </div>
        </template>
      </ModalDialog>

      <ChangeAssessmentRateModal
        :visible="showChangeAssessmentRateModal"
        :assessmentRate="assessmentRate"
        :declarationUid="declarationUid"
        @close="closeChangeAssessmentRateModal"
        @change="changeAssessmentRate"
      />

      <TaxCalculationErrorModal
        :errors="taxCalculationErrors"
        :declarationUid="declarationData.prim_uid"
        :defaultAreaOfTheLand1="areaOfTheLand1"
        :defaultAreaOfTheLandValue1="areaOfTheLandValue1"
        :errorKey="taxCalculationErrorKey"
        v-show="showTaxCalculationErrorModal"
        v-on:close="closeTaxCalculationErrorModal"
        v-on:regenerate-tax-calculation="regenerateCalculation(false)"
      ></TaxCalculationErrorModal>

      <ModalDialog v-show="showTaxAssessmentUploadDialog" @close="closeTaxAssessmentUploadDialog"
                  :headerText="$t('declarations.stages.assessment_received')">
        <template v-slot:body>
          <!-- tax_assessment_1 -->
          <div class="flex flex-col">
            <p class="text-left mt-5">{{ $t('declarations.detail.assessment_received.info1') }}</p>

            <InputUpload ref="uploadTaxAssessment" :label="$t('declarations.detail.assessment_received.pdf_upload_label')"
                        :multiple="false"
                        class="mt-5 mb-5"
                        accept=".pdf"
                        :isBlackText="true" :showDragAndDrop="true"
            />
            <div v-if="hasAccess('deadline')" class="flex justify-items-start items-center">
              <img class="icon icon-expand-collapse flex flex-col mr-2 cursor-pointer"
                  @click="toggleDeadlineCalculation(0)" :src="getAssetPath('expand.svg')"
                  :class="{'rotate': deadline_calculation[0].expanded}">
              <label class="text-left flex flex-col cursor-pointer" @click="toggleDeadlineCalculation(0)">{{
                  $t('declarations.detail.assessment_received.deadline_calculation')
                }}</label>
            </div>
            <div v-if="hasAccess('deadline')" class="flex flex-col justify-start space-y-4 mt-5"
                v-show="deadline_calculation[0].expanded">
              <div class="info flex justify-start items-center">
                <img class="info__icon" src="@/assets/info_gray.svg"/>
                <label class="info__text mr-5" v-html="$t('deadline.add_info')"/>
              </div>
              <InputDate
                  class="w-full"
                  v-model="deadline_calculation[0].document_date"
                  :label="$t('deadline.attrs.document_date')"
                  appendTo="body"
                  :showHelpIcon="true"
                  :tooltip="$t('deadline.attrs_help.document_date')"
                  @date-selected="calculateDeadlineDate($event, 0, 'document_date')"
              />
              <InputDate
                  class="w-full"
                  v-model="deadline_calculation[0].announcement_date"
                  :label="$t('deadline.attrs.announcement_date')"
                  appendTo="body"
                  :showHelpIconPopover="true"
                  :popoverTexts="[$t('deadline.attrs_help.announcement_date')]"
                  @date-selected="calculateDeadlineDate($event, 0, 'announcement_date')"
              />
              <InputDate
                  class="w-full"
                  v-model="deadline_calculation[0].deadline_reminder"
                  :label="$t('deadline.attrs.deadline_reminder')"
                  appendTo="body"
                  :showHelpIcon="true"
                  :tooltip="$t('deadline.attrs_help.deadline_reminder')"
              />
              <InputDate class="w-full" :label="$t('deadline.attrs.deadline')"
                        v-model="deadline_calculation[0].deadline"
                        :disabled="true"
                        :showHelpIconPopover="true"
                        :popoverTexts="[$t('deadline.attrs_help.deadline')]"
              />
            </div>
            <!-- tax_assessment_2 -->
            <InputUpload ref="uploadTaxAssessment2"
                        :label="$t('declarations.detail.assessment_received.pdf_upload2_label')"
                        :multiple="false"
                        class="mt-5 mb-5"
                        accept=".pdf"
                        :isBlackText="true" :showDragAndDrop="true"
            />
            <div v-if="hasAccess('deadline')" class="flex justify-items-start items-center">
              <img class="icon icon-expand-collapse flex flex-col mr-2 cursor-pointer"
                  @click="toggleDeadlineCalculation(1)" :src="getAssetPath('expand.svg')"
                  :class="{'rotate': deadline_calculation[1].expanded}">
              <label class="text-left flex flex-col cursor-pointer" @click="toggleDeadlineCalculation(1)">{{
                  $t('declarations.detail.assessment_received.deadline_calculation')
                }}</label>
            </div>
            <div v-if="hasAccess('deadline')" class="flex flex-col justify-start space-y-4 mt-5"
                v-show="deadline_calculation[1].expanded">
              <div class="info flex justify-start items-center">
                <img class="info__icon" src="@/assets/info_gray.svg"/>
                <label class="info__text mr-5" v-html="$t('deadline.add_info')"/>
              </div>
              <InputDate
                  class="w-full"
                  v-model="deadline_calculation[1].document_date"
                  :label="$t('deadline.attrs.document_date')"
                  appendTo="body"
                  :showHelpIcon="true"
                  :tooltip="$t('deadline.attrs_help.document_date')"
                  @date-selected="calculateDeadlineDate($event, 1, 'document_date')"
              />
              <InputDate
                  class="w-full"
                  v-model="deadline_calculation[1].announcement_date"
                  :label="$t('deadline.attrs.announcement_date')"
                  appendTo="body"
                  :showHelpIconPopover="true"
                  :popoverTexts="[$t('deadline.attrs_help.announcement_date')]"
                  @date-selected="calculateDeadlineDate($event, 1, 'announcement_date')"
              />
              <InputDate
                  class="w-full"
                  v-model="deadline_calculation[1].deadline_reminder"
                  :label="$t('deadline.attrs.deadline_reminder')"
                  appendTo="body"
                  :showHelpIcon="true"
                  :tooltip="$t('deadline.attrs_help.deadline_reminder')"
              />
              <InputDate class="w-full" :label="$t('deadline.attrs.deadline')"
                        v-model="deadline_calculation[1].deadline"
                        :disabled="true"
                        :showHelpIconPopover="true"
                        :popoverTexts="[$t('deadline.attrs_help.deadline')]"
              />
            </div>
            <!-- tax_assessment_3 -->
            <div v-if="isAreaModel()">
              <InputUpload ref="uploadTaxAssessment3"
                          :label="$t('declarations.detail.assessment_received.pdf_upload3_label')"
                          :multiple="false"
                          class="mt-5 mb-5"
                          accept=".pdf"
                          :isBlackText="true" :showDragAndDrop="true"
              />
              <div v-if="hasAccess('deadline')" class="flex justify-items-start items-center">
                <img class="icon icon-expand-collapse flex flex-col mr-2 cursor-pointer"
                    @click="toggleDeadlineCalculation(2)" :src="getAssetPath('expand.svg')"
                    :class="{'rotate': deadline_calculation[2].expanded}">
                <label class="text-left flex flex-col cursor-pointer" @click="toggleDeadlineCalculation(2)">{{
                    $t('declarations.detail.assessment_received.deadline_calculation')
                  }}</label>
              </div>
              <div v-if="hasAccess('deadline')" class="flex flex-col justify-start space-y-4 mt-5"
                  v-show="deadline_calculation[2].expanded">
                <div class="info flex justify-start items-center">
                  <img class="info__icon" src="@/assets/info_gray.svg"/>
                  <label class="info__text mr-5" v-html="$t('deadline.add_info')"/>
                </div>
                <InputDate
                    class="w-full"
                    v-model="deadline_calculation[2].document_date"
                    :label="$t('deadline.attrs.document_date')"
                    appendTo="body"
                    :showHelpIcon="true"
                    :tooltip="$t('deadline.attrs_help.document_date')"
                    @date-selected="calculateDeadlineDate($event, 2, 'document_date')"
                />
                <InputDate
                    class="w-full"
                    v-model="deadline_calculation[2].announcement_date"
                    :label="$t('deadline.attrs.announcement_date')"
                    appendTo="body"
                    :showHelpIconPopover="true"
                    :popoverTexts="[$t('deadline.attrs_help.announcement_date')]"
                    @date-selected="calculateDeadlineDate($event, 2, 'announcement_date')"
                />
                <InputDate
                    class="w-full"
                    v-model="deadline_calculation[2].deadline_reminder"
                    :label="$t('deadline.attrs.deadline_reminder')"
                    appendTo="body"
                    :showHelpIcon="true"
                    :tooltip="$t('deadline.attrs_help.deadline_reminder')"
                />
                <InputDate class="w-full" :label="$t('deadline.attrs.deadline')"
                          v-model="deadline_calculation[2].deadline"
                          :disabled="true"
                          :showHelpIconPopover="true"
                          :popoverTexts="[$t('deadline.attrs_help.deadline')]"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button class="" :text="$t('general.no')" :secondary="true" @click="closeTaxAssessmentUploadDialog"/>
            <Button class="" :text="$t('general.yes')" @click="uploadTaxAssessment"/>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="showCompareDocumentsDialog" modalSize="x-large" @close="closeCompareDocumentsDialog"
                  class="document-review-dialog"
                  :headerText="$t('declarations.detail.buttons.review.title')">
        <template v-slot:body>
          <div class="flex justify-around document-switch">
            <InputSelect
              class="w-1/3 mt-2"
              label=""
              :options="taxDocumentSwitchOptions"
              :selected-value="selectedTaxDocumentSwitchOption"
              overlay-append-to="body"
              @on-item-selected="taxDocumentTypeChanged"
            />
            <InputSelect
              v-if="documentSwitchOptions.length > 0"
              class="w-1/3 mt-2"
              label=""
              :options="documentSwitchOptions"
              :selected-value="selectedDocumentSwitchOption"
              :disabled="documentSwitchOptions.length === 1"
              overlay-append-to="body"
              @on-item-selected="documentTypeChanged"
            />
            <div v-else class="w-1/3 mt-2"/>
          </div>
          <div class="flex justify-around space-x-5">
            <div class="left-document w-1/2">
              <vue-pdf-embed :source="sourceObjectForPdfjs(taxDocuments.taxCalculation.fileSource)"/>
            </div>
            <div class="right-document w-1/2" v-if="taxDocuments.taxAssessment.fileSource !== ''">
              <vue-pdf-embed :source="sourceObjectForPdfjs(taxDocuments.taxAssessment.fileSource)"/>
            </div>
            <div class="w-1/2" v-else>
              <div class="sticky top-10">
                <div class="flex justify-center">{{ $t('declarations.detail.tax_assessment_not_uploaded') }}</div>
                <div class="flex justify-center">
                  <InputUpload ref="taxAssessmentFileInput" :label="$t('declarations.detail.upload_tax_assessment')"
                              :multiple="false"
                              class="mb-5"
                              accept=".pdf"
                              :isBlackText="true" :showDragAndDrop="true"
                              @select="taxAssessmentFileSelect"
                  />
                </div>
              </div>
            </div>
          </div>

        </template>
        <template v-slot:footer>

          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <div class="flex w-full pl-5 justify-start space-x-4">
              <Button @click="regenerateCalculation" :text="$t('declarations.detail.buttons.review.regenrate')"/>

              <Button :icon="getAssetPath('edit-property-white.svg')" :text="$t('declarations.detail.edit_property')"
                      @click="editProperty(false)"/>
            </div>
            <div class="flex w-full pl-5 justify-end space-x-4">
              <Button :secondary="true" :text="$t('buttons.cancel')" @click="closeCompareDocumentsDialog"/>

              <Button :icon="getAssetPath('thumbs_up.svg')" :text="$t('declarations.detail.tax_assessment_fine')"
                      @click="acceptTaxAssessment"/>
              <Button :icon="getAssetPath('thumbs_down.svg')" :text="$t('declarations.detail.tax_assessment_dispute')"
                      @click="rejectAssessment"/>
            </div>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog
        v-show="showElsterMessage"
        @close="closeElsterMessage"
        modalSize="x-large"
        :headerText="$t('declarations.detail.elster_message.dialog_header')"
      >
        <template v-slot:body>
          <div class="flex justify-around space-x-5 mt-5 mb-8">
            <div class="flex flex-col w-full h-full space-y-5">
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.message_type')"
                :options="getElsterMessageTypeOptions"
                v-model="elsterMessage.messageType"
                :selected-value="elsterMessage.messageType"
                overlay-append-to="body"
                @input="resetElsterMessageData"
              />
              <InputCheck
                class="mt-2"
                :text="$t('declarations.detail.elster_message.is_draft')"
                :isChecked="elsterMessage.isDraft"
                @check="elsterMessageIsDraftCheck"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.differing_reference_date')"
                :options="getElsterMessageDifferingReferenceDateOptions"
                v-model="elsterMessage.differingReferenceDate"
                v-show="elsterMessage.messageType === 'deadline_extension'"
                :selected-value="elsterMessage.differingReferenceDate"
                overlay-append-to="body"
              />
              <InputText
                class="mt-2"
                :label="$t('declarations.detail.elster_message.subject')"
                :maxLimit="getElsterMessageSubjectMaxLimit"
                v-model="elsterMessage.subject"
                v-show="['appeal', 'other'].includes(elsterMessage.messageType)"
                :isRequired="['appeal', 'other'].includes(elsterMessage.messageType)"
                :isError="elsterMessageValidations.subject.invalid"
                :error-message="$t('general.errors.limitDesc').replace('%length%', elsterMessage.subject.length).replace('%maxLimit%', getElsterMessageSubjectMaxLimit)"
                @keyup="checkText"
                @blur="checkText"
              />
              <InputText
                class="mt-2"
                bodyClass="non-resize-textarea"
                :label="$t('declarations.detail.elster_message.justification')"
                :isMultiline="true"
                :maxLimit="getElsterMessageJustificationMaxLimit"
                v-model="elsterMessage.justification"
                :showHelpIconPopover="['deadline_extension', 'subsequent'].includes(elsterMessage.messageType)"
                :is-required="(elsterMessage.messageType !== 'subsequent') ? true : (elsterMessage.attachments.length === 0)"
                :is-error="elsterMessageValidations.justification.invalid"
                :error-message="$t('general.errors.limitDesc').replace('%length%', elsterMessage.justification.length).replace('%maxLimit%', getElsterMessageJustificationMaxLimit)"
                overlay-append-to="body"
                :popoverTexts="getElsterMessageJustificationHelp"
                @keyup="checkText"
                @blur="checkText"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.request_extension')"
                :options="$t('declarations.detail.elster_message.request_extension_options')"
                v-model="elsterMessage.extensionUntil"
                v-show="elsterMessage.messageType === 'deadline_extension'"
                :selected-value="elsterMessage.extensionUntil"
                :is-required="elsterMessage.messageType === 'deadline_extension'"
                overlay-append-to="body"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.processing_type')"
                :options="$t('declarations.detail.elster_message.processing_type_options')"
                v-model="elsterMessage.processingType"
                v-show="elsterMessage.messageType === 'deadline_extension'"
                :selected-value="elsterMessage.processingType"
                :is-required="elsterMessage.messageType === 'deadline_extension'"
                :showHelpIconPopover="true"
                :popoverTexts="processing_type_tooltip"
                overlay-append-to="body"
              />
              <InputDate
                class="mt-2"
                :label="$t('declarations.detail.elster_message.administrative_act_date')"
                v-model="elsterMessage.actDate"
                v-show="elsterMessage.messageType === 'appeal'"
                :is-required="elsterMessage.messageType === 'appeal'"
                :isError="elsterMessageValidations.actDate.invalid"
                :error-message="$t('general.required')"
                :minDateValueProp="new Date('2022-07-01')"
                :maxDateValueProp="new Date()"
                :popoverTexts="$t('declarations.detail.elster_message.administrative_act_date_help')"
                dateId="elster_message_act_date"
                overlay-append-to="body"
                placeholder=""
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.administrative_act')"
                :options="getAdministrativeActOptions"
                v-model="elsterMessage.actId"
                v-show="elsterMessage.messageType === 'appeal'"
                :selected-value="elsterMessage.actId"
                :is-required="elsterMessage.messageType === 'appeal'"
                :isError="elsterMessageValidations.actId.invalid"
                :error-message="$t('general.required')"
                overlay-append-to="body"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.administrative_deadline')"
                :options="getAdministrativeDeadlineOptions"
                v-model="elsterMessage.actDeadline"
                :selected-value="elsterMessage.actDeadline"
                v-show="elsterMessage.actId === '108'"
                :is-required="elsterMessage.actId === '108'"
                :isError="elsterMessageValidations.actDeadline.invalid"
                :error-message="$t('general.required')"
                overlay-append-to="body"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.suspension_of_execution')"
                :options="$t('declarations.detail.elster_message.suspension_of_execution_options')"
                v-model="elsterMessage.suspensionOfExecution"
                v-show="elsterMessage.messageType === 'appeal'"
                :selected-value="elsterMessage.suspensionOfExecution"
                :is-required="elsterMessage.messageType === 'appeal'"
                :isError="elsterMessageValidations.suspensionOfExecution.invalid"
                :error-message="$t('general.required')"
                overlay-append-to="body"
              />
              <InputText
                class="mt-2"
                :label="$t('declarations.detail.elster_message.differing_reference_number')"
                v-model="elsterMessage.differingReferenceNumber"
                v-show="elsterMessage.messageType === 'appeal'"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.differing_appellant')"
                :options="differingAppellantOptions"
                v-model="elsterMessage.differingAppellant"
                v-show="elsterMessage.messageType === 'appeal'"
                :selected-value="elsterMessage.differingAppellant"
                overlay-append-to="body"
              />
              <InputSelect
                class="mt-2"
                :label="$t('declarations.detail.elster_message.differing_power_of_attorney')"
                :options="differingPowerOfAttorneyOptions"
                v-model="elsterMessage.differingPowerOfAttorneyId"
                v-show="elsterMessage.messageType === 'appeal'"
                :selected-value="elsterMessage.differingPowerOfAttorneyId"
                overlay-append-to="body"
              />

              <div class="flex flex-col">
                <div class="flex">
                  <InputCheck
                    :text="$t('declarations.detail.elster_message.check_box_text')"
                    :isRequired="!elsterMessage.isDraft"
                    :isChecked="elsterMessage.confirmed"
                    @check="elsterMessageConfirmationChecked"
                  />
                </div>
                <div class="flex" v-show="elsterMessageValidations.confirmation.invalid">
                  <label class="error-label self-start mt-1 error-text-red">{{ $t('general.required') }}</label>
                </div>
              </div>
              <div class="flex justify-around mt-2 space-x-10" v-if="showTextScanNotice">
                <div class="w-full flex flex-col justify-center items-center mt-2">
                  <label class="scan-notice-header">{{ $t('declarations.detail.elster_message.scan_notice') }}</label>
                  <div>{{ $t('declarations.detail.elster_message.scan_notice_2') }}</div>
                </div>
              </div>

              <div id="elster-message-errors-list" class="flex flex-col">
                <div class="flex justify-around mt-2 space-x-10" v-show="hasElsterMessageErrors">
                  <div class="w-full flex flex-col justify-center items-center mt-2">
                    <label class="text-h text-left">{{ $t('declarations.detail.elster_validation.validation_error') }}:</label>
                    <ul class="ul-list">
                      <li v-for="error in elsterMessageValidations.errors" :key="error.field">{{ error.readable }}</li>
                    </ul>
                  </div>
                </div>

                <div class="flex justify-around mt-2 space-x-10" v-show="hasElsterMessageHints">
                  <div class="w-full flex flex-col justify-center items-center mt-2">
                    <label class="text-h text-left">{{ $t('declarations.detail.elster_validation.notes') }}:</label>
                    <ul class="ul-list align-middle">
                      <li v-for="hint in elsterMessageValidations.hints" :key="hint.field">{{ hint.readable }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col w-full h-full space-y-5">
              <InputUpload
                ref="elsterMessageAttachmentsUpload"
                class="mt-2 elster-message-attachments-upload"
                :label="$t('declarations.detail.elster_message.attachments_select_files')"
                multiple="multiple"
                :isRequired="elsterMessage.messageType === 'subsequent'"
                :accept="$t('declarations.detail.elster_message.attachments_allowed_file_types')"
                :isBlackText="true"
                :showDragAndDrop="true"
                :fileLimit="parseInt($t('declarations.detail.elster_message.attachment_limits.files'))"
                :maxFileSize="parseInt($t('declarations.detail.elster_message.attachment_limits.file_size'))"
                :maxOverallSize="parseInt($t('declarations.detail.elster_message.attachment_limits.overall_size'))"
                byteFormatUnit="mb"
                :byteFormatDecimals="2"
                :description="$t('declarations.detail.elster_message.attachments_description').replace('%attachments%', elsterMessage.attachments.length).replace('%maxAttachments%', 20)"
                :showHelpIcon="true"
                :tooltip="$t('declarations.detail.elster_message.attachments_help').replace('%maxAttachments%', 20).replace('%maxSingleFileSize%', 10485760).replace('%maxSumFileSize%', 38700000)"
                overlay-append-to="body"
                :allowFilenameEditing="true"
                :normalizeFilenames="true"
                :maxFilenameLength="parseInt($t('declarations.detail.elster_message.attachment_limits.filename_length'))"
                :showUploadStatus="false"
                :viewFileEnabled="true"
                :viewFileService="service"
                @select="elsterMessageAttachmentsFileSelect"
                @filesChanged="elsterMessageAttachmentsFilesChanged"
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button
              :secondary="true"
              :text="$t('buttons.cancel')"
              @click="closeElsterMessage"
            />
            <Button
              :secondary="elsterMessageValidated"
              :text="$t('buttons.preview')"
              @click="validateElsterMessage(true)"
            />
            <Button
              :disabled="!elsterMessageValidated"
              v-show="!shouldShowIsDeclarationWithoutSubmissionModal"
              :text="elsterMessageButtonText"
              @click="sendElsterMessage"
            />
            <Button
              :disabled="!elsterMessageValidated"
              v-show="shouldShowIsDeclarationWithoutSubmissionModal"
              :text="elsterMessageButtonText"
              @click="showModalIsDeclarationWithoutSubmission = true"
            />
          </div>
        </template>
      </ModalDialog>

      <ModalDialog v-show="showElsterMessageResponse" @close="closeElsterMessageResponse"
                  :headerText="$t('declarations.detail.elster_message.response_dialog_header').replace('%messageType%', currentElsterMessageResponseType)">
        <template v-slot:body>
          <div class="flex justify-around space-x-5 mt-5 mb-8">
            <div class="flex flex-col w-full h-full space-y-5">
              <div id="elster-message-errors-list" class="flex flex-col">
                <div class="flex justify-around mt-2 space-x-10" v-show="hasElsterMessageErrors">
                  <div class="w-full flex flex-col justify-center items-center mt-2">
                    <label class="text-h text-left">{{ $t('declarations.detail.elster_validation.validation_error') }}:</label>
                    <ul class="ul-list">
                      <li v-for="error in elsterMessageValidations.errors" :key="error.field">{{ error.readable }}</li>
                    </ul>
                  </div>
                </div>

                <div class="flex justify-around mt-2 space-x-10" v-show="hasElsterMessageHints">
                  <div class="w-full flex flex-col justify-center items-center mt-2">
                    <label class="text-h text-left">{{ $t('declarations.detail.elster_validation.notes') }}:</label>
                    <ul class="ul-list align-middle">
                      <li v-for="hint in elsterMessageValidations.hints" :key="hint.field">{{ hint.readable }}</li>
                    </ul>
                  </div>
                </div>

                <div class="flex justify-around mt-2 space-x-10"
                    v-show="!hasElsterMessageHints && !hasElsterMessageErrors">
                  <div class="w-full flex flex-col justify-center items-center mt-2">
                    <label class="text-h text-left">{{ $t('declarations.detail.elster_message.response_please_contact_support') }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button :secondary="true" :text="$t('buttons.close')" @click="closeElsterMessageResponse"/>
          </div>
        </template>
      </ModalDialog>

      <ElsterMessageAttachmentsModal
        :visible="showElsterMessageAttachmentsModal"
        :declarationUid ="declarationUid"
        :elsterMessageId ="elsterMessageId"
        @close="closeElsterMessageAttachmentsModal"
      />

      <ModalDialog v-show="multiSynchronizeDialog"
                  :headerText="$t('declarations.synchronize')"
                  @close="closeMultiSynchronizeDialog()"
                  modalSize="small"
      >
        <template v-slot:body>
          <div class="flex flex-col justify-start space-y-4">
            <div class="flex justify-start mt-8 pb-3">
              <label>
                {{ $t('properties.multi_synchronize_description') }}
              </label>
            </div>
            <div class="flex justify-around space-x-10 mt-4 pb-3" v-show="declarationNumberChanged">
              <InputCheck :text="$t('properties.step2.reference')" :isChecked="referenceSyncChecked"
                          @check="referenceSyncCheck"/>
            </div>
            <div class="flex justify-around space-x-10 mt-4 pb-3" v-show="extraDetailsChanged">
              <InputCheck :text="$t('properties.step2.extraDetails')" :isChecked="extraDetailsSyncChecked"
                          @check="extraDetailsSyncCheck"/>
            </div>
            <div class="flex justify-around space-x-10 mt-4 pb-3" v-show="assignedUserChanged">
              <InputCheck :text="$t('declarations.detail.tab1.assignee')" :isChecked="assignedUserSyncChecked"
                          @check="assignedUserSyncCheck"/>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex justify-end  mt-8  ">
            <div class="flex mt-4 mb-6 space-x-4 ">
              <Button @click="closeMultiSynchronizeDialog()" :text="$t('declarations.synchronize_no')"
                      :secondary="true"></Button>
              <Button :text="$t('declarations.synchronize_yes')" @click="saveMultiSynchronizeDialog()"
                      :disabled="saveMultiSyncBtnDisabled"></Button>
            </div>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-if="showParticipant" modalSize="large"
                  :headerText="$t('declarations.add_declaration.steps.step2.participation')"
                  @close="closeParticipantDlg">
        <template v-slot:body>
          <div class="space-y-4">
            <div class="flex justify-around space-x-8">
              <div class="col-span-12 w-full ">
                <InputSelect :options="$t('general.salutation_elster_options')"
                            :isError="errors.participant.salutation.invalid"
                            :errorMessage="$t('general.errors.required')"
                            :isRequired="participantEditable"
                            v-model="participant.salutation"
                            :label="$t('general.attrs.salutation')"
                            :selected-value="participant.salutation"
                            placeholder=""
                            :disabled="!participantEditable"/>
              </div>
              <div class="col-span-12 w-full ">
                <InputText :label="$t('clients.attrs.title')"
                          v-model="participant.title" placeholder=""
                          :disabled="!participantEditable"/>
              </div>
            </div>
            <div class=" flex justify-between space-x-8 mt-4">
              <div class=" w-full">
                <InputText class=""
                          :label="$t('clients.attrs.phone')"
                          :maxLength="16"
                          v-model="participant.phone"
                          placeholder=""
                          :disabled="!participantEditable"/>
              </div>
              <div class="w-full"/>
            </div>
            <div class="flex justify-around space-x-8 mt-4">
              <div class="col-span-6 w-full">
                <InputText class="w-full"
                          :label="$t('declarations.add_declaration.steps.step3.first_name_company_line_1')"
                          :isRequired="participantEditable"
                          :isError="errors.participant.firstName.invalid"
                          :errorMessage="$t('general.errors.required')"
                          :showHelpIconPopover="true"
                          :maxLength="25"
                          :popoverTexts="[$t('declarations.add_declaration.steps.step3.participant_name_help')]"
                          v-model="participant.firstName"
                          :disabled="!participantEditable"/>
              </div>
              <div class="col-span-6 w-full">
                <InputText class="w-full"
                          :label="$t('declarations.add_declaration.steps.step3.name_company_line_1')"
                          v-model="participant.lastName"
                          :showHelpIconPopover="true"
                          :maxLength="25"
                          :popoverTexts="[$t('declarations.add_declaration.steps.step3.participant_name_help')]"
                          :disabled="!participantEditable"/>
              </div>
            </div>
            <div class="flex justify-around space-x-8 mt-4">
              <div class="col-span-6 w-full">
                <InputText class=" w-full"
                          :label="$t('clients.attrs.street')"
                          v-model="participant.street" placeholder=""
                          :disabled="!participantEditable"/>
              </div>
              <div class="col-span-6 w-full">
                <InputText class="w-full"
                          :label="$t('clients.attrs.house_number')"
                          v-model="participant.houseNumber" placeholder=""
                          :disabled="!participantEditable"/>
              </div>
            </div>
            <div class="flex justify-around space-x-8 mt-4">
              <div class="col-span-6 w-full">
                <InputText class=" w-full"
                          :isRequired="participantEditable"
                          :label="$t('clients.attrs.postcode')"
                          :isError="errors.participant.zip.invalid"
                          :errorMessage="$t('general.errors.required')"
                          v-model="participant.zip"
                          @blur="searchZipParticipant"
                          :isZip="true"
                          placeholder=""
                          :disabled="!participantEditable"/>
              </div>
              <div class="col-span-6 w-full">
                <InputText class="w-full "
                          :label="$t('clients.attrs.po_box')"
                          v-model="participant.postbox"
                          placeholder=""
                          :disabled="!participantEditable"/>
              </div>
              <div class="col-span-6 w-full">
                <InputSelect
                    class="w-full"
                    :isRequired="participantEditable"
                    :label="$t('clients.attrs.city')"
                    :isError="errors.participant.city.invalid"
                    :errorMessage="$t('general.errors.required')"
                    v-model="participant.city"
                    :disabled="!participantEditable"
                    :options="cityList"
                    :selected-value="participant.city"
                    :loading="zipSearch"
                    :filterable="true"
                    :auto-complete="true"
                    :placeholder="$t('general.please_select')"
                    @on-item-selected="selectCityParticipant"
                />

              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5" v-if="!isReadOnly">
            <div v-if="!participantEditable" class="flex w-full justify-end space-x-4">
              <Button class="" :text="$t('buttons.close')" :secondary="true" @click="closeParticipantDlg"/>
              <Button class="" :text="$t('declarations.menus.edit')" @click="editParticipant"
                      :disabled="disabledAfterElster"/>
            </div>
            <div v-else class="flex w-full justify-end space-x-4">
              <Button class="" :text="$t('general.cancel')" :secondary="true" @click="closeParticipantDlg"/>
              <Button class="" :text="$t('general.submit')" @click="saveParticipant" :disabled="disabledAfterElster"/>
            </div>
          </div>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4" v-else>
            <Button class="" :text="$t('buttons.close')" :secondary="true" @click="closeParticipantDlg"/>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="participationDialog" :headerText="$t('declarations.detail.data_participation.title')"
                  @close="participationDialog = false">
        <template v-slot:body>
          <div class="flex flex-col ml-4">
            <div class="mt-8 pb-8 text-left">
              <label class="label text-left">
                <p>{{ $t('declarations.detail.data_participation.info1') }}</p>
                <p class="mt-2">{{ $t('declarations.detail.data_participation.info2') }}</p>
              </label>
            </div>
            <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
              <Button class="" :text="$t('declarations.detail.data_participation.button2')" :secondary="true"
                      @click="participationDialog = false"/>
              <Button class="" :text="$t('declarations.detail.data_participation.button1')" @click="checkParticipation"/>
            </div>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="deadlineAddEditDialog && hasAccess('deadline')"
                  :headerText="parseInt(deadline.deadlineId) === 0 ? $t('deadline.add_title') : $t('deadline.edit_title', {'documentType':deadline.document_type_label})"
                  @close="deadlineAddEditDialog = false">
        <template v-slot:body>
          <div class="flex flex-col justify-start space-y-4">
            <div class="info flex justify-start items-center">
              <img class="info__icon" src="@/assets/info_gray.svg"/>
              <label class="info__text mr-5" v-html="$t('deadline.add_info')"/>
            </div>
            <InputSelect class="w-full"
                        v-model="deadline.document"
                        :selectedValue="deadline.document"
                        :label="$t('deadline.attrs.document')"
                        :options="deadlineDocuments"
                        :loading="fetchingDeadlineDocuments"
                        :filterable="true"
                        :showHelpIcon="true"
                        overlay-append-to="body"
                        :isRequired="true"
                        :isError="deadlineErrors.document"
                        :errorMessage="$t('general.required')"
                        :tooltip="$t('deadline.attrs_help.document')"
                        v-show="parseInt(deadline.deadlineId) === 0"
            />
            <InputSelect class="w-full"
                        v-model="deadline.document_type"
                        :selectedValue="deadline.document_type"
                        :label="$t('deadline.attrs.document_type')"
                        :options="$t('deadline.document_types')"
                        :showHelpIcon="true"
                        :tooltip="$t('deadline.attrs_help.document_type')"
                        :isRequired="true"
                        :isError="deadlineErrors.document_type"
                        :errorMessage="$t('general.required')"
                        v-show="parseInt(deadline.deadlineId) === 0"
            />

            <div v-if="isOtherDeadlineDocumentTypeSelected" class="info flex justify-start items-center">
              <img class="info__icon" src="@/assets/info_gray.svg"/>
              <label class="info__text mr-5" v-html="$t('deadline.other_document_type_info')"/>
            </div>

            <div class="flex" v-show="parseInt(deadline.deadlineId) > 0">
              <img :src="deadline.document_icon" class="icon flex flex-col mr-2">
              <label class="text-left">{{ deadline.document_name }}</label>
            </div>
            <InputDate
                class="w-full"
                v-model="deadline.document_date"
                :label="$t('deadline.attrs.document_date')"
                appendTo="body"
                :showHelpIcon="true"
                :tooltip="$t('deadline.attrs_help.document_date')"
                :isRequired="true"
                :isError="deadlineErrors.document_date"
                :errorMessage="$t('general.required')"
                @date-selected="calculateDeadlineDate($event, -1, 'document_date')"
            />
            <InputDate
                class="w-full"
                v-model="deadline.announcement_date"
                :label="$t('deadline.attrs.announcement_date')"
                :showHelpIconPopover="true"
                :popoverTexts="[$t('deadline.attrs_help.announcement_date')]"
                appendTo="body"
                @date-selected="calculateDeadlineDate($event, -1, 'announcement_date')"
            />
            <InputDate
                class="w-full"
                v-model="deadline.deadline_reminder"
                :label="$t('deadline.attrs.deadline_reminder')"
                appendTo="body"
                :showHelpIcon="true"
                :tooltip="$t('deadline.attrs_help.deadline_reminder')"
            />
            <InputDate class="w-full" :label="$t('deadline.attrs.deadline')"
                      v-model="deadline.deadline"
                      :disabled="!isOtherDeadlineDocumentTypeSelected"
                      :showHelpIconPopover="true"
                      :popoverTexts="[$t('deadline.attrs_help.deadline')]"
            />
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex justify-end">
            <div class="flex space-x-4 mt-4 mb-4 ">
              <Button class="w-44" @click="deadlineAddEditDialog=false" :text="$t('buttons.cancel')"
                      :secondary="true"></Button>
              <Button class="w-44" :text="$t('buttons.save')" @click="saveDeadline"></Button>
            </div>
          </div>
        </template>
      </ModalDialog>
      <ModalDialog v-show="documentViewer.show" @close="closeDocumentViewerDialog"
                  :headerText="documentViewer.documentName">
        <template v-slot:body>
          <div class="flex flex-col w-full">
            <vue-pdf-embed :source="documentViewer.fileSource"/>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex mt-5 mb-5 w-full justify-end space-x-4">
            <Button :secondary="true" @click="downloadDocument" :text="$t('documents.download')"></Button>
            <Button :secondary="true" :text="$t('buttons.cancel')" @click="closeDocumentViewerDialog"/>
          </div>
        </template>
      </ModalDialog>

      <AppealTypeSelectionModal
        :visible="showAppealTypeSelectionModal"
        :declarationUid="parseInt(declarationData.prim_uid)"
        :federalStateUid="parseInt(declarationData.property.federalStateUid)"
        :currentAppealType="declarationData.appealType"
        :currentMassAppealType="parseInt(declarationData.massAppealType)"
        :currentMassAppealActDate="declarationData.massAppealActDate"
        @close="closeAppealTypeSelectionModal"
        @savedAppealType="savedAppealType"
      />

      <PageHeader
        :title="PageHeaderTitle"
        :breadcrumbs="breadcrumbs"
        :info="declarationInfo"
        :pagination="navRecords.length > 0 && getCurrentNavIndex > 0"
        :totalRecordCount="navRecords.length"
        :currentRecordIndex="getCurrentNavIndex"
        @page="changeNavPage"
      />

      <div class="archived-ribbon" v-if="declarationData.status === 'archived'">
        <span class="text">{{ $t('sidebar.declaration_submenu.archived') }}</span>
        <img :src="getAssetPath('ribben.png')">
      </div>

      <div class="flex mt-8 mr-6 arrows">
        <ArrowProgress
          v-for="(step,index) in $isBusiness ? stepsBusiness : steps"
          :key="index"
          :step="step"
        />
      </div>

      <div class="w-full mt-10 mr-8">
        <AppTabs :categories="categories" :selected-tab="activeTab" v-if="renderTabs" @click="activateTab">
          <!-- tab1 basic -->
          <template #tab-pane-basic>
            <div class="flex justify-end mr-7">
              <label class="id">{{ $t('declarations.detail.tab1.id') }}: {{ declarationData.declarationId }}</label>
            </div>
            <div class="flex justify-around space-x-10">
              <div class="rectangle w-full">
                <div class=" ">
                  <div class="flex justify-start items-center h-12 ml-5">
                    <label class="text-left text-h">{{ $t('declarations.detail.tab1.details') }}</label>
                  </div>
                  <hr class=" line"/>
                  <!-- content-->
                  <div class="tp-space">
                    <div class="floating text-left w-full">
                      <div class="einzel_field">
                        <label class="label-title">{{ $t('declarations.detail.tab1.assignee') }}:</label>
                        <InputSelect
                          :options="userList"
                          v-model="declarationData.assignedUser"
                          :disabled="isReadOnly"
                          :selected-value="declarationData.assignedUser"
                          :filterable="true"
                        />
                      </div>
                      <div class="einzel_field">
                        <label class="label-title">{{ $t('declarations.grid.declaration_number') }}:<span
                            style="color:red">*</span>
                          <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                                    :hideTriggers="['click']" style="display: inline-block;">
                            <!-- This will be the popover target (for the events and position) -->
                            <img class="help_icon self-center cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                            <!-- This will be the content of the popover -->
                            <template #popper>
                              <div class="tooltip-content">
                                <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_declaration_number"
                                  v-bind:key="text">
                                  {{ text }}
                                </p>
                                <h2 style="line-height:80%; margin-top: 5px;">
                                  <a class="popover-text"
                                    :href="popoverLink_declaration_number"
                                    target="_blank">
                                    {{ popoverLink_declaration_number }}
                                  </a>
                                </h2>
                              </div>
                              <!-- You can put other components too -->
                            </template>
                          </VDropdown>
                        </label>
                        <InputText
                            type="text"
                            v-model="declarationData.declarationNumber"
                            placeholder=""
                            :maxLimit="30"
                            :disabled="isReadOnly || disabledAfterElster"
                            :is-error="declarationNumberInvalid"
                            class=""
                        />
                      </div>
                      <div class="einzel_field">
                        <label class="label-title">{{ $t('declarations.add_declaration.steps.step2.type') }}:<span
                            style="color:red">*</span></label>
                        <InputSelect label="" :options="declaration_type_options"
                                    :disabled="isReadOnly || disabledAfterElster"
                                    v-model="declarationData.type" :selected-value="declarationData.type"
                        />
                      </div>
                      <div class="einzel_field">
                        <label class="label-title">{{
                            $t('declarations.add_declaration.steps.step2.reference_date')
                          }}:<span
                              style="color:red">*</span></label>
                        <InputSelect label="" :options="declaration_reference_date_options"
                                    :disabled="isReadOnly || disabledAfterElster"
                                    v-model="declarationData.referenceDate"
                                    :selected-value="declarationData.referenceDate"
                        />
                      </div>
                      <div class="einzel_field">
                        <label class="label-title">{{ $t('declarations.grid.complexity') }}:</label>
                        <InputSelect
                            v-model="declarationData.property.complexity"
                            :selected-value="declarationData.property.complexity"
                            :options="$t('properties.complexity_options')"
                            :disabled="true"
                        />
                      </div>
                      <div class="einzel_field">
                        <label class="label-title w-full">{{
                            $t('declarations.add_declaration.steps.step2.extra_details')
                          }}:</label>
                        <div class=" check extra_dtl_check ml-2 mb-2">
                          <InputCheck class="ml-1 mb-2" label="" :is-checked="declarationData.extraDetails !== ''"
                                      :isDisabled="isReadOnly"
                                      @check="extraDetailsChecked"/>
                        </div>
                      </div>

                      <div class="einzel_field">
                        <label class="label-title w-full flex">
                          <span>
                            {{ $t('declarations.add_declaration.steps.step2.participation') }}:
                          </span>
                          <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                                    :hideTriggers="['click']" style="display: inline-block;">
                            <!-- This will be the popover target (for the events and position) -->
                            <img class="help_icon self-center cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                            <!-- This will be the content of the popover -->
                            <template #popper>
                              <div class="tooltip-content">
                                <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_participation"
                                  v-bind:key="text">
                                  {{ text }}
                                </p>
                                <h2 style="line-height:80%; margin-top: 5px;">
                                  <a class="popover-text"
                                    :href="popoverLink_participation"
                                    target="_blank">
                                    {{ popoverLink_participation }}
                                  </a>
                                </h2>
                              </div>
                              <!-- You can put other components too -->
                            </template>
                          </VDropdown>
                        </label>

                        <div class="check extra_dtl_check ml-2 flex justify-items-center">
                          <div class="flex flex-col">
                            <InputCheck
                                class="ml-1 mb-2"
                                label=""
                                :is-checked="isDeclarationParticipationChecked"
                                :isDisabled="isReadOnly || disabledAfterElster"
                                :enable-disabled-checked="true"
                                :checkable="isParticipationCheckable"
                                @check="participationChecked"
                                @clickNonCheckable="clickNonCheckableParticipation"
                            />
                          </div>
                          <div class="flex flex-col w-full h-5">
                            <label v-show="showParticipantLink" class="cursor-pointer link" @click="showParticipantDlg">
                              {{ $t('declarations.menus.view') }}</label>
                          </div>
                        </div>
                      </div>

                      <div class="einzel_field" v-if="false">
                        <label class="label-title">{{ $t('declarations.detail.tab1.tax_office') }}:</label>
                        <InputSelect label="" disabled/>
                      </div>
                      <div class="einzel_field" v-show="!$isBusiness">
                        <label class="label-title">
                          {{ $t('declarations.detail.tab1.fee_invoice_sent') }}:
                          <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                                    :hideTriggers="['click']" style="display: inline-block;"
                                    v-if="popoverText_feeInvoiceSent !== ''">
                            <!-- This will be the popover target (for the events and position) -->
                            <img class="help_icon self-center cursor-pointer" :src="getAssetPath('help_green.svg')"/>
                            <!-- This will be the content of the popover -->
                            <template #popper>
                              <div class="tooltip-content">
                                <p class="space-y-2 mb-1 popover-text">
                                  {{ popoverText_feeInvoiceSent }}
                                </p>
                              </div>
                              <!-- You can put other components too -->
                            </template>
                          </VDropdown>
                        </label>
                        <div class=" check extra_dtl_check ml-2 mb-2">
                          <InputCheck class="ml-1 mb-2" label="" @check="invoiceSent"
                                      :isDisabled="isReadOnly"
                                      :is-checked="declarationData.feeInvoiceSent === '1'"/>
                        </div>
                      </div>
                      <div class="einzel_field" v-show="showHideFarmRegulationsInTaxCalculationCheck">
                        <label class="label-title">
                          {{ $t('declarations.detail.tab1.hide_farm_regulations_in_tax_calculation') }}:
                        </label>
                        <div class="check extra_dtl_check ml-2 mb-2">
                          <InputCheck
                            class="ml-1 mb-2"
                            label=""
                            :isDisabled="isReadOnly"
                            :is-checked="declarationData.hideFarmRegulationsInTaxCalculation  === '1'"
                            @check="hideFarmRegulationsInTaxCalculationCheck"
                          />
                        </div>
                      </div>
                      <div class="einzel_field" v-show="!$isBusiness">
                        <label class="label-title w-full flex"><span> {{
                            $t('declarations.detail.tab1.property_value')
                          }}:</span>
                          <VDropdown :showTriggers="['click','hover']" :popperTriggers="['hover','click']"
                                    :hideTriggers="['click']" style="display: inline-block;">
                            <!-- This will be the popover target (for the events and position) -->
                            <img alt="question mark" class="my-auto help_icon self-center cursor-pointer"
                                :src="getAssetPath('help_green.svg')"/>
                            <!-- This will be the content of the popover -->
                            <template #popper>
                              <div class="tooltip-content">
                                <p class="space-y-2 mb-1 popover-text" v-for="text in popoverTexts_object_value"
                                  v-bind:key="text">
                                  {{ text }}
                                </p>
                              </div>
                              <!-- You can put other components too -->
                            </template>
                          </VDropdown>
                        </label>

                        <div v-if="calculatingPropertyValue" class="green-spinner">
                          <i class="pi pi-spin pi-spinner"/>
                        </div>
                        <div class="flex w-full justify-start space-x-4" v-else>
                          <div class="w-full"><p class="text-2 flex items-center w-2/3">
                            {{ renderFloatData(propertyValue, ' €') }} </p></div>
                          <div style="position: relative">
                            <ButtonWithOption
                                @click="handleTabsOtherButtonClick"
                                class="button-label text-center bg-green text-white "
                                primary
                                :text="$t('buttons.other')"
                                :options="otherButtonOptions"
                            ></ButtonWithOption>
                          </div>
                        </div>
                      </div>
                      <div class="einzel_field"
                          v-show="declarationData.status === 'dispute' || declarationData.status === 'assessment_received'">
                        <label class="label-title">{{ $t('declarations.detail.tab1.appeal_deadline') }}:</label>
                        <div class=" check extra_dtl_check ml-2 mb-2">
                          <InputDate
                              class="w-full"
                              v-model="declarationData.appealDeadline"
                              placeholder=""
                              :disabled="isReadOnly"
                          />
                        </div>
                      </div>
                      <div class="einzel_field" v-show="declarationData.status === 'dispute'">
                        <label class="label-title">{{ $t('declarations.detail.tab1.appeal_sent_on') }}:</label>
                        <div class=" check extra_dtl_check ml-2 mb-2">
                          <InputDate
                              class="w-full"
                              v-model="declarationData.appealSentOn"
                              placeholder=""
                              :disabled="isReadOnly"
                          />
                        </div>
                      </div>
                      <div class="einzel_field" v-show="declarationData.status === 'dispute'">
                        <label class="label-title">{{ $t('declarations.detail.tab1.appeal_types') }}:</label>
                        <div class="flex flex-col">
                          <div class="flex flex-row space-x-4">
                            <InputSelect
                              class="w-full"
                              :isLabelEnabled="false"
                              :options="$t('declarations.detail.appeal_type_selection.declaration_appeal_type')"
                              :disabled="true"
                              v-model="declarationData.appealType"
                              :selected-value="declarationData.appealType"
                            />
                            <Button
                              :icon="getAssetPath('edit_green.svg')"
                              :isTextButton="true"
                              :isSlim="true"
                              @click="showAppealTypeSelectionModal = true"
                            />
                          </div>

                          <div
                            v-if="!hasAccessToMassAppealFeature && declarationData.appealType === 'mass'"
                            class="mt-2"
                          >
                            <span class="label text-red">{{ $t('declarations.detail.appeal_type_selection.mass_appeal_not_available_in_plan') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rectangle w-full ">
                <div class="">
                  <div class="mandant-title">
                    <div class="flex justify-between items-center">
                      <div class="flex justify-start">
                        <label class="text-h  ml-4"><strong>{{ $t('declarations.detail.tab1.client') }}</strong></label>
                        <label class="text-h ml-1" style="color: #aeaeae">({{ declarationData.client.clientUid }})</label>
                      </div>

                      <IconLabel v-if="declarationData.freezedClientId > 0" :blackText="true"
                                :icon="getAssetPath('eye_green.svg')"
                                @click="editClient"
                                iconStyle="blackish"
                                class="icon-less-space -mt-1 mr-4"
                                :label="$t('declarations.detail.tab1.view_client') "/>
                      <IconLabel v-else :blackText="true" :icon="getAssetPath('edit_user_green.svg')"
                                @click="editClient"
                                iconStyle="blackish"
                                class="icon-less-space -mt-1 mr-4"
                                :label="$t('declarations.detail.tab1.editclient') "/>
                    </div>
                  </div>
                  <hr class=" line"/>
                  <!--  content-->
                  <div class="tp-space">
                    <div class="flex-col text-left w-full mt-4 ml-2">
                      <div class="row-client-data">
                        <p class="text flex-1">{{ $t('declarations.detail.tab1.name') }}:</p>
                        <p class="text-2 flex-1">{{ declarationData.client.clientName }}</p>
                      </div>
                      <div class="row-client-data">
                        <p class="text flex-1">{{ $t('declarations.detail.tab1.address') }}:</p>
                        <p class="text-2 flex-1">{{ renderAddress }}</p>
                      </div>
                      <div class="row-client-data">
                        <p class="text flex-1">{{ $t('declarations.detail.tab1.email') }}:</p>
                        <p class="text-2 flex-1">{{ renderEmail }}</p>
                      </div>
                      <div class="row-client-data">
                        <p class="text flex-1">{{ $t('declarations.detail.tab1.mobile') }}:</p>
                        <p class="text-2 flex-1">{{ renderMobile }}</p>
                      </div>
                      <div class="row-client-data">
                        <p class="text flex-1">{{ $t('declarations.detail.tab1.tel') }}:</p>
                        <p class="text-2 flex-1">{{ renderPhone }}</p>
                      </div>
                      <div class="row-client-data">
                        <p class="text flex-1">{{ $t('declarations.detail.tab1.internal_note_client') }}:</p>
                        <p class="text-2 flex-1" style="word-break: break-all;">{{ renderInternalNoteClient }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col  space-x-10 mt-6">
              <div class="rectangle w-full ">
                <div class="  ">
                  <div class="flex  justify-between items-center h-14 ml-5">
                    <label class="text-left text-h  ">{{ $t('declarations.detail.tab1.property_details') }}
                      <label class="id">({{ declarationData.property.propertyUid }})</label></label>
                    <div class="flex">
                      <IconLabel class="icon-less-space -mt-1 mr-4" :blackText="true"
                                :icon="getAssetPath('elster_validate.svg')"
                                @click="declarationWorkflow('elster_validate')"
                                :label="$t('declarations.detail.tab1.elster_validate')"
                                iconStyle="blackish"
                                v-if="!isReadOnly && declarationData.freezedPropertyId === 0"/>
                      <IconLabel v-if="declarationData.freezedPropertyId || isReadOnlyProperty"
                                class="icon-less-space -mt-1 mr-4"
                                :blackText="true"
                                :icon="getAssetPath('eye_green.svg')"
                                @click="editProperty"
                                iconStyle="blackish"
                                :label="$t('declarations.detail.tab1.view_property')"/>
                      <IconLabel v-else class="icon-less-space -mt-1 mr-4" :blackText="true"
                                :icon="getAssetPath('edit-property.svg')"
                                @click="editProperty"
                                iconStyle="blackish"
                                :label="$t('declarations.detail.tab1.edit_property')"/>
                    </div>
                  </div>
                  <hr class=" line"/>
                  <!-- content -->
                  <div class="py-2 align-left  w-auto sm:px-2 lg:px-2">
                    <div class="overflow-hidden property_details">
                      <div class="flex justify-around home-gray ">

                        <div class="w-full flex justify-center items-center home-gray-text">
                          <div class="floating text-left mr-8 ml-5 space-y-3 space-x-3">
                            <img class="w-11/12 " style="opacity: 0.33" src="@/assets/home-icon.svg"/>
                          </div>
                          <div class="floating text-left mr-8 ml-5 space-y-3">
                            <p class="text-address"><strong v-html="textAddressHtml"></strong></p>
                            <p class="flex map-icon-text" v-if="showGoogleLink">
                              <img class="w-1/12" src="@/assets/map_icon.svg"/>
                              <a target="_blank" :href="getGoogleMapsLink()">{{
                                  $t('declarations.detail.tab1.open_map')
                                }}</a>
                            </p>
                          </div>
                        </div>
                        <div class="w-full property_details_text">
                          <div class="floating text-left clientsection">
                            <p class="text">{{ $t('properties.step3.ownership_structure') }}:</p>
                            <p class="text">{{ $t('properties.step3.property_type') }}:</p>
                            <p class="text">{{ $t('properties.step3.usage_type') }}:</p>
                            <p class="text" v-if="propertyData.step2.economicEntityType === '3'">
                              {{ $t('declarations.detail.tab1.official_area') }}:</p>
                            <p class="text" v-if="propertyData.step2.economicEntityType === '3'">
                              {{ $t('declarations.detail.tab1.area_of_use') }}:</p>
                            <p class="text" v-if="propertyData.step2.economicEntityType != '3'">
                              {{ $t('declarations.detail.tab1.flache') }}:</p>
                            <p class="text">{{ $t('declarations.detail.tab1.bodenrichtwert') }}:</p>
                          </div>
                          <div class="floating text-left clientsection">
                            <p class="text-2 text-overflow">{{
                                renderData($t('properties.step3.ownershipStructureMap')[declarationData.property.ownershipStructure])
                              }}</p>
                            <p class="text-2">{{
                                renderData(getPropertyType)
                              }}</p>
                            <p class="text-2">{{
                                renderData($t('properties.step3.taxExemptionMap')[declarationData.property.taxExemption])
                              }}</p>
                            <p class="text-2" v-if="propertyData.step2.economicEntityType === '3'">
                              {{ getSumOfOfficialArea | formatNumber }} m²</p>
                            <p class="text-2" v-if="propertyData.step2.economicEntityType === '3'">
                              {{ getSumOfAreaOfUse | formatNumber }} m²</p>
                            <p class="text-2" v-if="propertyData.step2.economicEntityType != '3'">{{ sumOfAreas }} m²</p>
                            <p class="text-2">{{ renderFloatData(declarationData.property.areaOfTheLandValue1) }} €</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col mt-6">
              <div class="w-full">
                <InputText
                    class="mt-2 w-full "
                    bodyClass="textarea"
                    v-model="declarationData.internalNote"
                    :label="$t('declarations.detail.internal_note')"
                    :isMultiline="true"
                    :maxLimit="9999"
                    :errorMessage="$t('general.errors.limitDesc').replace('%length%', declarationData.internalNote.length).replace('%maxLimit%', 9999)"
                    placeholder=""
                    :disabled="isReadOnly"
                />
              </div>
              <div class="w-full  ">
                <InputText
                    v-show="showExtraDetails"
                    class="mt-6 w-full "
                    bodyClass="textarea"
                    v-model="declarationData.extraDetails"
                    :label="$t('declarations.detail.more_info')"
                    :isMultiline="true"
                    :maxLimit="999"
                    :errorMessage="$t('general.errors.limitDesc').replace('%length%', declarationData.extraDetails.length).replace('%maxLimit%', 999)"
                    placeholder=""
                    :disabled="isReadOnly"
                />
              </div>
              <div class="w-full">
                <InputText
                  class="mt-6 w-full "
                  bodyClass="textarea"
                  v-model="renderInternalNoteProperty"
                  :label="$t('declarations.detail.internal_note_property')"
                  :isMultiline="true"
                  placeholder=""
                  :disabled="true"
                />
              </div>
            </div>
          </template>

          <!-- tab2 receiver-->
          <template #tab-pane-receiver>
            <div class="w-full mb-3" v-show="!show">
              <div class="flex justify-start items-center py-3 px-2 rounded bg-gray_light mb-10">
                <img class="image ml-2" :src="getAssetPath('info_gray.svg')"/>
                <label class="text-left ml-2">{{ poaCharacterLimitInfo }}</label>
              </div>

              <p class="text-2">{{ $t('declarations.add_declaration.steps.step3.title') }}</p>
              <div class="vollmach mt-2 mb-8">
                <div class="Keine_input">
                  <label class="flex items-center space-x-3">
                    <RadioButton name="recipientType" value="none" v-model="declarationData.recipientType"
                                :disabled="isReadOnly || disabledAfterElster"
                                @click="selectReceiverType('none')"/>
                    <span class=" mt-1">{{ $t('declarations.add_declaration.steps.step3.none') }}</span>
                  </label>
                </div>
                <label class="flex items-center space-x-3" v-show="!isBusiness()">
                  <RadioButton name="recipientType" value="tax_consultant"
                              :disabled="isReadOnly || disabledAfterElster"
                              v-model="declarationData.recipientType" @click="selectReceiverType('tax_consultant')"/>
                  <span class="mt-1 ">{{ $t('declarations.add_declaration.steps.step3.tax_consultant') }}</span>
                </label>
                <label class="flex items-center space-x-3" v-show="showBranchPowerOwAttorney">
                  <RadioButton name="recipientType" value="power_of_attorney_from_branch"
                              :disabled="isReadOnly || disabledAfterElster"
                              v-model="declarationData.recipientType"
                              @click="selectReceiverType('power_of_attorney_from_branch')"/>
                  <span class="mt-1 ">{{
                      $t('declarations.add_declaration.steps.step3.power_of_attorney_from_branch')
                    }}</span>
                </label>
                <label class="flex items-center space-x-3" v-show="showClientPowerOwAttorney">
                  <RadioButton name="recipientType" value="power_of_attorney_from_client"
                              :disabled="isReadOnly || disabledAfterElster"
                              v-model="declarationData.recipientType"
                              @click="selectReceiverType('power_of_attorney_from_client')"/>
                  <span class="mt-1 ">{{
                      $t('declarations.add_declaration.steps.step3.power_of_attorney_from_client')
                    }}</span>
                </label>
                <label class="flex items-center space-x-3">
                  <RadioButton name="recipientType" value="others" v-model="declarationData.recipientType"
                              :disabled="isReadOnly || disabledAfterElster"
                              @click="selectReceiverType('others')"/>
                  <span class="mt-1"> {{ $t('declarations.add_declaration.steps.step3.others') }}</span>
                </label>
              </div>
            </div>

            <div class="space-y-4" v-show="declarationData.recipientType!=='none'">
              <div class="flex justify-around space-x-8" v-show="declarationData.recipientType === 'tax_consultant'">
                <div class="col-span-12 w-full ">
                  <InputSelect :options="powersOfAttorneyList"
                              v-model="declarationData.powerOfAttorney"
                              :selected-value="declarationData.powerOfAttorney"
                              :placeholder="$t('general.please_select')"
                              :disabled="isReadOnly"
                              :filterable="true"
                              @on-item-selected="powerOfAttorneySelected"
                  />
                </div>
                <div class="w-full text-left mt-3">
                  <span class="link" @click="addNewAttorney">{{
                      $t('declarations.add_declaration.steps.step3.new_power_of_attorney')
                    }}</span>
                </div>
              </div>
              <div class="flex justify-around space-x-8">
                <div class="col-span-12 w-full ">
                  <InputSelect :options="$t('general.salutation_elster_options')"
                              :isError="errors.recipient.salutation.invalid"
                              :errorMessage="$t('general.errors.required')"
                              :isRequired="!recipientDisabled"
                              v-model="recipient.salutation"
                              :label="$t('general.attrs.salutation')"
                              :selected-value="recipient.salutation"
                              placeholder=""
                              :disabled="recipientDisabled"/>
                </div>
                <div class="col-span-12 w-full ">
                  <InputText :label="$t('clients.attrs.title')"
                            v-model="recipient.title" placeholder=""
                            :disabled="recipientDisabled"/>
                </div>
              </div>
              <div class=" flex justify-between space-x-8 mt-4">
                <div class="  w-full">
                  <InputText class=""
                            :label="$t('clients.attrs.phone')"
                            :maxLength="16"
                            v-model="recipient.phone"
                            placeholder=""
                            :disabled="recipientDisabled"/>
                </div>
                <div class="w-full"/>

              </div>
              <div class="flex justify-around space-x-8 mt-4">
                <div class="col-span-6 w-full">
                  <InputText class="w-full"
                            :label="$t('settings.powers_of_attorney.table.first_name')"
                            :isRequired="true"
                            :isError="errors.recipient.firstName.invalid"
                            :errorMessage="$t('general.errors.required')"
                            v-model="recipient.firstName"
                            :disabled="recipientDisabled"/>
                </div>
                <div class="col-span-6 w-full">
                  <InputText class="w-full"
                            :label="$t('settings.powers_of_attorney.table.last_name')"
                            :isRequired="false"
                            v-model="recipient.lastName"
                            :disabled="recipientDisabled"/>
                </div>
              </div>
              <div class="flex justify-around space-x-8 mt-4">
                <div class="col-span-6 w-full">
                  <InputText class=" w-full"
                            :label="$t('clients.attrs.street')"
                            v-model="recipient.street" placeholder=""
                            :disabled="recipientDisabled"/>
                </div>
                <div class="col-span-6 w-full">
                  <InputText class="w-full"
                            :label="$t('clients.attrs.house_number')"
                            v-model="recipient.houseNumber" placeholder=""
                            :disabled="recipientDisabled"/>
                </div>
              </div>
              <div class="flex justify-around space-x-8 mt-4">
                <div class="col-span-6 w-full">
                  <InputText class=" w-full"
                            :isRequired="!recipientDisabled"
                            :label="$t('clients.attrs.postcode')"
                            :isError="errors.recipient.zip.invalid"
                            :errorMessage="$t('general.errors.required')"
                            v-model="recipient.zip"
                            @blur="searchZip"
                            :isZip="true"
                            placeholder=""
                            :disabled="recipientDisabled"/>
                </div>
                <div class="col-span-6 w-full">
                  <InputText class="w-full "
                            :label="$t('clients.attrs.po_box')"
                            v-model="recipient.postbox"
                            placeholder=""
                            :disabled="recipientDisabled"/>
                </div>
                <div class="col-span-6 w-full">
                  <InputSelect
                      class="w-full"
                      :isRequired="!recipientDisabled"
                      :label="$t('clients.attrs.city')"
                      :isError="errors.recipient.city.invalid"
                      :errorMessage="$t('general.errors.required')"
                      v-model="recipient.city"
                      :disabled="recipientDisabled"
                      :options="cityList"
                      :selected-value="recipient.city"
                      :loading="zipSearch"
                      :filterable="true"
                      :auto-complete="true"
                      :placeholder="$t('general.please_select')"
                      :maxLength="25"
                      @on-item-selected="selectCity"
                  />
                </div>
              </div>
              <div class="flex space-x-10 mt-5">
                <div class="col-span-12">
                  <label>{{ $t('declarations.add_declaration.steps.step3.authorized_receiver') }}<span style="color:red;">*</span></label>

                  <div class=" flex mt-5 space-x-2 items-center">
                    <label class="flex space-x-3">
                      <RadioButton :disabled="recipientDisabled"
                                  v-model="recipient.authorizedReceiver"
                                  name="authorized_receiver" value="1"
                      />
                      <span class="ml-2 mt-1 textradio">{{ $t('general.yes') }}</span>
                    </label>
                    <label class="flex space-x-3">
                      <RadioButton name="authorized_receiver" :disabled="recipientDisabled"
                                  v-model="recipient.authorizedReceiver"
                                  value="0"/>
                      <span class=" mt-1 textradio">{{ $t('general.no') }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- tab3 tax calculation-->
          <template #tab-pane-tax-calculation>
            <div v-show="fetchingTaxCalculation && !taxCalculationError">
              <div class="flex flex-col relative h-150">
                <div class="flex flex-col w-full loading-container">
                  <div class="spin"></div>
                </div>
              </div>
            </div>
            <div>
              <div class="flex" v-if="getTaxModel">
                <label class="label text-left">{{ $t('declarations.detail.tax_model') }}: {{ getTaxModel }}</label>
              </div>
              <div class="flex flex-gap-12 flex-wrap mt-6" id="taxCalcButtons">
                <Button
                  class="declaration-buttons"
                  v-show="activeTab ==='tax-calculation' && taxCalculationHtml"
                  :text="$t('declarations.tax_calculation.scroll_end')"
                  @click="taxCalculationScroll('id_taxcalc_end')"
                  :icon="require('@/assets/arrow_down.svg')"
                />
                <Button
                  v-show="canShowTaxCalculationSidebar"
                  @click="$refs.taxCalculationSidebar.toggle(declarationData.propertyId)"
                  :text="$t('declarations.tax_calculation.btn_navigation')"
                  class="declaration-buttons"
                />
                <Button
                  v-if="tax_calc_needs_community"
                  :text="$t('declarations.tax_calculation.select_community')"
                  @click="openCommunitySelection"
                />
                <Button
                  @click="regenerateCalculation(false)"
                  :text="$t('declarations.detail.buttons.review.regenrate')"
                  class="declaration-buttons"
                />
                <Button
                  v-if="areaOfTheLandButtonShow"
                  @click="showAreaOfTheLandDataModal"
                  :text="$t('declarations.detail.area_of_the_land_data_modal_button.title')"
                  class="declaration-buttons"
                />
                <Button
                  @click="showAssessmentRateModal"
                  :text="$t('declarations.detail.assessment_rate_modal_button.title')"
                  class="declaration-buttons"
                />
              </div>
              <div v-show="!fetchingTaxCalculation && !taxCalculationError"
                  class="flex mt-10 justify-center items-center">
                <div v-if="taxCalculationHtml" class="long-box flex flex-col justify-start relative">
                  <div class="pdf-icon">
                    <img :src="getAssetPath('document_pdf.svg')" @click="downloadTaxCalculationPdf">
                    <img :src="getAssetPath('eye_green.svg')" @click="openTaxCalculationPdf">
                  </div>
                  <div v-html="taxCalculationHtml"/>
                </div>
              </div>
            </div>
          </template>
          <!--  tab6 elster-message-->
          <template #tab-pane-elster-message>
            <hr class="hr-line-user"/>
            <AppGrid grid-id="elsterMessagesGrid"
                    data-endpoint="declaration/list_elster_message"
                    :columns="elster_message_columns"
                    :auto-load="false"
                    :showFilter="false"
                    :showSelectCol="false"
                    :show-create-record="false"
                    :show-view-menu="false"
                    :server-params="elsterMessageListServerParams"
                    :state-ful="false"
                    :menus="elsterMessageGridMenus"
                    @menu-clicked="elsterMessageGridMenuClick"
                    @col-click="elsterMessageColClick"
            />
          </template>
          <!--  tab7 deadline -->
          <template #tab-pane-deadline>
            <AppGrid v-if="deadlineModuleEnabled" :grid-id="deadlineGridId"
                    :show-select-col="false"
                    :show-search="true"
                    dataEndpoint="deadline/list"
                    :columns="deadlineGridColumns"
                    default-sort-attr="deadline"
                    :default-sort-dir="1"
                    :selectable="true"
                    selection-data-key="id"
                    :menus="deadlineGridMenus"
                    :auto-load="false"
                    :show-create-record="false"
                    :pagination="true"
                    :show-filter="false"
                    :serverParams='{"declarationId": declarationUid}'
                    @menu-clicked="deadlineGridMenuClick"
                    @col-click="deadlineGridColClick"
                    @prepend-icon-click="deadlineGridColClick"
                    @on-data-loading="deadlineGridDataLoaded=false"
                    @on-data-loaded="onDeadlineGridDataLoaded"
                    @on-item-selected="statusChanged"
            />
            <div v-else class="mt-8 pb-8 text-center justify-center-text">
              <img class="w-1/12" src="@/assets/war_icon.svg"/>
              <p>{{ $t('general.errors.feature_not_in_package') }}</p>
            </div>
          </template>
          <!-- tab4 documents-->
          <template #tab-pane-document>
            <Document grid-id="declaration_documents"
                      :parent-id="declarationData.prim_uid"
                      parent-type="DECLARATION"
                      :read-only="isReadOnly"
                      :viewer-breadcrumbs="viewerBreadcrumbs"
                      :datev-client="isDatevClient"
                      @document-uploaded="newDocumentUploaded"
            />
          </template>

          <!-- tab5 protocol-->
          <template #tab-pane-protocol>
            <VersionGrid :parent-id="parseInt(declarationData.prim_uid)" parent-type="declarations"/>
          </template>

          <template #tab-footer>
            <div>
              <div class="flex justify-between items-center mt-5" id="id_declaration_details_end">
                <div class="flex w-full justify-end space-x-4">
                  <Button class="declaration-buttons" :text="$t('buttons.cancel')"
                          @click="goBack" :secondary="true"/>

                  <Button class="declaration-buttons"
                    id="button_tax_calc_scroll_start"
                    v-show="activeTab ==='tax-calculation' && taxCalculationHtml"
                    :text="$t('declarations.tax_calculation.scroll_start')"
                    @click="taxCalculationScroll('id_declaration_details_top')"
                    :icon="require('@/assets/arrow_up.svg')"
                  />

                  <div v-if="declarationData.status === 'dispute'" class="flex justify-end">
                    <Button
                            v-show="showWorkflowButton('revise_declaration')" class="declaration-buttons" :text="$t('declarations.detail.buttons.revise_declaration.title')"
                            :class="getWorkflowBtnCls('revise_declaration')"
                            :subText="getWorkflowBtnSubText('revise_declaration')"
                            :icon="getAssetPath('circle_left_arrow.svg')" @click="declarationWorkflow('revise_declaration')"
                            @deactivateElsterAutoSubmission="deactivateElsterAutoSubmission"
                    />

                    <ButtonWithOption
                      @click="handleTabsMoreButtonClick"
                      class="button-label text-center bg-green text-white ml-4"
                      primary
                      :text="$t('buttons.other')"
                      :options="moreButtonOptions"
                    ></ButtonWithOption>
                  </div>

                  <div v-else class="flex justify-end space-x-4 " >
                    <Button v-for="(item, index) in $t('declarations.detail.buttons')" :key="'btn_' + index"
                            v-show="showWorkflowButton(index)" class="declaration-buttons" :text="item.title"
                            :class="getWorkflowBtnCls(index)"
                            :subText="getWorkflowBtnSubText(index)"
                            :elsterAutoSubmissionApproved="index === 'elster_transfer' && parseInt(declarationData.elsterSubmissionApproved) === 1"
                            :disabled="index === 'elster_transfer' && parseInt(declarationData.elsterSubmissionApproved) === 1"
                            :icon="getAssetPath(item.icon)" @click="declarationWorkflow(index)"
                            @deactivateElsterAutoSubmission="deactivateElsterAutoSubmission"
                    />
                  </div>

                  <Button v-show="showWorkflowButton('save')" class="declaration-buttons" :text="$t('buttons.save')"
                          :icon="require('@/assets/floppy_white.svg')"
                          @click="checkSynchronizeHandler"/>
                </div>
              </div>
            </div>
          </template>
        </AppTabs>
        <div class="w-full h-full mr-8" v-else style="min-height: 800px;"/>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton';
import AppTabs from "@/components/AppTabs";
import IconLabel from "../labels/IconLabel";
import InputCheck from "../inputs/InputCheck";
import Document from "../Document";
import VersionGrid from "../VersionGrid";
import {DeclarationService} from "@/services/declaration.service";
import {PropertyService} from "@/services/property.service";
import {ClientService} from "@/services/client.service";
import {UserService} from "@/services/user.service";
import {requiredIf} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {taxModels} from "@/core/constants";
import {PowersOfAttorneyService} from "@/services/powersofattorney.service";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import ModalDialog from "@/components/ModalDialog";
import InputSelect from "@/components/inputs/InputSelect";
import AppGrid from "@/components/AppGrid";
import Chip from "primevue/chip";
import _clone from 'lodash/clone'
import {DocumentService} from "@/services/document.service";
import TaxCalculationErrorModal from "./components/TaxCalculationErrorModal.vue";
import TaxCalculationSidebar from "./components/TaxCalculationSidebar.vue";
import ChangeAssessmentRateModal from "@/components/declarations/components/ChangeAssessmentRateModal.vue";
import ElsterMessageAttachmentsModal from '@/components/declarations/components/ElsterMessageAttachmentsModal.vue';
import AppealTypeSelectionModal from '@/components/declarations/components/AppealTypeSelectionModal.vue';
import ElsterValidationResults from '@/components/Elster/ElsterValidationResults.vue';
import {directive as onClickaway} from "vue-clickaway";
import {pdfjsCVEMixin} from "@/core/utils/PdfjsCVEMixin";

export default {
  name: "edit",
  components: {
    TaxCalculationErrorModal,
    TaxCalculationSidebar,
    ChangeAssessmentRateModal,
    ElsterMessageAttachmentsModal,
    AppealTypeSelectionModal,
    ElsterValidationResults,
    AppTabs,
    InputCheck,
    InputSelect,
    ModalDialog,
    Document,
    VersionGrid,
    IconLabel,
    RadioButton,
    VuePdfEmbed,
    AppGrid,
    Chip
  },
  directives: {
    onClickaway: onClickaway,
  },
  mixins: [pdfjsCVEMixin],
  data() {
    return {
      isPropertyValueMenuOpen: false,
      documentDownloadedCalculation: false,
      documentDownloadedAssessment: false,
      service: new DeclarationService(),
      propertyService: new PropertyService(),
      clientService: new ClientService(),
      documentService: new DocumentService(),
      deadlineGridId: 'declaration-deadline',
      navRecords: [],
      popoverTexts_declaration_number: [
        this.$t('declarations.add_declaration.steps.step2.tooltip.type'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverLink_declaration_number: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/",
      showElsterMessage: false,
      showElsterMessageResponse: false,
      showTaxCalculationErrorModal: false,
      showModalIsDeclarationWithoutSubmission: false,
      showAppealTypeSelectionModal: false,
      taxCalculationErrors: [],
      taxCalculationErrorKey: "",
      renderTabs: false,
      activeTab: 'basic',
      PageHeaderTitle: '',
      communityList: [],
      textAddressHtml: '',
      taxCalculationError: false,
      showCommunityOpener: false,
      tax_community: "",
      save_calculation_community: true,
      total_community_count: 0,
      communityIsValidForTax: true,
      propertyValue: 0,
      showGoogleLink: true,
      declarationUid: 0,
      declarationData: {
        "recipient": {},
        "branch": {},
        "client": {},
        "property": {},
        "internalNote": "",
        "extraDetails": "",
        "participation": "",
        "status": "",
        "calculationCommunity": '',
        "submissions": {}
      },
      initialDeclarationDataString: null,
      elster_message_columns: [
        {
          field: 'createdAt',
          header: this.$t('declarations.detail.elster_message.table_columns.date'),
          isChecked: true
        },
        {
          field: 'messageTypeText',
          elType: 'text',
          header: this.$t('declarations.detail.elster_message.table_columns.message'),
          isChecked: true,
        },
        {
          field: 'submission',
          header: this.$t('declarations.detail.elster_message.table_columns.submission'),
          isChecked: true,
          sortable: false,
          prependIcon: true,
          getIcon: (row) => {

            if (parseInt(row.isDraft) > 0) {
              return require('@/assets/orange_edit.svg');
            } else if (row.elsterSubmissionId !== null) {
              return require('@/assets/success.svg');
            } else if (parseInt(row.submissionTries) >= 5 && row.elsterSubmissionId === null) {
              return require('@/assets/elster_validate_error.svg');
            }

            return require('@/assets/hour_glass_blue.svg');
          },
          tooltip: (row) => {
            if (parseInt(row.isDraft) > 0) {
              return this.$t('declarations.detail.elster_message.submission_tooltips.draft');
            } else if (row.elsterSubmissionId !== null) {
              return this.$t('declarations.detail.elster_message.submission_tooltips.success');
            } else if (parseInt(row.submissionTries) >= 5 && row.elsterSubmissionId === null) {
              return this.$t('declarations.detail.elster_message.submission_tooltips.error');
            }

            return this.$t('declarations.detail.elster_message.submission_tooltips.pending');
          }
        },
        {
          field: 'protocol',
          header: this.$t('declarations.detail.elster_message.table_columns.protocol'),
          isChecked: true,
          sortable: false,
          multiIcons: true,
          getIcons: (row) => {
            let icons = [];

            if (row.documentUid && parseInt(row.documentUid) > 0) {
              icons = [
                {
                  src: require('@/assets/pdf.svg'),
                  clickable: true,
                  key: 'download'
                },
                {
                  src: require('@/assets/eye_green.svg'),
                  clickable: true,
                  key: 'view'
                }
              ]
            }

            if (parseInt(row.isDraft) > 0) {
              icons.push({
                src: require('@/assets/edit_green.svg'),
                clickable: true,
                key: 'edit_elster_message'
              });
            }

            if (Object.keys(row.details.attachments).length > 0) {
              icons.push({
                src: require('@/assets/clip_green.svg'),
                clickable: true,
                key: 'open_attachments'
              });
            }

            return icons;
          }
        }
      ],
      viewerBreadcrumbs: {},
      federalStateUid: '',
      powersOfAttorneyList: [],
      breadcrumbs: [
        {
          title: this.$t('declarations.detail.breadcrumbs.link1')
        }
      ],
      currentStage: '',
      steps: [
        {
          name: this.$t('declarations.detail.progress.step1'),
          isActive: false,
          isCompleted: false,
          status: ['draft']
        },
        {
          name: this.$t('declarations.detail.progress.step2'),
          isActive: false,
          isCompleted: false,
          status: ['missing_data']
        },
        {
          name: this.$t('declarations.detail.progress.step3'),
          isActive: false,
          isCompleted: false,
          status: ['internal_approval_requested']
        },
        {
          name: this.$t('declarations.detail.progress.step4'),
          isActive: false,
          isCompleted: false,
          status: ['external_approval_requested']
        },
        {
          name: this.$t('declarations.detail.progress.step5'),
          isActive: false,
          isCompleted: false,
          status: ['elster_submission', 'elster_completed']
        },
        {
          name: this.$t('declarations.detail.progress.step6'),
          isActive: false,
          isCompleted: false,
          status: ['assessment_received', 'dispute']
        },
        {
          name: this.$t('declarations.detail.progress.step7'),
          isActive: false,
          isCompleted: false,
          status: ['completed']
        }
      ],
      stepsBusiness: [
        {
          name: this.$t('declarations.detail.progress.step1'),
          isActive: false,
          isCompleted: false,
          status: ['draft']
        },
        {
          name: this.$t('declarations.detail.progress.step2'),
          isActive: false,
          isCompleted: false,
          status: ['internal_approval_requested']
        },
        {
          name: this.$t('declarations.detail.progress.step3'),
          isActive: false,
          isCompleted: false,
          status: ['elster_submission', 'elster_completed']
        },
        {
          name: this.$t('declarations.detail.progress.step4'),
          isActive: false,
          isCompleted: false,
          status: ['assessment_received', 'dispute']
        },
        {
          name: this.$t('declarations.detail.progress.step5'),
          isActive: false,
          isCompleted: false,
          status: ['completed']
        }
      ],
      categories: [
        {
          name: this.$t('declarations.detail.tabs.tab1'),
          slug: 'basic'
        }, {
          name: this.$t('declarations.detail.tabs.tab2'),
          slug: 'receiver'
        }, {
          name: this.$t('declarations.detail.tabs.tab3'),
          slug: 'tax-calculation'
        }, {
          name: this.$t('declarations.detail.tabs.tab6'),
          slug: 'elster-message'
        }, {
          name: this.$t('declarations.detail.tabs.tab7'),
          slug: 'deadline'
        },
        {
          name: this.$t('declarations.detail.tabs.tab4'),
          showTopLine: true,
          slug: 'document'
        }, {
          name: this.$t('declarations.detail.tabs.tab5'),
          showTopLine: true,
          slug: 'protocol'
        }
      ],
      cityList: [],
      zipSearch: false,
      errors: {
        recipient: {
          "salutation": {
            invalid: false
          },
          "title": {
            invalid: false
          },
          "firstName": {
            invalid: false
          },
          "phone": {
            invalid: false
          },
          "zip": {
            invalid: false
          },
          "city": {
            invalid: false
          }
        },
        participant: {
          "salutation": {
            invalid: false
          },
          "firstName": {
            invalid: false
          },
          "zip": {
            invalid: false
          },
          "city": {
            invalid: false
          }
        }
      },
      declarationNumberInvalid: false,
      userList: [],
      usersCanRelease: [],
      declaration_type_options: [],
      declaration_reference_date_options: [],
      differingAppellantOptions: [],
      differingPowerOfAttorneyOptions: [],
      showExtraDetails: false,
      recipient: {
        salutation: '1',
        title: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        postbox: '',
        city: '',
        authorizedReceiver: "0"
      },
      participantEditable: false,
      showParticipant: false,
      participant: {
        salutation: '1',
        title: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        postbox: '',
        city: ''
      },
      emailDialog: false,
      clientDataRequestEmailBody: '',
      sumOfAreas: 0,

      popoverTexts_participation: [
        this.$t('declarations.add_declaration.steps.step2.participation_popoverTexts')
      ],
      popoverLink_participation: "https://hilfe.grundsteuer-digital.de/faq/mitwirkung/",
      popoverTexts_object_value: [
        this.$t('declarations.object_value.calculation_explanation_1'),
        this.$t('declarations.object_value.calculation_explanation_2')
      ],
      popoverText_feeInvoiceSent: '',
      show: false,
      checked: false,
      Attorney: 'keine',
      currentCategory: 'basic',
      isModalVisible: false,
      isModalVisibleWarn: false,

      renderAddress: '-',
      renderEmail: '',
      renderMobile: '',
      renderPhone: '',
      renderInternalNoteClient: '',
      renderInternalNoteProperty: '',
      dataVerificationDialog: false,
      releaseWorkflowDialog: false,
      releaseEnabled: false,
      elsterAutoSubmissionDialog: false,
      selectedClientApprovalOption: '',
      elsterMessageId: 0,
      elsterMessage: {
        declarationId: 0,
        messageType: 'deadline_extension',
        isDraft: false,
        subject: '',
        differingReferenceNumber: '',
        differingAppellant: '',
        differingPowerOfAttorneyId: '',
        justification: '',
        differingReferenceDate: 0,
        extensionUntil: '2-Weeks',
        processingType: 'automated',
        actDate: '',
        actId: '',
        actDeadline: '',
        suspensionOfExecution: 'no',
        attachments: [],
        confirmed: false
      },
      elsterMessageValidations: {
        "subject": {
          "invalid": false,
        },
        "justification": {
          "invalid": false
        },
        "confirmation": {
          "invalid": false
        },
        "actDate": {
          "invalid": false
        },
        "actId": {
          "invalid": false
        },
        "actDeadline": {
          "invalid": false
        },
        "suspensionOfExecution": {
          "invalid": false
        },
        "attachments": [],
        "errors": [],
        "hints": []
      },
      elsterMessageValidated: false,
      currentElsterMessageResponseType: '',
      processing_type_tooltip: [
        this.$t('declarations.detail.elster_message.tooltip.processing_type.text_line1')
      ],
      assigneeForRelease: "0",
      elsterValidationMode: '',
      propertyData: {
        step2: {
          economicEntityType: ''
        }
      },
      elsterValidationResultDialog: false,
      elsterValidationPropertyUid: '',
      stateList: [],
      elsterValidationErrors: [],
      elsterValidationHints: [],
      showTaxCalculationError: false,
      showTextScanNotice: false,
      fetchingTaxCalculation: false,
      taxCalculationHtml: '',
      taxCalculationNavigationExcludedElements: [],
      showTaxAssessmentUploadDialog: false,
      taxDocuments: {
        taxCalculation: {
          fileSource: ''
        },
        taxAssessment: {
          fileSource: ''
        }
      },
      showCompareDocumentsDialog: false,
      showSavedElsterResponse: false,
      confirmedResubmission: false,
      calculatingPropertyValue: false,

      originalDeclarationNumber: '',
      synchronizedPropertyCount: 0,
      synchronizeDialog: false,

      referenceSynchronize: false,
      btnKey: '',
      clientApprovalMode: '',
      clientApprovalModes: {},
      clientApproverEmails: [],
      showApproverEmailChip: true,
      clientApproverEmailsStatus: [],
      approversCount: 0,
      approverEmail: '',
      sendClientPortalApprovalEmail: true,
      clientApproverEmailsError: false,
      originalExtraDetails: '',
      extraDetailsSynchronizeDialog: false,
      extraDetailsSynchronize: false,
      assignedUserSynchronizeDialog: false,
      assignedUserSynchronize: false,

      declarationNumberChanged: false,
      extraDetailsChanged: false,
      assignedUserChanged: false,

      multiSynchronizeDialog: false,
      referenceSyncChecked: false,
      extraDetailsSyncChecked: false,
      assignedUserSyncChecked: false,
      participationDialog: false,
      documentSwitchOptions: [],
      selectedDocumentSwitchOption: '',
      selectedTaxDocumentSwitchOption: "0",
      taxCalculationElsterValidationErrors: [],
      deadlineAddEditDialog: false,
      deadlineGridColumns: [
        {
          field: 'deadline',
          header: this.$t('declarations.detail.deadline.cols.deadline'),
          isChecked: true,
          clickable: true
        }, {
          field: 'documentType',
          header: this.$t('declarations.detail.deadline.cols.document_type'),
          isChecked: true,
          clickable: true
        }, {
          field: 'filename',
          header: this.$t('declarations.detail.deadline.cols.description'),
          isChecked: true,
          prependIcon: true,
          clickable: true,
          prependIconClickable: true,
          getIcon: (row) => {
            switch (row.fileExt) {
              case "xls,xlsx":
                return require('@/assets/document_xls.svg')
              case "doc,docx":
                return require('@/assets/document_doc.svg')
              case "pdf":
                return require('@/assets/document_pdf.svg')
              default:
                return require('@/assets/document_generic.svg')
            }
          }
        }, {
          field: 'statusTxt',
          header: this.$t('declarations.detail.deadline.cols.status'),
          isChecked: true,
          colElType: 'select',
          dataSource: this.$t('deadline.status'),
          model: 'status'
        }
      ],
      elsterMessageGridMenus: [
        {
          id: 'elsterMessage.add',
          icon: this.getAssetPath('message_icon.svg'),
          title: this.$t('declarations.detail.elster_message.new_elster_message')
        },
        {
          id: 'grid_reload',
          icon: this.getAssetPath('refresh.svg'),
          title: this.$t('general.grid.refresh')
        }
      ],
      deadlineGridMenus: [
        {
          id: "deadline.add",
          "icon": this.getAssetPath('circle_green.svg'),
          "title": this.$t('deadline.menus.add')
        },
        {
          id: 'deadline.edit',
          "icon": this.getAssetPath('edit_green.svg'),
          "icon_disabled": this.getAssetPath('edit_gray.svg'),
          "title": this.$t('deadline.menus.edit'),
          "slug": "edit",
          "disable_on_multi": true,
          "enable_on_select": true,
          "disabled": true
        },
        {
          id: "deadline.delete",
          "icon": this.getAssetPath('delete_user.svg'),
          "icon_disabled": this.getAssetPath('delete_user_gray.svg'),
          "title": this.$t('deadline.menus.delete'),
          "action_endpoint": 'deadline/delete',
          "action_confirmation": {
            show: true
          },
          "disable_on_multi": false,
          "enable_on_select": true,
          "disabled": true
        },
        {
          id: "grid_reload",
          "icon": this.getAssetPath('refresh.svg'),
          "title": this.$t('general.grid.refresh')
        }
      ],
      deadline: {
        "declarationId": 0,
        "deadlineId": 0,
        "document": '',
        "document_type": "",
        "document_date": "",
        "announcement_date": "",
        "deadline_reminder": "",
        "deadline": ""
      },
      deadlineErrors: {
        document: false,
        document_type: false,
        document_date: false
      },
      deadline_calculation: [
        {
          "expanded": false,
          "document_date": "",
          "announcement_date": "",
          "deadline_reminder": "",
          "deadline": ""
        },
        {
          "expanded": false,
          "document_date": "",
          "announcement_date": "",
          "deadline_reminder": "",
          "deadline": ""
        },
        {
          "expanded": true,
          "document_date": "",
          "announcement_date": "",
          "deadline_reminder": "",
          "deadline": ""
        }
      ],
      fetchingDeadlineDocuments: true,
      deadlineDocuments: [],
      deadlineGridDataLoaded: false,
      documentViewer: {
        show: false,
        documentName: '',
        fileSource: '',
        documentId: 0
      },
      considerExemption: false,
      originalAssignedUser: '',

      areaOfTheLand1: '',
      areaOfTheLandValue1: '',
      areaOfTheLand2: '',
      areaOfTheLandValue2: '',
      areaOfTheLand3: '',
      areaOfTheLandValue3: '',
      areaOfTheLand4: '',
      areaOfTheLandValue4: '',

      maxAreaOfLandValue1: 999999.99,
      areaOfTheLandDataModalShow: false,

      secondAreaInfoShow: false,
      thirdAreaInfoShow: false,
      fourthAreaInfoShow: false,

      assessmentRate: '',
      showChangeAssessmentRateModal: false,
      showElsterMessageAttachmentsModal: false,

      elsterMessageButtonText: this.$t('buttons.send'),
    }
  },
  validations: {
    declarationData: {
      recipient: {
        firstName: {
          required: requiredIf(function () {
            return this.declarationData.recipientType === 'others'
          })
        },
        zip: {
          required: requiredIf(function () {
            return this.declarationData.recipientType === 'others'
          })
        },
        city: {
          required: requiredIf(function () {
            return this.declarationData.recipientType === 'others'
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters("property", ["getCurrentTaxModel"]),
    ...mapGetters("record_observer", ["triggerRefreshRecord"]),
    canShowTaxCalculationSidebar() {
      return (
          typeof this.propertyData !== 'undefined'
          && parseInt(this.propertyData.step2.economicEntityType) === 3
          && this.taxCalculationHtml
      );
    },
    taxDocumentSwitchOptions() {
      let taxDocumentSwitchOptions = [];
      let lastIndex = 0;

      if (Array.isArray(this.declarationData.submissions)) {
        this.declarationData.submissions.map((submission, index) => {
          let optionName = '';

          if (typeof submission.elsterMessage !== 'undefined') {
            optionName = this.$t(
              'declarations.detail.tax_calculation_options.submitted',
              {date: submission.elsterMessage.elsterSubmissionReceivedDate}
            );
          } else if (typeof submission.freezedProperty !== 'undefined') {
            optionName = this.$t(
              'declarations.detail.tax_calculation_options.freezed',
              {date: submission.freezedProperty.createdAt}
            )
          }

          taxDocumentSwitchOptions.push({
            code: index.toString(),
            name: optionName
          });

          lastIndex = index;
        });

        lastIndex++;
      }

      taxDocumentSwitchOptions.push({
        'code': lastIndex.toString(10),
        'name': this.$t('declarations.detail.tax_calculation_options.actual')
      });

      return taxDocumentSwitchOptions;
    },
    disabledAfterElster() {
      return ['elster_completed', 'assessment_received', 'dispute', 'completed'].includes(this.declarationData.status);
    },
    isReadOnlyProperty() {
      return !(
        typeof this.getCurrentUser().permissions !== 'undefined'
        && typeof this.getCurrentUser().permissions.manage_property !== 'undefined'
        && parseInt(this.getCurrentUser().permissions.manage_property) === 1
      );
    },
    isReadOnly() {
      return this.declarationData.status && this.declarationData.status === 'archived';
    },
    recipientDisabled() {
      return this.declarationData.recipientType === 'tax_consultant' || this.declarationData.recipientType === 'power_of_attorney_from_client' || this.declarationData.recipientType === 'power_of_attorney_from_branch' || this.declarationData.status === 'archived';
    },
    hasPermissionForRelease() {
      return typeof this.getCurrentUser().permissions.release_declaration !== 'undefined' && this.getCurrentUser().permissions.release_declaration === '1'
        ? this.getCurrentUser().permissions.release_declaration
        : 0;
    },
    hasPermissionForElster() {
      return typeof this.getCurrentUser().permissions.release_declaration !== 'undefined' && this.getCurrentUser().permissions.elster_transaction === '1'
        ? this.getCurrentUser().permissions.elster_transaction
        : 0;
    },
    hasAccessToMassAppealFeature() {
      if (this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_appeal)) {
        return true;
      }

      return false;
    },
    getEmailDialogTitle() {
      if (this.declarationData.status) {
        return this.selectedClientApprovalOption === 'request_approval' || this.declarationData.status === 'external_approval_requested' ? this.$t('declarations.detail.buttons.request_client_approval.title') : this.$t('declarations.detail.buttons.request_data.title');
      }
      return '';
    },
    getPropertyType() {
      let propertyTypeDisabled = this.propertyService.isPropertyTypeDisabled(
          this.declarationData.property.economicEntityType,
          this.declarationData.property.federalStateUid
      );
      if (propertyTypeDisabled) {
        return '';
      }

      let selectedTaxModel = taxModels.filter(item => {
        if (item.stateCodes.includes(this.declarationData.property.federalStateUid)) {
          return item;
        }
      });

      if (typeof selectedTaxModel[0] !== 'undefined' && typeof selectedTaxModel[0].model !== 'undefined') {
        let propertyTypes = this.$t('properties.step3.property_type_options.' + selectedTaxModel[0].model);
        if (propertyTypes[this.declarationData.property.propertyType]) {
          return propertyTypes[this.declarationData.property.propertyType].name;
        }
      }

      return '';
    },
    federalStateCode() {
      return this.stateList.find(o => o.code === this.propertyData.step2.federalStateUid)?.state_code;
    },
    tax_calc_needs_community() {
      const state_models_with_land_value = ["3", "4", "5", "8", "10", "11", "13", "15", "16", "12", "14", "9", "7"]

      return state_models_with_land_value.includes(this.declarationData.property.federalStateUid)
    },
    showHideFarmRegulationsInTaxCalculationCheck() {
      const stateModelsFarmRegulationsInTaxCalculation = ['6', '9', '10', '15'];

      return stateModelsFarmRegulationsInTaxCalculation.includes(this.declarationData.property.federalStateUid)
        && (this.declarationData.property.economicEntityType === '3');
    },
    areaOfTheLandButtonShow() {
      return ['9', '7'].includes(this.declarationData.property.federalStateUid);
    },
    clientApprovalOptions() {
      if (this.declarationData.client) {
        if (this.declarationData.client.clientPortalAccess && this.declarationData.client.clientUserEmail) {
          return this.$t('declarations.detail.release.client_approval_options');
        }
      }

      return this.$t('declarations.detail.release.client_approval_options').filter((o) => {
        return o.code !== 'request_approval'
      })

    },
    getTaxModel() {
      let selectedTaxModel = taxModels.filter(item => {
        if (item.stateCodes.includes(this.declarationData.property.federalStateUid)) {
          return item;
        }
      });

      if (typeof selectedTaxModel[0] !== 'undefined' && typeof selectedTaxModel[0].model !== 'undefined') {
        return selectedTaxModel[0].model;
      }

      return '';
    },
    showBranchPowerOwAttorney() {
      return this.declarationData.branch.powerOfAttorney && parseInt(this.declarationData.branch.powerOfAttorney) > 0;
    },
    showClientPowerOwAttorney() {
      return this.declarationData.client.powerOfAttorney && parseInt(this.declarationData.client.powerOfAttorney) > 0;
    },
    getReleaseWorkflowDialogTitle() {
      if (this.declarationData.status === 'internal_approval_requested') {
        return this.$t('declarations.detail.release.title2');
      } else {
        return this.$t('declarations.detail.release.title');
      }
    },
    hasElsterErrors() {
      return this.elsterValidationErrors.length > 0;
    },
    hasElsterHints() {
      return this.elsterValidationHints.length > 0;
    },
    hasElsterMessageErrors() {
      return this.elsterMessageValidations.errors.length > 0;
    },
    hasElsterMessageHints() {
      return this.elsterMessageValidations.hints.length > 0;
    },
    isParticipationCheckable() {
      if (!this.$isBusiness) {
        return true;
      }

      if (this.isDeclarationParticipationChecked) {
        return true
      }

      return false;
    },
    isDeclarationParticipationChecked() {
      let checked = false;
      if (this.declarationData.participation === '1' || this.declarationData.participation === true) {
        checked = true;
      }

      return checked;
    },
    showParticipantLink() {
      if (this.$isBusiness) {
        return false;
      }

      if (this.isReadOnly && this.isDeclarationParticipationChecked) {
        return this.participant.firstName !== '';
      } else {
        return this.isDeclarationParticipationChecked;
      }
    },
    declarationInfo() {
      if (this.declarationData.status) {
        let submissionsCount = this.declarationData.submissions ? Object.keys(this.declarationData.submissions).length : 0;
        let submissionInfoStatus = ["elster_completed", "assessment_received", "dispute", "completed", "archived"];
        if (this.declarationData.status === 'internal_approval_requested') {
          return this.$t('declarations.detail.internal_approval_requested_info')
        } else if (submissionsCount > 0 && this.declarationData.elsterSubmissionTransferTicket && this.declarationData.elsterSubmissionReceivedDate) {
          return this.$t('declarations.detail.elster_submission_info', {
            "last": submissionsCount > 1 ? this.$t('declarations.detail.info_last') + ' ' : "",
            "elsterSubmissionReceivedDate": this.declarationData.elsterSubmissionReceivedDate,
            "elsterSubmissionTransferTicket": this.declarationData.elsterSubmissionTransferTicket
          })
        } else if (submissionInfoStatus.includes(this.declarationData.status) && this.declarationData.isDeclarationWithoutSubmission === '1') {
          return this.$t('declarations.detail.no_elster_submission_info');
        }
      }

      return '';
    },
    isDatevClient() {
      return this.declarationData && typeof this.declarationData.client.externalName !== 'undefined' && this.declarationData.client.externalName === 'datev';
    },
    disabledRequestApproval() {
      if (this.declarationData.status && this.declarationData.status === 'external_approval_requested' && this.approversCount === 0) {
        return this.clientApprovalMode === '';
      }
      return false;
    },
    saveMultiSyncBtnDisabled() {
      return !(this.referenceSyncChecked || this.extraDetailsSyncChecked || this.assignedUserSyncChecked)
    },
    elsterMessageListServerParams() {
      return {
        "declarationId": this.declarationData.prim_uid
      }
    },
    getCurrentNavIndex() {
      if (this.navRecords.length > 0 && parseInt(this.declarationUid) > 0) {
        let nevRecordIndex = this.navRecords.findIndex(o => parseInt(o) === parseInt(this.declarationUid));
        if (nevRecordIndex >= 0) {
          return nevRecordIndex + 1;
        }
      }

      return 0;
    },
    getElsterMessageTypeOptions() {
      let elsterMessageTypeOptions = this.$t('declarations.detail.elster_message.dialog_message_type');

      elsterMessageTypeOptions = elsterMessageTypeOptions.filter((option) => {
        if (
          ['appeal', 'subsequent'].includes(option.code)
          && ['elster_completed', 'assessment_received', 'dispute', 'completed'].includes(this.declarationData.status) === false
        ) {
          return false;
        }

        if (
          option.code === 'appeal'
          && typeof this.getCurrentUser().permissions !== 'undefined'
          && typeof this.getCurrentUser().permissions.send_dispute !== 'undefined'
          && parseInt(this.getCurrentUser().permissions.send_dispute) !== 1
        ) {
          return false;
        }

        return true;
      });

      return elsterMessageTypeOptions;
    },
    getElsterMessageSubjectMaxLimit() {
      let maxLimit = parseInt(this.$t('declarations.detail.elster_message.subject_limit'));
      if (isNaN(maxLimit)) {
        return 0;
      }

      return maxLimit;
    },
    getElsterMessageJustificationMaxLimit() {
      let maxLimit = parseInt(this.$t('declarations.detail.elster_message.justification_limits.' + this.elsterMessage.messageType));
      if (isNaN(maxLimit)) {
        return 0;
      }

      return maxLimit;
    },
    getElsterMessageJustificationHelp() {
      if (this.elsterMessage.messageType === 'deadline_extension') {
        return this.$t('declarations.detail.elster_message.deadline_justification_help')
      }

      if (this.elsterMessage.messageType === 'subsequent') {
        return this.$t('declarations.detail.elster_message.attachment_justification_help');
      }
    },
    getElsterMessageDifferingReferenceDateOptions() {
      const currentYear = new Date().getFullYear();

      let differingReferenceDateOptions = [{
        name: this.$t('declarations.detail.elster_message.differing_reference_date_default_option_name'),
        code: 0
      }];

      for (let year = 2022; year <= currentYear; year++) {
        differingReferenceDateOptions.push({
          name: year,
          code: year,
        });
      }

      return differingReferenceDateOptions;
    },
    getAdministrativeActOptions() {
      let administrativeActOptions = this.$t('declarations.detail.elster_message.administrative_act_options')

      administrativeActOptions = administrativeActOptions.filter((option) => {
        if (option.code === '271') {
          // Only include 271 when NOT BY or NI
          return (['2', '9'].includes(this.declarationData.property.federalStateUid) === false);
        } else if (option.code === '299') {
          // Only include 299 when BY or NI
          return (['2', '9'].includes(this.declarationData.property.federalStateUid));
        } else {
          return true;
        }
      });

      return administrativeActOptions;
    },
    getAdministrativeDeadlineOptions() {
      let deadlineOpts = [];
      let year = this.declarationData.property.federalStateUid === '7' ? 2022 : 2025;
      for (year; year <= 2050; year++) {
        deadlineOpts.push({
          'name': '01.01.' + year,
          'code': year
        });
      }

      return deadlineOpts;
    },
    deadlineModuleEnabled() {
      return this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_deadline_control);
    },
    getSumOfOfficialArea() {
      var sumOfOfficialArea = 0;
      var gemeindes = this.propertyData.step9.gemeindes ? this.$api.parse_object_data(this.propertyData.step9.gemeindes) : [];
      if (gemeindes.length > 0) {
        for (var i = 0; i < gemeindes.length; i++) {
          if (typeof gemeindes[i].parcels !== 'undefined') {
            var parcels = this.$api.parse_object_data(gemeindes[i].parcels);
            if (parcels.length > 0) {
              for (var j = 0; j < parcels.length; j++) {
                sumOfOfficialArea += parseInt(parcels[j].areaOfTheLand);
              }
            }
          }
        }
      }

      return (sumOfOfficialArea > 0) ? sumOfOfficialArea : '-';
    },
    getSumOfAreaOfUse() {
      var sumOfAreaOfUse = 0;
      var gemeindes = this.propertyData.step9.gemeindes ? this.$api.parse_object_data(this.propertyData.step9.gemeindes) : [];
      if (gemeindes.length > 0) {
        for (var i = 0; i < gemeindes.length; i++) {
          if (typeof gemeindes[i].parcels !== 'undefined') {
            var parcels = this.$api.parse_object_data(gemeindes[i].parcels);
            if (parcels.length > 0) {
              for (var j = 0; j < parcels.length; j++) {
                if (typeof parcels[j].usageTypes !== 'undefined') {
                  var usageTypes = this.$api.parse_object_data(parcels[j].usageTypes);
                  if (usageTypes.length > 0) {
                    for (var k = 0; k < usageTypes.length; k++) {
                      let usageAreaOfTheLand = parseInt(usageTypes[k].areaOfTheLand);
                      if (!isNaN(usageAreaOfTheLand)) {
                        sumOfAreaOfUse += usageAreaOfTheLand;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return (sumOfAreaOfUse > 0) ? sumOfAreaOfUse : '-';
    },
    poaCharacterLimitInfo() {
      return this.$t('declarations.add_declaration.steps.step3.new_power_of_attorney_character_restriction_info')
        .toString()
        .replace('%s', this.$t('declarations.add_declaration.steps.step3.character_restriction_info_by_federal_state')[this.declarationData.property.federalStateUid]);
    },
    otherButtonOptions() {
      return [
        {
          icon: this.getAssetPath('document_pdf.svg'),
          label: this.$t('declarations.detail.tab1.download').toString(),
          handler: 'downloadPropertyValuePdf',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('search-green.svg'),
          label: this.$t('declarations.detail.tab1.choose_community').toString(),
          handler: 'openCommunitySelection',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: this.getAssetPath('refresh_green.svg'),
          label: this.$t('declarations.detail.tab1.refresh_object_value').toString(),
          handler: 'calculatePropertyValue',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        },
        {
          icon: false,
          checkbox: true,
          checked: this.considerExemption,
          label: this.$t('declarations.detail.tab1.consider_exemption'),
          handler: 'considerExemptionInPropertyValueToggle',
          default: false,
          blackText: true,
          show: true,
          fontSize: '14',
        }
      ];
    },
    shouldShowIsDeclarationWithoutSubmissionModal() {
      if (this.declarationData.isDeclarationWithoutSubmission !== '1') {
        return false;
      }

      if (this.elsterMessage.isDraft) {
        return false;
      }

      if (this.declarationData.hasSubmittedElsterMessage === '1') {
        return false;
      }

      return true;
    },

    isOtherDeadlineDocumentTypeSelected() {
      return (this.deadline.document_type === 'other_document');
    },

    moreButtonOptions() {
      let buttonOpts = this.$t('declarations.detail.buttons');
      let moreButtonOptions = [];

      for (const index in buttonOpts) {
        if (buttonOpts.hasOwnProperty(index)) {
          if (index !== 'revise_declaration' && this.showWorkflowButton(index)) {
            moreButtonOptions.push({
              index: index,
              icon: this.getAssetPath(buttonOpts[index].icon2),
              label: buttonOpts[index].title,
              handler: 'declarationWorkflow',
              default: false,
              blackText: true,
              show: (index === 'elster_transfer' && parseInt(this.declarationData.elsterSubmissionApproved) === 1) ? false : true,
              fontSize: '14',
            });
          }
        }
      }

      return moreButtonOptions;
    },
    elsterValidationResultsList() {
      return [
        ...this.elsterValidationErrors,
        ...this.elsterValidationHints
      ];
    }
  },
  watch: {
    activeTab(val) {
      if (val !== 'tax-calculation') {
        this.$refs.taxCalculationSidebar.hide();
      }
    },
    declarationData: {
      handler(val) {
        this.setDeclarationDataChanged(JSON.stringify(val) !== this.initialDeclarationDataString);
      },
      deep: true
    },
    '$route'(to) {
      if (this.declarationData.prim_uid && this.declarationData.prim_uid !== to.query.uid) {
        this.renderTabs = false;
        this.startEditing();
      }
    },
    'elsterMessage.messageType'(newMessageType) {
      if (newMessageType === 'appeal') {
        this.elsterMessage.subject = this.declarationData.elsterMessageAppealSubject.replace('%EW-AZ%', this.declarationData.declarationNumber);
        this.elsterMessage.justification = this.declarationData.elsterMessageAppealContent;
      }
    },
    'elsterMessage.justification'(newMsg) {
      this.checkText(newMsg);
    },
    'elsterMessage.subject'(newSubject) {
      this.checkText(newSubject);
    },
    'elsterMessage.differingReferenceDate'(newReferenceDate, oldReferenceDate) {
      const mainDeclarationJustification = this.$t('declarations.detail.elster_message.justification_suggestions.deadline.main_declaration');
      const otherDeclarationJustification = this.$t('declarations.detail.elster_message.justification_suggestions.deadline.other_declaration');
      let otherDeclarationJustificationCheck = otherDeclarationJustification.replace('%year%', oldReferenceDate).trim();
      otherDeclarationJustificationCheck = otherDeclarationJustificationCheck.replace('%previous_year%', oldReferenceDate - 1).trim();

      if (
        this.elsterMessage.justification.length > 0
        && this.elsterMessage.justification.trim() !== mainDeclarationJustification.replace('%year%', oldReferenceDate).trim()
        && this.elsterMessage.justification.trim() !== otherDeclarationJustificationCheck
      ) {
        return;
      }

      if ([2022, 2029].includes(parseInt(newReferenceDate))) {
        this.elsterMessage.justification = mainDeclarationJustification.replace('%year%', newReferenceDate);
        return;
      }

      if (parseInt(newReferenceDate) > 2022) {
        this.elsterMessage.justification = otherDeclarationJustification.replace('%year%', newReferenceDate);
        this.elsterMessage.justification = this.elsterMessage.justification.replace('%previous_year%', newReferenceDate - 1);
        return;
      }
    },
  },
  mounted() {
    if (this.$route.params.nav_records && this.$route.params.nav_records.length > 0) {
      this.navRecords = this.$route.params.nav_records;
    }

    // Fetch list of holidays from api and cache
    this.$api.get_state_holidays();

    this.$api.get_state_list().then((list) => {
      this.stateList = list;
    });

    let powersOfAttorneyService = new PowersOfAttorneyService();
    powersOfAttorneyService.listAll().then((list) => {
      this.powersOfAttorneyList = list.map((o) => {
        return {
          "name": o.formattedName,
          "code": o.id,
          "detail": o
        }
      });
      this.differingPowerOfAttorneyOptions = this.powersOfAttorneyList;
    });

    if (this.getCurrentUser().settings.addison_ggw_enabled) {
      this.popoverText_feeInvoiceSent = this.$t('declarations.detail.tab1.fee_invoice_sent_addison');
    }
    if (this.getCurrentUser().settings.datev_ggw_enabled) {
      this.popoverText_feeInvoiceSent = this.$t('declarations.detail.tab1.fee_invoice_sent_datev');
    }
  },
  beforeDestroy() {
    this.$refs.taxCalculationSidebar.hide();
    this.stopObserver();
  },
  created() {
    this.startEditing();
  },
  methods: {
    ...mapMutations("declaration", ["setDeclarationDataChanged"]),
    ...mapGetters("user", ["getCurrentUser"]),
    ...mapActions("record_observer", ["startObserver", "stopObserver"]),
    ...mapMutations("grid", ["triggerReload"]),
    ...mapMutations("user", ["saveAccessLog"]),
    ...mapMutations("declaration", ["setDeclarationClientEmail"]),
    changeNavPage(currentPage) {
      if (currentPage && this.navRecords.length > 0 && this.navRecords[currentPage - 1]) {
        let newDeclarationUid = parseInt(this.navRecords[currentPage - 1]);

        if (newDeclarationUid > 0) {
          this.stopObserver();
          this.$router.push('/declaration/edit?uid=' + newDeclarationUid);
        }
      }
    },
    togglePropertyMenuOpen() {
      this.isPropertyValueMenuOpen = !this.isPropertyValueMenuOpen;
    },
    closePropertyMenu({srcElement}) {
      if (!srcElement || (this.isPropertyValueMenuOpen && !srcElement.classList.contains('propertyMenuOpener'))) {
        this.isPropertyValueMenuOpen = false
      }
    },
    startEditing() {
      this.calculatingPropertyValue = !this.$isBusiness;
      this.stopObserver();
      this.breadcrumbs = [
        {
          title: this.$t('declarations.detail.breadcrumbs.link1')
        }
      ];
      this.selectedClientApprovalOption = '';
      this.propertyValue = '';
      this.dataVerificationDialog = false;
      this.releaseWorkflowDialog = false;
      this.elsterValidationMode = '';
      this.documentSwitchOptions = [];
      this.selectedDocumentSwitchOption = '';
      this.declarationData = {
        recipient: {},
        branch: {},
        client: {},
        property: {},
        internalNote: '',
        extraDetails: '',
        participation: '',
        calculationCommunity: '',
        submissions: []
      };
      if (this.$isBusiness) {
        this.stepsBusiness = this.stepsBusiness.map((o) => {
          o.isActive = false;
          o.isCompleted = false;
          if (o.status.includes('elster_completed')) {
            o.name = this.$t('declarations.detail.progress.step3')
          } else if (o.status.includes('dispute')) {
            o.name = this.$t('declarations.detail.progress.step4');
          }
          return o;
        });
      } else {
        this.steps = this.steps.map((o) => {
          o.isActive = false;
          o.isCompleted = false;
          if (o.status.includes('elster_completed')) {
            o.name = this.$t('declarations.detail.progress.step5')
          } else if (o.status.includes('dispute')) {
            o.name = this.$t('declarations.detail.progress.step6');
          }
          return o;
        });
      }

      this.service.get(this.$route.query.uid).then(declarationData => {
        if (declarationData.prim_uid) {
          this.saveAccessLog({
            "recordType": "declaration",
            "recordUid": declarationData.prim_uid
          })
          this.startObserver({
            "recordType": "declarations",
            "recordId": declarationData.prim_uid,
            "recordTimestamp": declarationData.recordTimestamp,
            "declarationStatus": declarationData.status
          });
          this.declarationUid = parseInt(declarationData.prim_uid);
          let address = declarationData.client.address.street + ' ' + declarationData.client.address.houseNumber + ', ' + declarationData.client.address.zip + ' ' + declarationData.client.address.city
          this.renderAddress = address.trim() === ',' ? '-' : address;
          this.renderEmail = declarationData.client.address.email;
          this.renderMobile = declarationData.client.address.mobile;
          this.renderPhone = declarationData.client.address.phone;
          this.renderInternalNoteClient = declarationData.client.internalNoteClient;
          this.renderInternalNoteProperty = declarationData.property.internalNoteProperty;

          this.setDeclarationClientEmail(this.renderEmail);

          if (declarationData.assessmentDocTypes) {
            let assessmentDocumentTypes = this.$api.parse_object_data(declarationData.assessmentDocTypes);
            if (assessmentDocumentTypes.length > 0) {
              this.documentSwitchOptions = assessmentDocumentTypes;
              this.selectedDocumentSwitchOption = assessmentDocumentTypes[0].code;
            }
            if (assessmentDocumentTypes.length > 0) {
              this.selectedDocumentSwitchOption = assessmentDocumentTypes[0].code;
            }
          }

          // Set original declaration number
          this.originalDeclarationNumber = declarationData.declarationNumber;

          // Set original extra details
          this.originalExtraDetails = declarationData.extraDetails;

          // Set original assigned user
          this.originalAssignedUser = declarationData.assignedUser;

          let status_to_rename_steps = ['elster_completed', 'assessment_received', 'dispute', 'completed'];
          if (status_to_rename_steps.includes(declarationData.status)) {
            if (this.$isBusiness) {
              this.stepsBusiness = this.stepsBusiness.map((o) => {
                if (o.status.includes('elster_completed')) {
                  o.name = this.$t('declarations.stages.elster_completed')
                } else if (o.status.includes('dispute') && declarationData.status === 'dispute') {
                  o.name = this.$t('declarations.stages.dispute')
                }
                return o;
              });
            } else {
              this.steps = this.steps.map((o) => {
                if (o.status.includes('elster_completed')) {
                  o.name = this.$t('declarations.stages.elster_completed')
                } else if (o.status.includes('dispute') && declarationData.status === 'dispute') {
                  o.name = this.$t('declarations.stages.dispute')
                }
                return o;
              });
            }
          }

          if ((declarationData.property.street !== null && declarationData.property.street.trim() !== '') || (declarationData.property.houseNumber !== null && declarationData.property.houseNumber.trim() !== '') || (declarationData.property.zip !== null && declarationData.property.zip.trim() !== '') || (declarationData.property.city !== null && declarationData.property.city.trim() !== '')) {
            this.textAddressHtml = declarationData.property.street + ' ' + declarationData.property.houseNumber + '<br/>' + declarationData.property.zip + ' ' + declarationData.property.city;
            this.showGoogleLink = true;
          } else {
            this.textAddressHtml = declarationData.property.name;
            this.showGoogleLink = false;
          }

          this.PageHeaderTitle = declarationData.property.name;

          this.declarationData = {...this.declarationData, ...declarationData};

          if (this.declarationData.calculationCommunity) {
            this.tax_community = this.declarationData.calculationCommunity;
          }
          this.declarationData.prim_uid = parseInt(this.declarationData.prim_uid);
          this.showExtraDetails = this.declarationData.extraDetails !== '';
          this.areaOfTheLand1 = this.declarationData.property.areaOfTheLand1;
          this.areaOfTheLandValue1 = this.declarationData.property.areaOfTheLandValue1.replace('.', ',');

          this.selectReceiverType(this.declarationData.recipientType, true);

          if (typeof this.$route.params.showSavedElsterResponse !== 'undefined' && this.$route.params.showSavedElsterResponse) {
            this.showSavedElsterResponse = true;
            this.elsterValidate(false, false);
          }

          // fetch user list
          let userService = new UserService();
          userService.listUserByClient(this.declarationData.clientId).then((list) => {
            this.userList = list.reduce((filtered, item) => {
              if (item.roleKey === 'administrator' || item.roleKey === 'user') {
                filtered.push({
                  "name": item.name,
                  "code": item.id
                });
              }

              return filtered;
            }, []);

            this.usersCanRelease = list.filter((o) => {
              return o.permissions.release_declaration && o.permissions.release_declaration === "1";
            }).map((item) => {
              return {
                "name": item.name,
                "code": item.id
              };
            });
            if (this.usersCanRelease.length > 1) {
              this.usersCanRelease = [...[{
                "code": '0',
                "name": this.$t('general.please_select')
              }], ...this.usersCanRelease]
            }

            if (this.declarationData.status === 'internal_approval_requested') {
              this.assigneeForRelease = "0"
            } else if (this.hasPermissionForRelease) {
              this.assigneeForRelease = this.getCurrentUser().id;
            }
          });

          if (this.$isBusiness) {
            this.stepsBusiness.some(function (item, index) {
              if (this.stepsBusiness[index].status.includes(this.declarationData.status)) {
                this.stepsBusiness[index].isActive = true;
                this.currentStage = this.declarationData.status
                this.breadcrumbs.push({title: this.stepsBusiness[index].name})
                return true;
              }
              this.stepsBusiness[index].isActive = false;
              this.stepsBusiness[index].isCompleted = true;
            }, this);
          } else {
            this.steps.some(function (item, index) {
              if (this.steps[index].status.includes(this.declarationData.status)) {
                this.steps[index].isActive = true;
                this.currentStage = this.declarationData.status
                this.breadcrumbs.push({title: this.steps[index].name})
                return true;
              }
              this.steps[index].isActive = false;
              this.steps[index].isCompleted = true;
            }, this);

            this.considerExemption = declarationData['considerExemption'] == 1;
            this.calculatePropertyValue(true);
          }

          this.viewerBreadcrumbs = [
            {
              title: this.$t('dashboard.top_header.properties'),
              link: '/declaration/declarations'
            }, {
              title: this.declarationData.declarationNumber,
              link: '/declaration/edit/?uid=' + this.declarationData.prim_uid
            }, {
              title: this.$t('clients.edit.tabs.document'),
              backButtonHandler: true,
              handler: () => {
                this.$router.push({
                  name: "Declaration",
                  params: {
                    'comp': 'edit',
                    'selectedTab': 'document'
                  },
                  query: {
                    'uid': this.declarationData.prim_uid
                  }
                });
              }
            }
          ];

          let propertyId = declarationData.propertyPrimUid;
          if (propertyId) {
            this.propertyService.get(propertyId, null, false).then((data) => {
              if (data.id) {
                this.federalStateUid = data.step2.federalStateUid;
                this.propertyData = data;

                this.sumOfAreas = this.calculateSumOfAreasTotal(data.step3.parcels, data.step3.areaOfTheLandTotal, data.step3.areaOfTheLand1, data.step3.areaOfTheLand2, data.step2.federalStateUid);
                this.initializeSelect();

                this.synchronizedPropertyCount = 1;

                // differingAppellantOptions
                this.differingAppellantOptions.push({
                  'name': this.propertyData.clientName,
                  'code': 'client|' + this.propertyData.clientUid
                });

                if (this.propertyData.step4 && this.propertyData.step4.name && Object.keys(this.propertyData.step4.name).length) {
                  this.differingAppellantOptions.push({
                    'name': this.propertyData.step4.name,
                    'code': 'ownership|' + this.propertyData.step4.primUid
                  });
                }

                if (this.propertyData.stepOwner && Object.keys(this.propertyData.stepOwner).length > 0) {
                  Object.entries(this.propertyData.stepOwner).forEach(([index, item]) => {
                    let name = item.firstName + ' ' + item.lastName;
                    this.differingAppellantOptions.push({
                      'name': name.trim(),
                      'code': 'owner|' + item.primUid
                    });
                  });
                }
              }
            });
          }

          if (this.declarationData.personpoolClientUid) {
            let getData = {
              parentType: 'client',
              parentId: this.declarationData.personpoolClientUid,
            }

            this.clientService.getPersonData(getData, true).then((list) => {
              if (list.length > 0) {
                list.map((o) => {
                  this.differingAppellantOptions.push({
                    'name': o.name.trim(),
                    'code': 'personpool|' + o.code
                  });
                });
              }
            });
          }

          let declaration_reference_date_options = [];
          for (var i = 2022; i <= 2050; i++) {
            declaration_reference_date_options.push({
              'name': i,
              'code': i
            });
          }
          this.declaration_reference_date_options = declaration_reference_date_options;

          if (this.$route.params.selectedTab) {
            this.activeTab = this.$route.params.selectedTab;
          }

          if (this.declarationData.elsterMessageAppealSubject === '') {
            this.declarationData.elsterMessageAppealSubject = this.$t('settings.taxCategories.elster_message_appeal_subject_text');
          }

          if (this.declarationData.elsterMessageAppealContent === '') {
            this.declarationData.elsterMessageAppealContent = this.$t('settings.taxCategories.elster_message_appeal_content_text');
          }

          this.declarationData.powerOfAttorney = this.declarationData.powerOfAttorney.toString();

          this.declarationData.referenceDate = parseInt(this.declarationData.referenceDate);

          this.assessmentRate = this.declarationData.assessmentRate == null ? '' : this.declarationData.assessmentRate.toString();

          // Get declaration data string
          let declarationDataLocalCopy = _.clone(this.declarationData);
          this.initialDeclarationDataString = JSON.stringify(declarationDataLocalCopy);

          this.prepareParticipantData();
          this.activateTab(this.activeTab)
          this.renderTabs = true;

          if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect === 'attribution_perpetuation') {
            this.activeTab = 'elster-message';
            this.openElsterMessageDlg('other');

            setTimeout(() => {
              this.elsterMessage.subject = this.$t(
                'declarations.detail.elster_message.attribution_perpetuation_subject',
                {reference: this.declarationData.declarationNumber}
              );
              this.elsterMessage.justification = this.$t('declarations.detail.elster_message.attribution_perpetuation_justification');
            }, 300);
          }
        } else {
          this.goBack();
        }
      });
    },
    initializeSelect() {
      if (this.federalStateUid == '6' || this.federalStateUid == '2' || this.federalStateUid == '9') {
        //types_bayern
        this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_bayern');
      } else if (this.federalStateUid == '1') {
        //types_baden
        this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_baden');
      } else if (this.federalStateUid == '7') {
        //types_hessen
        this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_hessen');
      } else {
        this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_all');
      }

      this.declaration_type_options =  this.declaration_type_options.filter(
        (value) => {
          let ignoredOptions = ['1001', '1002'];

          if (
            ignoredOptions.includes(this.declarationData.type)
            || ignoredOptions.includes(value.code)
          ) {
            return false;
          }

          return true;
        }
      );
    },
    getGoogleMapsLink() {
      return 'https://www.google.com/maps/search/?api=1&query=' + this.declarationData.property.street + ' ' + this.declarationData.property.houseNumber + ' ' + this.declarationData.property.city;
    },
    activateTab(tabKey) {
      this.activeTab = tabKey;

      if (tabKey === 'receiver') {
        this.selectReceiverType(this.declarationData.recipientType, true);
      } else if (tabKey === 'tax-calculation') {
        this.fetchTaxCalculation();
      }
    },
    showWorkflowButton(button_key) {
      if (button_key === 'client_approval_available') {
        return false;
      }

      if (this.declarationData.status === 'draft' || this.declarationData.status === 'missing_data') {
        return (button_key === 'request_data' && !this.$isBusiness) || button_key === 'data_completed' || button_key === 'save_draft';
      }

      if (this.declarationData.status === 'internal_approval_requested') {
        return button_key === 'start_approval' || button_key === 'save_draft' || button_key === 'revise_declaration';
      }

      if (this.declarationData.status === 'external_approval_requested') {
        return button_key === 'client_approval_available' || button_key === 'request_client_approval' || button_key === 'save' || button_key === 'revise_declaration';
      }

      if (this.declarationData.status === 'elster_submission') {
        if (button_key === 'skip_elster' && this.declarationData.elsterTransferredAt) {
          return true;
        }

        return button_key === 'revise_declaration' || button_key === 'elster_transfer' || button_key === 'save';
      }

      if (this.declarationData.status === 'elster_completed') {
        if (this.declarationData.isDeclarationWithoutSubmission === '1') {
          return button_key === 'received_notice' || button_key === 'save';
        }

        return button_key === 'revise_declaration' || button_key === 'received_notice' || button_key === 'save';
      }

      if (this.declarationData.status === 'assessment_received') {
        return button_key === 'revise_declaration' || button_key === 'review' || button_key === 'save';
      }

      if (this.declarationData.status === 'dispute') {
        return button_key === 'revise_declaration'
            || (
                button_key === 'send_dispute'
                && typeof this.getCurrentUser().permissions !== 'undefined'
                && typeof this.getCurrentUser().permissions.send_dispute !== 'undefined'
                && parseInt(this.getCurrentUser().permissions.send_dispute) === 1
            )
            || button_key === 'dispute_resolved'
            || (
                button_key === 'dispute_upload'
                && this.declarationData.elsterMessageAppealsCount > 0
            )
            || button_key === 'save';
      }

      if (this.declarationData.status === 'completed') {
        return button_key === 'archive' || button_key === 'save' || button_key === 'revise_declaration';
      }

      if (this.declarationData.status === 'archived') {
        return button_key === 'unarchive';
      }

      return false;
    },
    getWorkflowBtnCls(button_key) {
      return button_key === 'elster_transfer' ? 'secondary' : '';
    },
    getWorkflowBtnSubText(button_key) {
      if (button_key === 'request_data' && this.declarationData.clientDataRequestedAt) {
        return '(' + this.$t('declarations.detail.data_requested_at') + ' ' + this.declarationData.clientDataRequestedAt + ')';
      } else if (button_key === 'request_client_approval' && this.declarationData.clientApprovalRequestedAt) {
        return '(' + this.$t('declarations.detail.data_requested_at') + ' ' + this.declarationData.clientApprovalRequestedAt + ')';
      }

      return '';
    },
    editClient() {
      if (this.declarationData.freezedClientId) {
        this.$router.push('/client/edit?uid=' + this.declarationData.freezedClientId)
      } else if (this.declarationData.clientId) {
        this.$router.push('/client/edit?uid=' + this.declarationData.clientId)
      }
    },
    editProperty(useFrozen = true) {
      if (this.declarationData.freezedPropertyId && useFrozen) {
        this.$router.push('/property/edit?uid=' + this.declarationData.freezedPropertyId)
      } else if (this.declarationData.propertyId) {
        this.$router.push('/property/edit?uid=' + this.declarationData.propertyId)
      }
    },
    async saveDeclaration(callback) {
      if (this.declarationData.status === 'archived') {
        return;
      }

      if (this.declarationData.recipientType === 'others') {
        this.declarationData.recipient = this.recipient;
      }
      this.errors.recipient = this.resetErrors(this.errors.recipient);

      if (this.declarationData.recipientType === 'others') {
        if ((this.declarationData.recipient.salutation === "0" || this.declarationData.recipient.salutation === "")) {
          this.errors.recipient.salutation.invalid = true;
          return;
        }

        if ((this.declarationData.recipient.street === '' || this.declarationData.recipient.houseNumber === '') && this.declarationData.recipient.postbox === '') {
          this.$api.showToast(this.$t('declarations.street_pobox_error'), "error")
          return false;
        }
      }

      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        for (let x in this.$v.declarationData.recipient) {
          if (this.$v.declarationData.recipient[x].$error) {
            this.errors.recipient[x].invalid = true;
          }
        }
        return;
      }

      if (this.declarationData.declarationNumber.length > 30) {
        this.declarationNumberInvalid = true;
        return;
      } else {
        this.declarationNumberInvalid = false;
      }

      if (this.declarationData.internalNote.length > 9999 || this.declarationData.extraDetails.length > 999) {
        return;
      }

      this.declarationData.referenceSynchronize = this.referenceSynchronize;
      this.declarationData.extraDetailsSynchronize = this.extraDetailsSynchronize;
      this.declarationData.assignedUserSynchronize = this.assignedUserSynchronize;

      this.stopObserver();
      let res = await this.service.save(this.declarationData);

      if (res) {

        this.referenceSynchronize = false;
        this.extraDetailsSynchronize = false;
        this.assignedUserSynchronize = false;
        this.btnKey = '';

        // set original declaration number with current declaration number
        this.originalDeclarationNumber = this.declarationData.declarationNumber;

        // set original declaration number with current declaration number
        this.originalExtraDetails = this.declarationData.extraDetails;

        // set original assigned user with current assigned user
        this.originalAssignedUser = this.declarationData.assignedUser;

        this.startObserver({
          "recordType": "declarations",
          "recordId": this.declarationData.prim_uid,
          "recordTimestamp": res.recordTimestamp
        });
        if (typeof callback === 'function') {
          callback();
        } else {
          this.$api.showToast(this.$t('general.data_saved'));
        }

        if (!this.declarationData.participation) {
          this.declarationData.participant = {};
        }

        let declarationDataLocalCopy = _.clone(this.declarationData);
        this.initialDeclarationDataString = JSON.stringify(declarationDataLocalCopy);

        this.setDeclarationDataChanged(false);
      }
    },
    declarationWorkflow(button_key) {
      this.btnKey = button_key;
      this.checkSynchronize();
      if (!this.synchronizeDialog && !this.extraDetailsSynchronizeDialog && !this.assignedUserSynchronizeDialog && !this.multiSynchronizeDialog) {
        this.saveDeclarationHandler(button_key);
      }
    },
    saveDeclarationHandler(button_key) {
      if (button_key === 'save_draft') {
        this.saveDeclaration();
      } else if (button_key === 'request_data') {
        this.selectedClientApprovalOption = '';
        if (this.declarationData.client.clientPortalAccess && this.declarationData.client.clientUserEmail) {
          this.$api.getEmailTemplate('property_data_request', true).then((content) => {
            this.clientDataRequestEmailBody = content
                .replace('%sender_name%', this.getCurrentUser().name)
                .replace('%property_name%', this.declarationData.property.name)
                .replace(/<br>/g, "");
            this.emailDialog = true
            this.clientApprovalMode = '';
            this.clientApproverEmails = [];
          });
        } else {
          this.clientPortalAccessNotActive();
        }
      } else if (button_key === 'data_completed') {
        if (this.usersCanRelease.length === 0 && !this.hasPermissionForRelease) {
          this.$api.showToast(this.$t("declarations.detail.release.no_user_found"), "error");
        } else {
          this.elsterValidationMode = 'data_completed';
          this.saveDeclaration(() => this.elsterValidate());
        }
      } else if (button_key === 'start_approval') {
        this.releaseWorkflowDialog = true;
        if (this.$refs.userSelection) {
          this.$refs.userSelection.clearSelection();
        }
        this.releaseEnabled = false;
      } else if (button_key === 'client_approval_available') {
        if (this.declarationData.status === 'external_approval_requested') {
          this.service.updateStage({
            'prim_uid': this.declarationData.prim_uid,
            'stage': 'elster_submission',
            'clientApprovalMode': 'approval_available'
          }).then((res) => {
            if (res) {
              this.$api.showToast(this.$t('general.data_saved'));
              this.startEditing();
            }
          });
        } else {
          this.$modal.show({
            headerText: this.$t('declarations.detail.buttons.client_approval_available.title'),
            text: this.$t('declarations.detail.buttons.client_approval_available.confirmation'),
            showClose: true,
            canEscape: true,
            buttons: {
              "ok": this.$t('declarations.detail.buttons.client_approval_available.confirmation_buttons.ok'),
              "cancel": this.$t('declarations.detail.buttons.client_approval_available.confirmation_buttons.cancel'),
              "delete": false
            },
            onConfirm: () => {
              this.service.updateStage({
                'prim_uid': this.declarationData.prim_uid,
                'stage': 'elster_submission'
              }).then((res) => {
                if (res) {
                  this.$api.showToast(this.$t('general.data_saved'));
                  this.startEditing();
                }
              })
            }
          });
        }
      } else if (button_key === 'request_client_approval') {
        this.elsterValidationMode = 'request_client_approval';
        this.saveDeclaration(() => {
          this.elsterValidate(true, false, true, true).then(() => {
            if (this.elsterValidationErrors.length === 0) {
              this.clientApprovalMode = '';
              this.clientApproverEmails = [];
              this.clientApproverEmailsStatus = [];
              this.approversCount = 0;
              this.clientDataRequestEmailBody = '';

              this.prepareClientApprovalModes();

              this.service.approversList(this.declarationData.prim_uid).then((resp) => {
                if (resp.data.success && resp.data.approversCount > 0) {
                  if (resp.data.approversCount === 1 && resp.data.approvers.client_portal) {
                    this.clientApprovalMode = 'client_portal';
                  } else {
                    this.clientApproverEmailsStatus = resp.data.approvers;
                    this.clientApprovalMode = 'multi_emails';
                  }
                  this.approversCount = resp.data.approversCount;
                }
                this.emailDialog = true;
              });
            }
          })
        });
      } else if (button_key === 'skip_elster') {
        this.service.updateStage({
          'prim_uid': this.declarationData.prim_uid,
          'stage': 'skip_elster'
        }).then((res) => {
          if (res) {
            this.$api.showToast(this.$t('general.data_saved'));
            this.startEditing();
          }
        })
      } else if (button_key === 'revise_declaration') {
        if (this.declarationData.status === 'elster_completed' && !this.confirmedResubmission) {
          this.$modal.show({
            headerText: this.$t('declarations.title'),
            textHtml: this.$t('declarations.detail.resubmission_warning'),
            showClose: true,
            canEscape: true,
            showCancel: false,
            buttons: {
              "ok": this.$t('buttons.ok'),
              "delete": false
            },
            onConfirm: () => {
              this.confirmedResubmission = true;
              this.$nextTick(() => this.declarationWorkflow(button_key));
            }
          });
          return;
        }
        this.confirmedResubmission = false;

        this.$modal.show({
          headerText: this.$t('declarations.detail.buttons.revise_declaration.title'),
          text: this.$t('declarations.detail.buttons.revise_declaration.confirmation'),
          showClose: true,
          canEscape: true,
          buttons: {
            "ok": this.$t('buttons.save'),
            "cancel": this.$t('buttons.cancel'),
            "delete": false
          },
          onConfirm: () => {
            this.service.updateStage({
              'prim_uid': this.declarationData.prim_uid,
              'stage': 'revise_declaration'
            }).then((res) => {
              if (res) {
                this.$api.showToast(this.$t('general.data_saved'));
                this.startEditing();
              }
            })
          }
        });
      } else if (button_key === 'elster_transfer') {
        if (this.hasPermissionForElster) {
          if (this.getCurrentUser().account_pay_type === 'paid') {
            this.elsterAutoSubmissionDialog = true;
          } else {
            this.$modal.show({
              headerText: this.$t('declarations.subscription_error.title'),
              text: this.$t('declarations.subscription_error.info'),
              showClose: true,
              canEscape: true,
              buttons: {
                "ok": this.$t('buttons.ok'),
                "cancel": this.$t('buttons.cancel'),
                "delete": false
              },
              onConfirm: () => {
                this.$router.push('/setting/billing')
              }
            });
          }
        } else {
          this.$api.showToast(this.$t('declarations.detail.elster_auto_submission.permission_error'), "error");
        }
      } else if (button_key === 'start_approval') {
        this.releaseWorkflowDialog = true;
      } else if (button_key === 'elster_validate') {
        this.elsterValidationMode = '';
        this.saveDeclaration(() => this.elsterValidate(true, false, false, true));
      } else if (button_key === 'received_notice') {
        this.$refs.uploadTaxAssessment.clear();
        this.deadline_calculation = [
          {
            "expanded": false,
            "document_date": "",
            "announcement_date": "",
            "deadline_reminder": "",
            "deadline": ""
          }, {
            "expanded": false,
            "document_date": "",
            "announcement_date": "",
            "deadline_reminder": "",
            "deadline": ""
          }, {
            "expanded": false,
            "document_date": "",
            "announcement_date": "",
            "deadline_reminder": "",
            "deadline": ""
          }
        ]
        this.showTaxAssessmentUploadDialog = true;
      } else if (button_key === 'send_dispute') {
        this.activeTab = 'elster-message';
        this.openElsterMessageDlg('appeal');
      } else if (button_key === 'dispute_resolved') {
        this.service.updateStage({
          'prim_uid': this.declarationData.prim_uid,
          'stage': 'completed'
        }).then((res) => {
          if (res) {
            this.$api.showToast(this.$t('general.data_saved'));
            this.startEditing();
          }
        })
      } else if (button_key === 'review') {
        this.loadAssessmentDocument();
      } else if (button_key === 'archive') {
        let data = [];
        data.records = [];
        data.records.push({
          prim_uid: this.declarationData.prim_uid
        });
        this.service.archive(data).then((res) => {
          if (res) {
            this.startEditing();
          }
        })
      } else if (button_key === 'unarchive') {
        let data = [];
        data.records = [];
        data.records.push({
          prim_uid: this.declarationData.prim_uid
        });
        this.service.unarchive(data).then((res) => {
          if (res) {
            this.startEditing();
          }
        })
      } else if (button_key === 'dispute_upload') {
        this.$modal.show({
          headerText: this.$t('declarations.dispute_upload_ask.title'),
          textHtml: this.$t('declarations.dispute_upload_ask.info').replace('@/assets/info_gray.svg', require('@/assets/info_gray.svg')),
          showClose: true,
          canEscape: true,
          buttons: {
            ok: this.$t('buttons.next'),
            cancel: this.$t('buttons.cancel'),
            delete: false
          },
          onConfirm: () => {
            this.confirmedResubmission = true;
            this.service.updateStage({
              prim_uid: this.declarationData.prim_uid,
              stage: 'revise_declaration'
            }).then((res) => {
              if (res) {
                this.$api.showToast(this.$t('general.data_saved'));
                this.startEditing();
                this.showTaxAssessmentUploadDialog = true;
              }
            });
          }
        });
      }
    },
    loadAssessmentDocument() {
      this.taxDocuments = {
        taxCalculation: {
          fileSource: ''
        },
        taxAssessment: {
          fileSource: ''
        }
      };
      this.$loading.show();
      let promises = [];
      promises.push(
        this.service.getTaxDocument(
          this.declarationData.prim_uid,
          this.selectedDocumentSwitchOption ? this.selectedDocumentSwitchOption : 'tax_assessment'
        )
      );
      const submissionIndex = parseInt(this.selectedTaxDocumentSwitchOption);

      let documentType = (submissionIndex < this.taxDocumentSwitchOptions.length - 1)
          ? 'tax_calculation'
          : 'tax_calculation_modified';

      let freezedPropertyId = 0;
      let taxCalculationDocumentId = 0;
      let elsterSubmissionReceivedDate = '';

      if (documentType === 'tax_calculation') {
        if (typeof (this.declarationData.submissions[submissionIndex].freezedProperty) !== 'undefined') {
          freezedPropertyId = this.declarationData.submissions[submissionIndex].freezedProperty.id;
        }

        if (typeof (this.declarationData.submissions[submissionIndex].taxCalculationDocument) !== 'undefined') {
          taxCalculationDocumentId = this.declarationData.submissions[submissionIndex].taxCalculationDocument.id;
        }

        if (typeof (this.declarationData.submissions[submissionIndex].elsterMessage) !== 'undefined') {
          elsterSubmissionReceivedDate = this.declarationData.submissions[submissionIndex].elsterMessage.elsterSubmissionReceivedDate;
        }
      }

      promises.push(
        this.service.getTaxDocument(
          this.declarationData.prim_uid,
          documentType,
          false,
          freezedPropertyId,
          taxCalculationDocumentId,
          elsterSubmissionReceivedDate
        )
      );

      Promise.all(promises).then((documents) => {
        for (let document of documents) {
          if (document.prim_uid || document.content) {
            if (
                document.document_type === 'tax_assessment'
                || document.document_type === 'tax_assessment_2'
                || document.document_type === 'tax_assessment_3'
                || document.document_type === 'tax_assessment_4'
            ) {
              this.documentDownloadedAssessment = true;
              this.taxDocuments.taxAssessment.fileSource = "data:" + document.mime_type + ";base64," + document.content;
            } else {
              this.documentDownloadedCalculation = true;
              this.taxDocuments.taxCalculation.fileSource = "data:" + document.mime_type + ";base64," + document.content;
            }
          } else if (document.document_type === 'tax_assessment' || document.document_type === 'tax_assessment_2') {
            this.taxDocuments.taxAssessment.fileSource = '';
            this.documentDownloadedAssessment = false;
          } else {
            this.taxDocuments.taxCalculation.fileSource = '';
            this.documentDownloadedCalculation = false;
          }
        }

        this.showCompareDocumentsDialog = true;

        this.$loading.hide();
      });
    },
    documentTypeChanged(e) {
      if (this.showCompareDocumentsDialog && this.selectedDocumentSwitchOption !== e.code) {
        this.$loading.show();
        this.selectedDocumentSwitchOption = e.code;
        this.service.getTaxDocument(this.declarationData.prim_uid, e.code).then((document) => {
          this.$loading.hide();
          this.taxDocuments.taxAssessment.fileSource = '';
          if (document.prim_uid) {
            this.taxDocuments.taxAssessment.fileSource = "data:" + document.mimeType + ";base64," + document.content;
          }
        })
      }
    },
    taxDocumentTypeChanged(e) {
      if (this.selectedDocumentSwitchOption === e.code) {
        return;
      }

      this.$loading.show();
      this.selectedTaxDocumentSwitchOption = e.code;

      const submissionIndex = parseInt(e.code);

      const type = (submissionIndex < this.taxDocumentSwitchOptions.length - 1)
          ? 'tax_calculation'
          : 'tax_calculation_modified';

      let freezedPropertyId = 0;
      let taxCalculationDocumentId = 0;
      let elsterSubmissionReceivedDate = '';

      if (type === 'tax_calculation') {
        if (typeof (this.declarationData.submissions[submissionIndex].freezedProperty) !== 'undefined') {
          freezedPropertyId = this.declarationData.submissions[submissionIndex].freezedProperty.id;
        }

        if (typeof (this.declarationData.submissions[submissionIndex].taxCalculationDocument) !== 'undefined') {
          taxCalculationDocumentId = this.declarationData.submissions[submissionIndex].taxCalculationDocument.id;
        }

        if (typeof (this.declarationData.submissions[submissionIndex].elsterMessage) !== 'undefined') {
          elsterSubmissionReceivedDate = this.declarationData.submissions[submissionIndex].elsterMessage.elsterSubmissionReceivedDate;
        }
      }

      this.service.getTaxDocument(
        this.declarationData.prim_uid,
        type,
        false,
        freezedPropertyId,
        taxCalculationDocumentId,
        elsterSubmissionReceivedDate
      ).then((document) => {
        if (document.content) {
          this.taxDocuments.taxCalculation.fileSource = `data:application/pdf;base64,${document.content}`;
        } else {
          this.taxDocuments.taxCalculation.fileSource = '';
        }
      }).finally(() => {
        this.$loading.hide();
      });
    },
    clientPortalAccessNotActive() {
      this.$modal.show({
        headerText: this.$t('declarations.title'),
        text: this.$t('declarations.detail.client_portal_not_active'),
        showClose: true,
        canEscape: true,
        buttons: {
          "ok": this.$t('declarations.detail.activate_client_portal'),
          "cancel": this.$t('buttons.cancel'),
          "delete": false
        },
        onConfirm: () => {
          this.$router.push({
            name: "client",
            params: {
              'comp': 'edit',
              'selectedTab': 'mandantenportal'
            },
            query: {
              "uid": this.declarationData.client.id
            }
          })
        }
      });
    },
    closeElsterAutoSubmissionDialog() {
      this.elsterAutoSubmissionDialog = false;
    },
    enableElsterAutoSubmission() {
      this.closeElsterAutoSubmissionDialog();

      this.saveDeclaration(() => {
        this.elsterValidate(true, false, true).then(() => {
          if (this.elsterValidationErrors.length === 0) {
            this.service.updateStage({
              'prim_uid': this.declarationData.prim_uid,
              'stage': 'enable_elster_auto_submission'
            }).then((res) => {
              if (res) {
                this.startEditing();
              }
            })
          }
        })
      });
    },
    deactivateElsterAutoSubmission() {
      this.service.updateStage({
        'prim_uid': this.declarationData.prim_uid,
        'stage': 'disable_elster_auto_submission'
      }).then((res) => {
        if (res) {
          this.startEditing();
        }
      })
    },
    cancelRelease() {
      this.dataVerificationDialog = false;
    },
    startRelease() {
      this.elsterValidationResultDialog = false;
      this.dataVerificationDialog = false;
      this.releaseWorkflowDialog = true;
      if (this.$refs.userSelection) {
        this.$refs.userSelection.clearSelection();
      }
      this.releaseEnabled = false;
      if (this.assigneeForRelease !== '0' && this.assigneeForRelease !== '' && this.usersCanRelease.length > 0) {
        this.releaseEnabled = this.declarationData.status === 'internal_approval_requested' || this.$isBusiness;
      }
    },
    handleReleaseDialogClose() {
      this.releaseWorkflowDialog = false;
    },
    enableReleaseFlow(checked) {
      this.releaseEnabled = checked;
    },
    selectedAssigneeForRelease(item) {
      this.releaseEnabled = item.code && (item.code !== '' && item.code !== '0');
    },
    releaseDeclaration(mode) {
      if (this.selectedClientApprovalOption === 'request_approval') {
        // Request client approval
        this.releaseWorkflowDialog = false;
        this.declarationWorkflow('request_client_approval');
        return;
      } else if (this.selectedClientApprovalOption === 'approval_available') {
        // Client approval is available
        this.releaseWorkflowDialog = false;
        this.declarationWorkflow('client_approval_available');
        return;
      }

      // If user has permission, and it has other users also who have permission
      // then user has to choose some assignee otherwise can release himself

      let stage = '';
      if (this.declarationData.status === 'draft' || this.declarationData.status === 'missing_data') {
        if (this.hasPermissionForRelease && this.usersCanRelease.length <= 1) {
          stage = this.$isBusiness ? 'elster_submission' : 'external_approval_requested'; // User will release himself
        } else {
          stage = 'internal_approval_requested'; // User selected a assignee for release
        }
      } else if (this.declarationData.status === 'internal_approval_requested') {
        if (mode === 'request_approval') {
          stage = 'internal_approval_requested'; // User selected a assignee for release
        } else if (mode === 'release') {
          stage = this.$isBusiness ? 'elster_submission' : 'external_approval_requested'; // User will release himself
        }
      }

      if (stage) {
        this.releaseWorkflowDialog = false;
        this.service.updateStage({
          'prim_uid': this.declarationData.prim_uid,
          'stage': stage,
          'assignee': this.assigneeForRelease
        }).then((res) => {
          if (res) {
            this.$api.showToast(this.$t('general.data_saved'));
            this.startEditing();
          }
        })
      }
    },
    change_save_calculation_community(check) {
      this.save_calculation_community = check;
    },
    extraDetailsChecked(check) {
      this.showExtraDetails = check;
    },
    checkParticipation() {
      this.declarationData.participation = true;
      this.participationDialog = false;
    },
    clickNonCheckableParticipation() {
      this.participationDialog = true;
    },
    participationChecked(check) {
      this.declarationData.participation = check;
    },
    handleCheck(check) {
      this.checked = check
    },
    invoiceSent(check) {
      this.declarationData.feeInvoiceSent = check ? "1" : "0";
    },
    hideFarmRegulationsInTaxCalculationCheck(check) {
      this.declarationData.hideFarmRegulationsInTaxCalculation = check ? '1' : '0';
    },
    resetApproval() {
      if (this.approversCount > 0 && this.declarationData.status === 'external_approval_requested') {
        this.service.trigger('declaration_workflow/reset_approval', {
          "declarationId": this.declarationData.prim_uid
        }, true).then((response) => {
          if (response.data.success) {
            this.approversCount = 0;
            this.clientApproverEmailsStatus = [];
            this.clientApprovalMode = '';
            this.declarationData.clientApprovalMode = '';
            this.prepareClientApprovalModes();
          } else {
            this.$api.showToast(response.data.message, "error");
          }
        })
      }
    },
    requestDataFromClient() {
      this.clientApproverEmailsError = false;
      let requestApproval = this.selectedClientApprovalOption === 'request_approval' || this.declarationData.status === 'external_approval_requested';
      if (requestApproval && this.clientApprovalMode === '') {
        return;
      }

      if (requestApproval && this.clientApprovalMode === 'approval_available') {
        // Client approval is available
        this.emailDialog = false;
        this.releaseWorkflowDialog = false;
        this.declarationWorkflow('client_approval_available');
        return;
      }

      if (requestApproval && this.clientApprovalMode === 'multi_emails' && this.clientApproverEmails.length === 0) {
        this.clientApproverEmailsError = true;
        return;
      }

      this.emailDialog = false;
      this.service.trigger('declaration_workflow/request_client_data', {
        "declarationId": this.declarationData.prim_uid,
        'emailBody': this.clientDataRequestEmailBody,
        'requestApproval': requestApproval ? 1 : 0,
        'requestApprovalMode': requestApproval ? this.clientApprovalMode : '',
        'clientApproverEmails': requestApproval && this.clientApprovalMode === 'multi_emails' ? this.clientApproverEmails : [],
        'sendClientPortalApprovalEmail': this.sendClientPortalApprovalEmail
      }, true).then((response) => {
        if (response.data.success) {
          if (this.declarationData.status === 'external_approval_requested' || this.selectedClientApprovalOption === 'request_approval') {
            this.startEditing();
          } else if (response.data.clientDataRequestedAt) {
            this.declarationData.clientDataRequestedAt = response.data.clientDataRequestedAt;
          }
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      })
    },
    addApproversEmail() {
      if (this.approverEmail && this.$refs.approver_email.validateEmail(this.approverEmail)) {
        this.clientApproverEmails.push(this.approverEmail);
        this.$nextTick(() => this.approverEmail = '');
      }
    },
    removeApproverEmail(email) {
      if (email && this.clientApproverEmails.length > 0) {
        let index = this.clientApproverEmails.indexOf(email);
        if (index > -1) {
          this.showApproverEmailChip = false;
          this.clientApproverEmails.splice(index, 1)
          this.$nextTick(() => this.showApproverEmailChip = true);
        }
      }
    },
    selectClientApprovalOption(opt) {
      if (this.approversCount === 0) {
        this.clientApprovalMode = opt;
        this.sendClientPortalApprovalEmail = true;
        let email_tpl = '';
        if (opt === 'client_portal') {
          email_tpl = 'property_declaration_approval';
        } else if (opt === 'multi_emails') {
          email_tpl = 'property_declaration_multi_approval';
          if (this.declarationData.client.address.email && this.approversCount === 0 && this.clientApproverEmails.length === 0) {
            this.clientApproverEmails.push(this.declarationData.client.address.email);
          }
        }

        if (email_tpl) {
          this.$api.getEmailTemplate(email_tpl, true).then((content) => {
            this.clientDataRequestEmailBody = content
                .replace('%sender_name%', this.getCurrentUser().name)
                .replace('%property_name%', this.declarationData.property.name)
                .replace(/<br\s*[\/]?>/gi, "");
          });
        }
      }
    },
    toggleClientEmailSend(checked) {
      this.sendClientPortalApprovalEmail = !checked;
    },
    handleEmailDialogClose() {
      this.emailDialog = false
    },
    handleModalClose() {
      this.isModalVisible = false
    },
    handleElsterValidationResultDialogOpen() {
      //Add newline between list of possibilities for better formatting
      this.elsterValidationHints.forEach((el) => {
        if (/ähneln Ihrer Eingabe/.test(el.readable)) {
          el.readable = el.readable.replaceAll('\",', '",\n');
        }
      })
      this.elsterValidationResultDialog = true;
    },
    handleElsterValidationResultDialogClose() {
      this.elsterValidationResultDialog = false;
    },
    toggleClick(args) {
      let index = this.protocols.findIndex(item => item.version_number === args.version_number)  ///need a unique id to match this condition may fail
      this.protocols[index].showDetail = !this.protocols[index].showDetail;
    },
    renderData(data) {
      return data ? data : '-';
    },
    renderFloatData(data, suffix) {
      if (typeof suffix === 'undefined') {
        suffix = '';
      }

      if (data === null) {
        return '-';
      }

      return data ? data.replaceAll('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + suffix : '-';
    },
    selectReceiverType(receiverType, force) {
      if (this.isReadOnly) {
        return;
      }
      if (receiverType !== this.declarationData.recipientType || force) {
        this.recipient = {
          salutation: '1',
          title: '',
          phone: '',
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          zip: '',
          postbox: '',
          city: '',
          authorizedReceiver: "0"
        }

        if (receiverType === 'tax_consultant') {
          this.fillRecipientByPowerOfAttorney(parseInt(this.declarationData.powerOfAttorney));
        } else if (receiverType === 'power_of_attorney_from_client') {
          let poa = parseInt(this.declarationData.powerOfAttorneyFromClient);
          if (poa === 0 && parseInt(this.declarationData.client.powerOfAttorney) > 0) {
            poa = parseInt(this.declarationData.client.powerOfAttorney);
          }
          this.fillRecipientByPowerOfAttorney(poa);
        } else if (receiverType === 'power_of_attorney_from_branch') {
          let poa = parseInt(this.declarationData.powerOfAttorneyFromBranch);
          if (poa === 0 && parseInt(this.declarationData.branch.powerOfAttorney) > 0) {
            poa = parseInt(this.declarationData.branch.powerOfAttorney);
          }
          this.fillRecipientByPowerOfAttorney(poa);
        } else if (receiverType === 'others') {
          this.recipient = this.declarationData.recipient;
        }
      }
    },
    fillRecipientByPowerOfAttorney(id) {
      let selectedPowerOfAttorney = this.powersOfAttorneyList.filter((o) => {
        return parseInt(o.code) === id;
      });
      if (selectedPowerOfAttorney.length > 0 && selectedPowerOfAttorney[0].detail) {
        this.recipient.salutation = selectedPowerOfAttorney[0].detail.salutation;
        this.recipient.phone = selectedPowerOfAttorney[0].detail.phone;
        this.recipient.firstName = selectedPowerOfAttorney[0].detail.firstName;
        this.recipient.lastName = selectedPowerOfAttorney[0].detail.lastName;
        this.recipient.street = selectedPowerOfAttorney[0].detail.street;
        this.recipient.houseNumber = selectedPowerOfAttorney[0].detail.houseNumber;
        this.recipient.zip = selectedPowerOfAttorney[0].detail.zip;
        this.recipient.city = selectedPowerOfAttorney[0].detail.city;
        this.recipient.postbox = selectedPowerOfAttorney[0].detail.postbox;
        this.recipient.authorizedReceiver = selectedPowerOfAttorney[0].detail.authorizedRecipient === "1" ? '1' : '0';
        this.recipient.title = selectedPowerOfAttorney[0].detail.title;
      }
    },
    addNewAttorney() {
      this.$router.push('/powerofattorney/addPowerOfAttorney');
    },
    powerOfAttorneySelected(item) {
      this.fillRecipientByPowerOfAttorney(parseInt(item.code))
    },
    elsterValidate(showLoader = true, silent = false, showErrorsOnly = false, needsPdfGeneration = false) {
      return new Promise((resolve, reject) => {
        this.elsterValidationErrors = [];
        this.elsterValidationHints = [];

        this.$v.$touch() // we check the form to see if it's valid

        if (this.$v.$invalid) {
          for (let x in this.$v.declarationData.recipient) {
            if (this.$v.declarationData.recipient[x].$error) {
              this.errors.recipient[x].invalid = true;
            }
          }

          return reject();
        }

        this.elsterValidationPropertyUid = this.declarationData.property.propertyUid;
        this.service.elsterValidate({
          'prim_uid': this.declarationData.prim_uid,
          'showSavedElsterResponse': this.showSavedElsterResponse ? 1 : 0,
          'needsElsterPdfGeneration': needsPdfGeneration
        }, showLoader)
          .then((validationResponse) => {
            if (validationResponse.success === undefined && validationResponse.errors === undefined) {
              if (!silent) {
                this.$api.showToast(this.$t('general.errors.elsterValidationError'), 'error');
              }

              return reject();
            }

            if (
                typeof (validationResponse.errors) !== 'undefined'
                && validationResponse.errors !== null
                && Object.values(validationResponse.errors).length > 0
            ) {
              Object.values(validationResponse.errors).forEach(error => {
                if (error.type === 'error') {
                  this.elsterValidationErrors.push(error)
                } else if (error.type === 'hint' && !this.showSavedElsterResponse) {
                  this.elsterValidationHints.push(error);
                }
              });
            } else if (validationResponse.success === false) {
              if (!silent) {
                this.$api.showToast(this.$t('general.errors.elsterValidationError'), 'error');
              }

              return reject();
            }

            this.showSavedElsterResponse = false;

            if (
                !silent
                && (!showErrorsOnly || (showErrorsOnly && this.elsterValidationErrors.length))
            ) {
              this.handleElsterValidationResultDialogOpen();
            }

            return resolve();
          })
          .catch((e) => {
            console.error(e);
            return reject();
          });
      });
    },
    async elsterDownloadPdf(documentType, opt) {
      this.service.elsterDownloadPdf({
        "prim_uid": this.declarationData.prim_uid,
        "documentType": documentType
      }).then((downloadPdfResponse) => {
        if (!downloadPdfResponse.success) {
          this.$api.showToast(this.$t('general.errors.elsterPdfDownload'), 'error');
          return;
        }

        if (downloadPdfResponse.fileContent && downloadPdfResponse.fileContent.length > 0) {
          const downloadLink = document.createElement("a");
          if (opt && opt === 'view' && downloadPdfResponse.token) {
            downloadLink.href = '/download?token=' + downloadPdfResponse.token;
            downloadLink.target = '_blank';
          } else {
            downloadLink.href = "data:application/pdf;base64," + downloadPdfResponse.fileContent;
            downloadLink.download = downloadPdfResponse.fileName;
          }
          downloadLink.click();
        }
      });
    },
    openSettings() {
      if (this.hasAccess('system_settings')) {
        this.$router.push({
          name: "Setting",
          params: {
            "comp": 'systemsettings',
            "tab": 'client_portal'
          }
        });
      }
    },
    prepareClientApprovalModes() {
      // clientApprovalModes: show client portal option only if client have access
      this.clientApprovalModes = _clone(this.$t('declarations.detail.buttons.request_client_approval.approval_options'));
      if (!(this.declarationData.client.clientPortalAccess && this.declarationData.client.clientUserEmail)) {
        delete this.clientApprovalModes.client_portal;
      }
      if (this.declarationData.clientApprovalMode && this.declarationData.clientApprovalMode !== '') {
        delete this.clientApprovalModes.external_approval;
      }
    },
    fetchTaxCalculationOrObjectValue() {
      this.communityIsValidForTax = true;
      if (this.activeTab === "tax-calculation") {
        this.fetchTaxCalculation();
      } else {
        this.calculatePropertyValue(true, true);
      }
    },
    communitytListSearch(search) {
      const params = {uid: this.declarationData.prim_uid, community: search.txtFilter}
      if (search.uid) {
        params.communityUid = search.uid;
      }

      return this.service.get_communities(params).then((data) => {
        if (data.error) {
          return false;
        }

        const communities = Object.keys(data.data.communities).map((key) => data.data.communities[key]);
        if (!communities) {
          return false;
        }

        return communities.map((o) => {
          let name = o['community'];
          let code = o['agn'];

          if (this.declarationData.property.federalStateUid == 9 || this.declarationData.property.federalStateUid == 7) {
            name = name + " -  Ø Bodenrichtwert: " + o["land_value"] + " €/m²";

          } else {
            name = name + " -  Mietniveaustufe: " + o['rent_level'];
          }

          return {
            "name": name,
            "code": code
          };
        })
      })
    },
    closeCommunitySelection() {
      this.communityIsValidForTax = true;
      this.showCommunityOpener = true;
    },
    closeTaxCalculationErrorDialog() {
      this.showTaxCalculationError = false;
    },
    openCommunitySelection() {
      this.closePropertyMenu({})
      this.communityIsValidForTax = false;
      this.communitytListSearch({uid: this.tax_community})
        .then((communityList) => {
          if (communityList !== false) {
            this.communityList = communityList;
          }
        });
    },
    async fetchTaxCalculation() {
      this.taxCalculationError = false;
      this.fetchingTaxCalculation = true;
      this.communityIsValidForTax = true;
      if (!this.tax_community && this.declarationData.calculationCommunity) {
        this.tax_community = this.declarationData.calculationCommunity;
      }

      this.service.getTaxCalculation(this.declarationData.prim_uid, "html", this.tax_community, this.save_calculation_community)
        .then(async ({error, data, error_key, values}) => {
          if (error) {
            this.handleTaxCalculationError(error_key, values);
            return;
          }

          this.communityIsValidForTax = true;
          this.taxCalculationHtml = data;
        })
        .finally(() => {
          this.fetchingTaxCalculation = false;
        });
    },
    closeTaxAssessmentUploadDialog() {
      this.showTaxAssessmentUploadDialog = false;
    },
    closeCompareDocumentsDialog() {
      this.showCompareDocumentsDialog = false;
    },
    elsterMessageAttachmentsFilesChanged(files) {
      this.elsterMessage.attachments = [];
      this.elsterMessageValidations.attachments = [];

      if (files.length === 0) {
        return;
      }

      files.forEach((file) => {
        this.elsterMessageValidations.attachments.push({'invalid': false});

        let attachment = {
          'file': file,
          'description': file.description
        };

        if (file.uuid.endsWith('.pdf')) {
          attachment.fileName = file.uuid;
        }

        this.elsterMessage.attachments.push(attachment);
      });
    },
    elsterMessageAttachmentsFileSelect(e) {
      this.elsterMessageAttachmentsFilesChanged(e.files);
    },
    elsterMessageConfirmationChecked(checked) {
      this.checkText();
      this.elsterMessage.confirmed = checked;
    },
    resetElsterMessageValidations() {
      this.elsterMessageValidated = false;
      this.elsterMessageValidations.subject.invalid = false;
      this.elsterMessageValidations.justification.invalid = false;
      this.elsterMessageValidations.confirmation.invalid = false;
      this.elsterMessageValidations.actDate.invalid = false;
      this.elsterMessageValidations.actId.invalid = false;
      this.elsterMessageValidations.actDeadline.invalid = false;
      this.elsterMessageValidations.suspensionOfExecution.invalid = false;
      this.elsterMessageValidations.attachments.forEach((entry) => {
        entry.invalid = false;
      })
      this.elsterMessageValidations.errors = [];
      this.elsterMessageValidations.hints = [];
    },
    resetElsterMessageData(messageType = 'deadline_extension') {
      this.elsterMessage = {
        declarationId: 0,
        messageType: messageType,
        isDraft: false,
        subject: '',
        differingReferenceNumber: '',
        differingAppellant: '',
        differingPowerOfAttorneyId: '',
        justification: '',
        differingReferenceDate: 0,
        extensionUntil: '2-Weeks',
        processingType: 'automated',
        actDate: '',
        actId: this.getDefaultAdministrativeAct(),
        actDeadline: '',
        suspensionOfExecution: 'no',
        attachments: [],
        confirmed: false
      };

      this.$refs.elsterMessageAttachmentsUpload.clear();

      this.resetElsterMessageValidations();
    },
    preValidateElsterMessage() {
      if (
        ['appeal', 'other'].includes(this.elsterMessage.messageType)
        && (this.elsterMessage.subject.trim() === '' || this.elsterMessage.subject.length > this.getElsterMessageSubjectMaxLimit)
      ) {
        this.elsterMessageValidations.subject.invalid = true;
        return false;
      }

      if (this.elsterMessage.messageType === 'subsequent') {
        if (
          this.elsterMessage.justification.length > this.getElsterMessageJustificationMaxLimit
          || (this.elsterMessage.attachments.length === 0 && this.elsterMessage.justification.trim() === '')
        ) {
          this.elsterMessageValidations.justification.invalid = true;
          return false;
        }
      } else if (
        this.elsterMessage.justification.trim() === ''
        || this.elsterMessage.justification.length > this.getElsterMessageJustificationMaxLimit
      ) {
        this.elsterMessageValidations.justification.invalid = true;
        return false;
      }

      if (
        this.elsterMessage.actDate.trim() === ''
        && this.elsterMessage.messageType === 'appeal'
      ) {
        this.elsterMessageValidations.actDate.invalid = true;
        return false;
      }

      if (
        this.elsterMessage.actId.trim() === ''
        && this.elsterMessage.messageType === 'appeal'
      ) {
        this.elsterMessageValidations.actId.invalid = true;
        return false;
      }

      if (
        this.elsterMessage.actDeadline === ''
        && this.elsterMessage.actId === '108'
      ) {
        this.elsterMessageValidations.actDeadline.invalid = true;
        return false;
      }

      if (
        this.elsterMessage.suspensionOfExecution.trim() === ''
        && this.elsterMessage.messageType === 'appeal'
      ) {
        this.elsterMessageValidations.suspensionOfExecution.invalid = true;
        return false;
      }

      if (this.elsterMessage.attachments.length > 0) {
        let attachmentsAreInvalid = false;

        let attachmentHasDuplicateDescription = false;
        let attachmentDescriptions = [];
        this.elsterMessage.attachments.forEach((attachment, index) => {
          if (attachment.description.trim() === '' || attachment.description.length > 50) {
            this.elsterMessageValidations.attachments[index].invalid = true;
            attachmentsAreInvalid = true;
          }

          if (attachmentDescriptions.includes(attachment.description)) {
            this.elsterMessageValidations.attachments[index].invalid = true;
            attachmentsAreInvalid = true;
            attachmentHasDuplicateDescription = true;
          }

          attachmentDescriptions.push(attachment.description);
        });

        if (attachmentsAreInvalid === true) {
          if (attachmentHasDuplicateDescription) {
            this.$api.showToast(this.$t('general.errors.duplicate_attachment_description'), 'error');
          }

          return false;
        }
      }
    },
    async validateElsterMessage(forPreviewOnly = false) {
      this.resetElsterMessageValidations();

      if (this.preValidateElsterMessage() === false) {
        return;
      }

      if (forPreviewOnly !== true) {
        if (!this.elsterMessage.confirmed && !this.elsterMessage.isDraft) {
          this.elsterMessageValidations.confirmation.invalid = true;
          return;
        }
      }

      this.elsterMessage.declarationId = this.declarationData.prim_uid;

      let elsterMessageData = await this.getElsterMessageForRequest();

      this.service.validateElsterMessage(elsterMessageData).then((response) => {
        this.parseElsterMessageErrorsAndHints(response.data);
        if (response.data.success) {
          this.elsterMessageValidated = true;
          if (response.data.elster.fileContent && response.data.elster.fileContent.length > 0) {
            const downloadLink = document.createElement("a");
            downloadLink.href = "data:application/pdf;base64," + response.data.elster.fileContent;
            downloadLink.download = response.data.elster.fileName;
            downloadLink.click();
          }
        } else {
          if (!this.hasElsterMessageErrors) {
            if (response.data.message === undefined) {
              this.$api.showToast(this.$t('general.errors.unknownError'), "error")
            } else {
              this.$api.showToast(response.data.message, "error")
            }
          }
        }

        // Delay scrolling to wait for Vue to render content of errors list
        setTimeout(
          () => document.getElementById('elster-message-errors-list').scrollIntoView(true),
          200
        );
      })
    },
    async sendElsterMessage() {
      this.showModalIsDeclarationWithoutSubmission = false;
      this.resetElsterMessageValidations();

      if (this.preValidateElsterMessage() === false) {
        return;
      }

      if (!this.elsterMessage.confirmed && !this.elsterMessage.isDraft) {
        this.elsterMessageValidations.confirmation.invalid = true;
        return;
      }

      let elsterMessageData = await this.getElsterMessageForRequest();
      elsterMessageData.isDraft = elsterMessageData.isDraft ? 1 : 0;

      this.elsterMessage.declarationId = this.declarationData.prim_uid;

      this.service.sendElsterMessage(elsterMessageData).then((response) => {
        this.parseElsterMessageErrorsAndHints(response.data);
        if (response.data.success) {
          if (elsterMessageData.isDraft === 1) {
            this.$api.showToast(this.$t('declarations.detail.elster_message.saved_successfully'));
          } else {
            this.$api.showToast(this.$t('declarations.detail.elster_message.sent_successfully'));
          }

          this.closeElsterMessage();
          this.startEditing();

          setTimeout(
            () => this.triggerReload('elsterMessagesGrid'),
            500
          );
        } else {
          if (!this.hasElsterMessageErrors) {
            if (response.data.message === undefined) {
              this.$api.showToast(this.$t('general.errors.unknownError'), "error")
            } else {
              this.$api.showToast(response.data.message, "error")
            }
          }

          // Delay scrolling to wait for Vue to render content of errors list
          setTimeout(
            () => document.getElementById('elster-message-errors-list').scrollIntoView(true),
            200
          );
        }
      })
    },
    parseElsterMessageErrorsAndHints(elsterResponse) {
      if (typeof (elsterResponse.errors) !== 'undefined' && elsterResponse.errors !== null && Object.values(elsterResponse.errors).length > 0) {
        Object.values(elsterResponse.errors).forEach(error => {
          if (error.type === 'error') {
            this.elsterMessageValidations.errors.push(error)
          } else if (error.type === 'hint') {
            this.elsterMessageValidations.hints.push(error);
          }
        });
      }
    },
    async getElsterMessageForRequest() {
      let elsterMessage = {...this.elsterMessage};

      if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect === 'attribution_perpetuation') {
        elsterMessage.isAttributionPerpetuation = true;
      }

      if (elsterMessage.attachments.length > 0) {
        let attachments = await Promise.all(
          elsterMessage.attachments.map(async (attachment) => {
            if (attachment.fileName) {
              return {
                fileName: attachment.fileName,
                description: attachment.description
              };
            }

            return await this.getElsterMessageAttachmentsForRequest(attachment);
          })
        );

        elsterMessage.attachments = attachments;
      }

      return elsterMessage;
    },
    async getElsterMessageAttachmentsForRequest(attachment) {
      let fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(attachment.file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      return {
        fileData: fileContent,
        fileUuid: attachment.file.uuid,
        description: attachment.description
      }
    },
    elsterMessageColClick(e) {
      if (e.gridId === 'elsterMessagesGrid' && e.field === 'protocol' && e.iconKey && e.row.documentUid) {
        if (e.iconKey === 'view') {
          this.$router.push({
            name: "DocumentViewer",
            params: {
              'id': parseInt(e.row.documentUid),
              'parentType': 'declaration',
              'parentId': this.declarationData.prim_uid,
              'breadcrumbs': [
                {
                  title: this.$t('dashboard.top_header.properties'),
                  link: '/declaration/declarations'
                }, {
                  title: this.declarationData.declarationNumber,
                  link: '/declaration/edit/?uid=' + this.declarationData.prim_uid
                }, {
                  title: this.$t('clients.edit.tabs.document'),
                  backButtonHandler: true,
                  handler: () => {
                    this.$router.push({
                      name: "Declaration",
                      params: {
                        'comp': 'edit',
                        'selectedTab': 'elster-message'
                      },
                      query: {
                        'uid': this.declarationData.prim_uid
                      }
                    });
                  }
                }
              ]
            }
          });
        } else if (e.iconKey === 'download') {
          this.documentService.setDefaultParams({
            "parentType": 'declaration',
            "parentId": this.declarationData.prim_uid,
            "gridId": "elsterMessagesGrid"
          });
          this.documentService.download(e.row.documentUid)
        } else if (e.iconKey === 'elster_response') {
          this.resetElsterMessageValidations();
          this.parseElsterMessageErrorsAndHints(e.row.elsterSubmissionResponse)
          this.currentElsterMessageResponseType = e.row.messageTypeText;
          this.showElsterMessageResponse = true;
        } else if (e.iconKey === 'edit_elster_message') {
          this.openElsterMessageDlg(e.row.messageType);

          this.$nextTick(() => {
            this.elsterMessage.id = e.row.id;
            this.elsterMessage.declarationId = e.row.declarationUid;
            this.elsterMessage.isDraft = parseInt(e.row.isDraft) > 0 ? true : false;
            this.elsterMessage.subject = e.row.details.subject;
            this.elsterMessage.differingReferenceNumber = e.row.details.differingReferenceNumber;
            this.elsterMessage.differingAppellant = e.row.details.differingAppellant;
            this.elsterMessage.differingPowerOfAttorneyId = e.row.details.differingPowerOfAttorneyId;
            this.elsterMessage.differingReferenceDate = e.row.details.differingReferenceDate;
            this.elsterMessage.justification = e.row.details.justification;
            this.elsterMessage.extensionUntil = e.row.details.extensionUntil;
            this.elsterMessage.processingType = e.row.details.processingType;
            this.elsterMessage.actDate = e.row.details.actDate;
            this.elsterMessage.actId = e.row.details.actId;
            this.elsterMessage.actDeadline = e.row.details.actDeadline;
            this.elsterMessage.suspensionOfExecution = e.row.details.suspensionOfExecution;

            if (e.row.details.attachments) {
              Object.entries(e.row.details.attachments).forEach(([key, attachment]) => {
                let currentAttachment = {
                  file: {
                    uuid: attachment.fileName,
                    name: attachment.description + '.pdf',
                    size: attachment.size,
                    recordId: e.row.id,
                    parentId : e.row.declarationUid,
                  },
                  description: attachment.description,
                  fileName: attachment.fileName
                };

                this.elsterMessage.attachments.push(currentAttachment);
                this.elsterMessageValidations.attachments.push({'invalid': false});
                this.$refs.elsterMessageAttachmentsUpload.addAlreadyUploadedFile(currentAttachment.file);
              });
            }

            if (this.elsterMessage.isDraft) {
              this.elsterMessageButtonText = this.$t('general.submit');
            } else {
              this.elsterMessageButtonText = this.$t('buttons.send');
            }
          });
        } else if (e.iconKey === 'open_attachments') {
            this.elsterMessageId = parseInt(e.row.id);
            this.showElsterMessageAttachmentsModal = true;
        }
      }
    },
    openElsterMessageDlg(selectedMessageType = 'deadline_extension') {
      this.resetElsterMessageData(selectedMessageType);
      this.elsterMessageValidated = false;
      this.showElsterMessage = true;

      if (this.elsterMessage.isDraft) {
        this.elsterMessageButtonText = this.$t('general.submit');
      } else {
        this.elsterMessageButtonText = this.$t('buttons.send');
      }
    },
    closeElsterMessage() {
      this.showElsterMessage = false;
      this.showModalIsDeclarationWithoutSubmission  = false;
      delete this.$route.query.redirect;
    },
    closeElsterMessageResponse() {
      this.showElsterMessageResponse = false;
      this.currentElsterMessageResponseType = '';
    },
    taxAssessmentFileSelect() {
      let files = this.$refs.taxAssessmentFileInput.files;
      if (files.length === 1) {
        this.$loading.show();
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append('uid', this.declarationData.prim_uid);
        this.service.trigger('declaration/upload_tax_assessment', formData, false, 'multipart/form-data').then((response) => {
          if (response.data.success) {
            this.loadAssessmentDocument();
          } else {
            this.$loading.hide();
            this.$api.showToast(response.data.message, "error")
          }
        });
      }
    },
    chooseTaxAssessment() {
      this.$refs.taxAssessmentFileInput.click();
    },
    uploadTaxAssessment() {
      let formData = new FormData();

      if (this.$refs.uploadTaxAssessment.files.length === 1) {
        for (let file of this.$refs.uploadTaxAssessment.files) {
          formData.append('file', file);
        }
      }

      if (this.$refs.uploadTaxAssessment2.files.length === 1) {
        for (let file of this.$refs.uploadTaxAssessment2.files) {
          formData.append('file2', file);
        }
      }

      if (this.$refs.uploadTaxAssessment3 !== undefined && this.$refs.uploadTaxAssessment3.files.length === 1) {
        for (let file of this.$refs.uploadTaxAssessment3.files) {
          formData.append('file3', file);
        }
      }

      formData.append('uid', this.declarationData.prim_uid);
      formData.append('deadlineCalculation', JSON.stringify(this.deadline_calculation));
      this.service.trigger('declaration/upload_tax_assessment', formData, true, 'multipart/form-data').then((response) => {
        if (response.data.success) {
          this.showTaxAssessmentUploadDialog = false;
          this.startEditing();
          this.triggerReload(this.deadlineGridId)
        } else {
          this.$api.showToast(response.data.message, "error")
        }
      });
    },
    acceptTaxAssessment() {
      if (parseInt(this.selectedTaxDocumentSwitchOption) === this.taxDocumentSwitchOptions.length - 1) {
        this.service.getTaxDocument(this.declarationUid, 'tax_calculation_modified', true);
      }

      this.service.updateStage({
        'prim_uid': this.declarationData.prim_uid,
        'stage': 'completed'
      }).then((res) => {
        this.closeCompareDocumentsDialog();
        if (res) {
          this.$api.showToast(this.$t('general.data_saved'));
          this.startEditing();
        }
      })
    },
    rejectAssessment() {
      this.showAppealTypeSelectionModal = true;
    },
    downloadPropertyValuePdf() {
      this.closePropertyMenu({});
      this.service.getPropertyValueCalculation(this.declarationData.prim_uid, this.tax_community, 'pdf', true, false, this.considerExemption);
    },
    openPropertyValuePdf() {
      this.service.getPropertyValueCalculation(this.declarationData.prim_uid, this.tax_community, 'view-pdf', true, false, this.considerExemption).then((resp) => {
        if (resp && resp.data.success && resp.data.token) {
          const downloadLink = document.createElement("a");
          downloadLink.href = '/download?token=' + resp.data.token;
          downloadLink.target = '_blank';
          downloadLink.click();
        }
      });
    },
    downloadTaxCalculationPdf() {
      this.service.getTaxCalculation(this.declarationData.prim_uid, 'pdf', this.tax_community);
    },
    considerExemptionInPropertyValueToggle() {
      let check = true;
      if (this.considerExemption) {
        check = false;
      }

      this.considerExemption = check;
      this.calculatePropertyValue(true, true, true);
    },
    openTaxCalculationPdf() {
      this.service.getTaxCalculation(this.declarationData.prim_uid, 'view-pdf', this.tax_community).then((resp) => {
        if (resp && resp.data.success && resp.data.token) {
          const downloadLink = document.createElement("a");
          downloadLink.href = '/download?token=' + resp.data.token;
          downloadLink.target = '_blank';
          downloadLink.click();
        }
      })
    },
    calculatePropertyValue(silent = false, showLoader = false, refreshValue = false) {
      this.elsterValidate(showLoader, silent)
        .then(() => {
          if (this.elsterValidationErrors.length > 0 && this.declarationData.type !== '1001') {
            if (!this.calculatingPropertyValue) {
              this.showTaxCalculationError = !silent;
              this.taxCalculationError = this.$i18n.t('declarations.tax_calculation.errors.elster');
            }
            this.calculatingPropertyValue = false;

            return;
          }

          this.communityIsValidForTax = true;
          if ((this.declarationData.property.federalStateUid === '2' && this.declarationData.type === '4')) {
            this.propertyValue = null;
            this.calculatingPropertyValue = false;
          } else {
            this.service.getPropertyValueCalculation(this.declarationData.prim_uid, this.tax_community, '', silent, this.save_calculation_community, this.considerExemption, refreshValue)
              .then(async ({error, data, values}) => {
                if (error && !silent) {
                  if (data === 'no_community') {
                    if (this.declarationData.calculationCommunity && this.tax_community === '') {
                      this.tax_community = this.declarationData.calculationCommunity;
                    }
                    this.communityIsValidForTax = false;
                  } else {
                    this.$api.showToast(
                        this.$i18n.t('declarations.tax_calculation.errors.no_object_value') +
                        this.$i18n.t(
                            'declarations.tax_calculation.errors.' + data,
                            {
                              taxModel: this.getTaxModel,
                              values: values
                            }
                        ),
                        'info'
                    );
                  }
                } else {
                  this.propertyValue = data.propertyValue;
                }

                this.calculatingPropertyValue = false;
              });
          }
        })
        .catch(() => {
          this.calculatingPropertyValue = false;
        });
    },
    checkSynchronizeHandler() {
      this.checkSynchronize();
      if (!this.synchronizeDialog && !this.extraDetailsSynchronizeDialog && !this.assignedUserSynchronizeDialog && !this.multiSynchronizeDialog) {
        this.saveDeclaration();
      }
    },
    checkSynchronize() {
      this.declarationNumberChanged = false;
      this.extraDetailsChanged = false;
      this.assignedUserChanged = false;

      var syncPossibleStatus = ['draft', 'missing_data', 'internal_approval_requested', 'external_approval_requested'];
      if (syncPossibleStatus.includes(this.declarationData.status) && this.declarationData.elsterTransferredAt === '' && this.synchronizedPropertyCount > 0) {
        let syncCount = 0;

        if (this.originalDeclarationNumber !== this.declarationData.declarationNumber) {
          this.declarationNumberChanged = true;
          syncCount++;
        }

        if (this.originalExtraDetails !== this.declarationData.extraDetails) {
          this.extraDetailsChanged = true;
          syncCount++;
        }

        if (this.originalAssignedUser !== this.declarationData.assignedUser) {
          this.assignedUserChanged = true;
          syncCount++;
        }

        if (this.declarationNumberChanged && !this.extraDetailsChanged && !this.assignedUserChanged) {
          this.synchronizeDialog = true;
          this.$modal.show({
            headerText: this.$t('declarations.synchronize'),
            text: this.$t('declarations.synchronize_description'),
            showClose: true,
            buttons: {
              "ok": this.$t('declarations.synchronize_yes'),
              "cancel": this.$t('declarations.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.referenceSynchronize = true;
              this.synchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            },
            onCancel: () => {
              this.synchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            },
            onHide: () => {
              this.synchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            }
          });
        }

        if (!this.declarationNumberChanged && this.extraDetailsChanged && !this.assignedUserChanged) {
          this.extraDetailsSynchronizeDialog = true;
          this.$modal.show({
            headerText: this.$t('declarations.synchronize'),
            text: this.$t('declarations.extra_details_synchronize_description'),
            showClose: true,
            buttons: {
              "ok": this.$t('declarations.synchronize_yes'),
              "cancel": this.$t('declarations.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.extraDetailsSynchronize = true;
              this.extraDetailsSynchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            },
            onCancel: () => {
              this.extraDetailsSynchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            },
            onHide: () => {
              this.extraDetailsSynchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            }
          });
        }

        if (!this.declarationNumberChanged && !this.extraDetailsChanged && this.assignedUserChanged) {
          this.assignedUserSynchronizeDialog = true;
          this.$modal.show({
            headerText: this.$t('declarations.synchronize'),
            text: this.$t('declarations.assigned_user_synchronize_description'),
            showClose: true,
            buttons: {
              "ok": this.$t('declarations.synchronize_yes'),
              "cancel": this.$t('declarations.synchronize_no'),
              delete: false
            },
            onConfirm: () => {
              this.assignedUserSynchronize = true;
              this.assignedUserSynchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            },
            onCancel: () => {
              this.assignedUserSynchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            },
            onHide: () => {
              this.assignedUserSynchronizeDialog = false;
              if (this.btnKey !== '') {
                this.saveDeclarationHandler(this.btnKey);
              } else {
                this.saveDeclaration();
              }
            }
          });
        }

        if (syncCount > 1) {
          this.referenceSyncChecked = false;
          this.extraDetailsSyncChecked = false;
          this.assignedUserSyncChecked = false;
          this.multiSynchronizeDialog = true;
        }
      }
    },
    referenceSyncCheck(checked) {
      this.referenceSyncChecked = !!checked;
    },
    elsterMessageIsDraftCheck(checked) {
      this.elsterMessage.isDraft = !!checked;

      if (this.elsterMessage.isDraft) {
        this.elsterMessageButtonText = this.$t('general.submit');
      } else {
        this.elsterMessageButtonText = this.$t('buttons.send');
      }
    },
    extraDetailsSyncCheck(checked) {
      this.extraDetailsSyncChecked = !!checked;
    },
    assignedUserSyncCheck(checked) {
      this.assignedUserSyncChecked = !!checked;
    },
    closeMultiSynchronizeDialog() {
      if (this.multiSynchronizeDialog) {
        this.referenceSynchronize = false;
        this.extraDetailsSynchronize = false;
        this.assignedUserSynchronize = false;
        this.multiSynchronizeDialog = false;

        if (this.btnKey !== '') {
          this.saveDeclarationHandler(this.btnKey);
        } else {
          this.saveDeclaration();
        }
      }
    },
    saveMultiSynchronizeDialog() {
      if (this.referenceSyncChecked) {
        this.referenceSynchronize = true;
      }

      if (this.extraDetailsSyncChecked) {
        this.extraDetailsSynchronize = true;
      }

      if (this.assignedUserSyncChecked) {
        this.assignedUserSynchronize = true;
      }

      this.multiSynchronizeDialog = false;
      if (this.btnKey !== '') {
        this.saveDeclarationHandler(this.btnKey);
      } else {
        this.saveDeclaration();
      }
    },
    prepareParticipantData() {
      this.participant = {
        salutation: '1',
        title: '',
        phone: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        postbox: '',
        city: ''
      };

      let ownershipStructure = this.declarationData.property.ownershipStructure ? parseInt(this.declarationData.property.ownershipStructure) : 0;
      let arr = [5, 6, 7, 8, 9];
      if (this.declarationData.participant.id) {
        Object.assign(this.participant, this.declarationData.participant);
      } else if (this.declarationData.recipientType === 'none' || (this.declarationData.recipientType === 'other' && arr.includes(ownershipStructure))) {
        this.fillParticipantFromBranch();
      } else {
        this.selectReceiverType(this.declarationData.recipientType, true);
        Object.assign(this.participant, this.recipient);
        if (this.recipient.name !== '') {
          this.participant.firstName = this.recipient.name;
        }
      }
    },
    showParticipantDlg() {
      this.participantEditable = false;
      this.prepareParticipantData();
      this.showParticipant = true;
    },
    fillParticipantFromBranch() {
      if (this.declarationData.branch.phone) {
        this.participant.phone = this.declarationData.branch.phone;
      }
      if (this.declarationData.branch.streetInfo) {
        if (this.declarationData.branch.streetInfo.street) {
          this.participant.street = this.declarationData.branch.streetInfo.street;
        }
        if (this.declarationData.branch.streetInfo.houseNumber) {
          this.participant.houseNumber = this.declarationData.branch.streetInfo.houseNumber;
        }
      }
      if (this.declarationData.branch.zip) {
        this.participant.zip = this.declarationData.branch.zip;
      }
      if (this.declarationData.branch.city) {
        this.participant.city = this.declarationData.branch.city;
      }
      if (this.getCurrentUser().salutation_elster) {
        this.participant.salutation = this.getCurrentUser().salutation_elster;
      }
      if (['1', '2', '3'].includes(this.participant.salutation)) {
        if (this.getCurrentUser().account_fname) {
          this.participant.firstName = this.getCurrentUser().account_fname;
        }
        if (this.getCurrentUser().account_lname) {
          this.participant.lastName = this.getCurrentUser().account_lname;
        }
      } else if (this.getCurrentUser().company_name) {
        this.participant.firstName = this.getCurrentUser().company_name.slice(0, 25);
        this.participant.lastName = this.getCurrentUser().company_name.slice(25);
      }
    },
    closeParticipantDlg() {
      this.showParticipant = false;
    },
    editParticipant() {
      if (!this.isReadOnly) {
        this.participantEditable = true;
      }
    },
    saveParticipant() {
      this.errors.participant = this.resetErrors(this.errors.participant);
      if ((this.participant.salutation === "0" || this.participant.salutation === "")) {
        this.errors.participant.salutation.invalid = true;
        return;
      }
      if (this.participant.firstName.trim() === '') {
        this.errors.participant.firstName.invalid = true;
        return;
      }
      if (this.participant.zip.trim() === '') {
        this.errors.participant.zip.invalid = true;
        return;
      }
      if (this.participant.city.trim() === '') {
        this.errors.participant.city.invalid = true;
        return;
      }

      if ((this.participant.street === '' || this.participant.houseNumber === '') && this.participant.postbox === '') {
        this.$api.showToast(this.$t('declarations.street_pobox_error'), "error")
        return false;
      }

      this.participant.declarationId = this.declarationData.prim_uid;
      this.service.saveParticipation(this.participant).then((res) => {
        if (res) {
          this.showParticipant = false;
          if (res.participant) {
            this.declarationData.participant = res.participant;
          }
        }
      })
    },
    getDefaultAdministrativeAct() {
      if (this.propertyData === null) {
        return '';
      }

      // If property is LuF set default actId to LuF
      if (this.propertyData.step2.economicEntityType === '3') {
        return '272';
      }

      if (['2', '9'].includes(this.declarationData.property.federalStateUid)) {
        return '299';
      } else {
        return '271';
      }
    },
    regenerateCalculation(showInDialog = true) {
      this.$loading.show();
      let documentType = 'tax_calculation'

      const submissionIndex = parseInt(this.selectedTaxDocumentSwitchOption);

      if (showInDialog) {
        documentType = (submissionIndex < this.taxDocumentSwitchOptions.length - 1)
          ? 'tax_calculation'
          : 'tax_calculation_modified';
      }

      this.service.getTaxDocument(this.declarationUid, documentType, true).then((document) => {
        if (document.prim_uid || document.content) {
          if (showInDialog) {
            this.documentDownloadedCalculation = true;
            this.taxDocuments.taxCalculation.fileSource = 'data:' + document.mime_type + ';base64,' + document.content;
          }

          this.$api.showToast(this.$t('declarations.detail.tax_calculation.renew_doc.success'), 'success');
          this.triggerReload('declaration_documents');

          if (!showInDialog) {
            this.fetchTaxCalculation();
          }
        } else {
          this.handleTaxCalculationError(document.data.error_key, document.data.values);
        }
      }).finally(() => {
        this.$loading.hide()
      });
    },
    showAreaOfTheLandDataModal() {
      this.areaOfTheLandDataModalShow = true;
    },
    saveAreaOfTheLandData() {
      this.service.saveAreaOfTheLandData({
        'prim_uid': this.declarationData.prim_uid,
        'areaOfTheLandValue1': this.areaOfTheLandValue1,
        'areaOfTheLand1': this.areaOfTheLand1,
        'secondAreaInfoShow': this.secondAreaInfoShow,
        'areaOfTheLandValue2': this.areaOfTheLandValue2,
        'areaOfTheLand2': this.areaOfTheLand2,
        'thirdAreaInfoShow': this.thirdAreaInfoShow,
        'areaOfTheLandValue3': this.areaOfTheLandValue3,
        'areaOfTheLand3': this.areaOfTheLand3,
        'fourthAreaInfoShow': this.fourthAreaInfoShow,
        'areaOfTheLandValue4': this.areaOfTheLandValue4,
        'areaOfTheLand4': this.areaOfTheLand4
      }).then((res) => {
        if (res) {
          this.areaOfTheLandDataModalShow = false;
          this.regenerateCalculation(false);
        }
      })
    },

    showAssessmentRateModal() {
      this.showChangeAssessmentRateModal = true;
    },

    changeAssessmentRate(assessmentRate) {
      this.assessmentRate = assessmentRate;
      this.regenerateCalculation(false);
    },

    newDocumentUploaded() {
      this.fetchingDeadlineDocuments = true;
    },
    deadlineGridColClick(e) {
      if (e.field === 'filename') {
        this.openDocumentViewer(e.row.documentUid);
      } else if (e.row.id) {
        this.editDeadline(e.row.id);
      }
    },
    onDeadlineGridDataLoaded() {
      this.$nextTick(() => this.deadlineGridDataLoaded = true);
    },
    statusChanged(e) {
      if (this.deadlineGridDataLoaded) {
        if (e.row) {
          let postData = {
            deadlineId: e.row.id,
            status: e.row.status
          }
          this.$api.trigger('deadline/save', postData, true).then((response) => {
            if (response.data.success) {
              this.triggerReload(this.deadlineGridId)
            } else {
              this.$api.showToast(response.data.message, "error");
            }
          });
        }
      }
    },
    elsterMessageGridMenuClick(args) {
      switch (args.menuId) {
        case 'elsterMessage.add':
          this.openElsterMessageDlg();
          break;
      }
    },
    deadlineGridMenuClick(args) {
      switch (args.menuId) {
        case 'deadline.add':
          this.deadline = {
            "declarationId": this.declarationUid,
            "deadlineId": 0,
            "document": '',
            "document_type": "",
            "document_date": "",
            "announcement_date": "",
            "deadline_reminder": "",
            "deadline": ""
          }
          this.deadlineErrors = {
            document: false,
            document_type: false,
            document_date: false
          }
          this.deadlineAddEditDialog = true;
          if (this.fetchingDeadlineDocuments) {
            this.$api.trigger('document/list', {
              parentType: "DECLARATION",
              parentId: this.declarationUid,
              rows: -1
            }).then((response) => {
              if (response.data.success) {
                this.deadlineDocuments = this.$api.parse_object_data(response.data.records).map((o) => {
                  return {
                    "code": o.prim_uid,
                    "name": o.filename
                  }
                });
              }
              this.fetchingDeadlineDocuments = false;
            });
          }
          break;
        case 'deadline.edit':
          this.editDeadline(args.data[0].id);
          break;
      }
    },
    editDeadline(deadlineId) {
      this.$api.trigger('deadline/get', {
        uid: deadlineId
      }, true).then((response) => {
        if (response.data.success) {
          let documentIcon = '';
          switch (response.data.deadlineData.fileExt) {
            case "xls,xlsx":
              documentIcon = require('@/assets/document_xls.svg');
              break;
            case "doc,docx":
              documentIcon = require('@/assets/document_doc.svg');
              break;
            case "pdf":
              documentIcon = require('@/assets/document_pdf.svg');
              break;
            default:
              documentIcon = require('@/assets/document_generic.svg')
          }

          this.deadline = {
            "declarationId": this.declarationUid,
            "deadlineId": response.data.deadlineData.id,
            "document": '',
            "document_type": "",
            "document_icon": documentIcon,
            "document_name": response.data.deadlineData.filename,
            "document_type_label": response.data.deadlineData.documentType,
            "document_date": response.data.deadlineData.documentDate,
            "announcement_date": response.data.deadlineData.announcementDate,
            "deadline_reminder": response.data.deadlineData.deadlineReminder,
            "deadline": new Date(response.data.deadlineData.deadline)
          }
          this.deadlineErrors = {
            document: false,
            document_type: false,
            document_date: false
          }
          this.deadlineAddEditDialog = true;
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    toggleDeadlineCalculation(index) {
      this.deadline_calculation[index].expanded = !this.deadline_calculation[index].expanded;
    },
    calculateDeadlineDate(val, index, elName) {
      if (this.isOtherDeadlineDocumentTypeSelected) {
        return;
      }

      let date = null

      if (val !== '') {
        date = new Date(val);
      }

      if (date instanceof Date) {
        if (elName === 'document_date') {
          if (index === -1) {
            this.deadline.announcement_date = this.calculateAnnouncementDate(date, this.declarationData.property.federalStateUid, 3, true);
            this.deadline.deadline = this.calculateAnnouncementDate(_clone(this.deadline.announcement_date), this.declarationData.property.federalStateUid, '1M', true);
            this.deadline.deadline_reminder = this.calculateAnnouncementDate(_clone(this.deadline.deadline), this.declarationData.property.federalStateUid, -7, false);
          } else {
            this.deadline_calculation[index].announcement_date = this.calculateAnnouncementDate(date, this.declarationData.property.federalStateUid, 3, true);
            this.deadline_calculation[index].deadline = this.calculateAnnouncementDate(_clone(this.deadline_calculation[index].announcement_date), this.declarationData.property.federalStateUid, '1M', true);
            this.deadline_calculation[index].deadline_reminder = this.calculateAnnouncementDate(_clone(this.deadline_calculation[index].deadline), this.declarationData.property.federalStateUid, -7, false);
          }
        } else if (elName === 'announcement_date') {
          if (index === -1) {
            this.deadline.deadline = this.calculateAnnouncementDate(date, this.declarationData.property.federalStateUid, '1M', true);
            this.deadline.deadline_reminder = this.calculateAnnouncementDate(_clone(this.deadline.deadline), this.declarationData.property.federalStateUid, -7, false);
          } else {
            this.deadline_calculation[index].deadline = this.calculateAnnouncementDate(date, this.declarationData.property.federalStateUid, '1M', true);
            this.deadline_calculation[index].deadline_reminder = this.calculateAnnouncementDate(_clone(this.deadline_calculation[index].deadline), this.declarationData.property.federalStateUid, -7, false);
          }
        }
      }
    },
    saveDeadline() {
      this.deadlineErrors = {
        document: false,
        document_type: false,
        document_date: false
      }

      if (parseInt(this.deadline.deadlineId) === 0) {
        if (this.deadline.document === '' || parseInt(this.deadline.document) === 0) {
          this.deadlineErrors.document = true;
          return;
        }
        if (this.deadline.document_type === '') {
          this.deadlineErrors.document_type = true;
          return;
        }
      }
      if (this.deadline.document_date === '') {
        this.deadlineErrors.document_date = true;
        return;
      }

      this.$api.trigger('deadline/save', this.deadline, true).then((response) => {
        if (response.data.success) {
          this.deadlineAddEditDialog = false;
          this.triggerReload(this.deadlineGridId);
        } else {
          this.$api.showToast(response.data.message, "error");
        }
      });
    },
    closeDocumentViewerDialog() {
      this.documentViewer = {
        show: false,
        documentName: '',
        fileSource: '',
        documentId: 0
      }
    },
    downloadDocument() {
      this.documentService.setDefaultParams({
        "parentType": 'declaration',
        "parentId": this.declarationUid
      });
      this.documentService.download(this.documentViewer.documentId)
    },
    openDocumentViewer(documentId) {
      this.documentService.setDefaultParams({
        "parentType": 'declaration',
        "parentId": this.declarationUid
      });

      // fetch document
      this.documentService.get(documentId, true).then((doc) => {
        if (doc.mime_type) {
          this.documentViewer.documentName = doc.filename;
          this.documentViewer.fileSource = "data:" + doc.mime_type + ";base64," + doc.content;
          this.documentViewer.documentId = documentId;
          this.documentViewer.show = true;
        }
      });
    },
    isAreaModel: function () {
      return taxModels.filter(item => {
        return (['Flächen-Modell', 'Flächen-Faktor-Modell', 'Flächen-Lage-Modell', 'Wohnlagen-Modell'].includes(item.model))
      }).map((item) => item.stateCodes).flat().includes(this.federalStateUid)
    },
    //TODO: Extract this into a tax calculation component so that it can be reused wherever the calculation is triggered.
    handleTaxCalculationError(error_key, values) {
      const errors = [];

      if (error_key === 'no_land_value' && this.areaOfTheLandButtonShow) {
        error_key = 'no_land_value_HE_NI';
      }

      switch (error_key) {
        case 'no_community': {
          if (this.declarationData.calculationCommunity && this.tax_community === '') {
            this.tax_community = this.declarationData.calculationCommunity;
          }

          this.communityIsValidForTax = false;

          //This is not handled by the default modal, so we return early.
          return;
        }

        case 'elster_error': {
          if (!Array.isArray(values)) {
            values = Object.values(values);
          }
          errors.push(...values);
          break;
        }

        default: {
          let isPlural = false;
          if (values !== null &&
              typeof(values) !== 'undefined') {
            if (!Array.isArray(values)) {
              values = Object.values(values);
            }
            values.forEach(function(element, index, theArray) {
              if (index > 0 && typeof(element) === 'string') {
                theArray[index] = ' ' + element;
                isPlural = true;
              }
            });
          } else {
            values = [''];
          }
          const readable = this.$i18n.te(`declarations.tax_calculation.errors.${error_key}`)
              ? this.$i18n.tc(
                  'declarations.tax_calculation.errors.' + error_key,
                  isPlural === true ? 2 : 1,
                  {
                    taxModel: this.getTaxModel,
                    values: values
                  }
              )
              : this.$i18n.t('declarations.tax_calculation.errors.unknownError');

          errors.push({readable});
        }
      }

      this.taxCalculationErrors = errors;
      this.taxCalculationErrorKey = error_key;
      this.showTaxCalculationErrorModal = true;
    },

    closeTaxCalculationErrorModal() {
      this.showTaxCalculationErrorModal = false;
      this.taxCalculationErrorKey = '';
    },

    closeChangeAssessmentRateModal() {
      this.showChangeAssessmentRateModal = false;
    },

    closeElsterMessageAttachmentsModal() {
      this.showElsterMessageAttachmentsModal = false;
    },

    checkText(text = '') {
      if (
          (
            this.elsterMessage.subject.toLowerCase().includes('einspruch')
            || this.elsterMessage.subject.toLowerCase().includes('frist')
            || this.elsterMessage.justification.toLowerCase().includes('einspruch')
            || this.elsterMessage.justification.toLowerCase().includes('frist')
            || text.toLowerCase().includes('einspruch')
            || text.toLowerCase().includes('frist')
          )
          && this.elsterMessage.messageType === 'other'
      ) {
        this.showTextScanNotice = true;
      } else {
        this.showTextScanNotice = false;
      }
    },

    handleTabsOtherButtonClick(btn_key) {
      if (btn_key === 'downloadPropertyValuePdf') {
        this.downloadPropertyValuePdf();
      } else if (btn_key === 'openCommunitySelection') {
        this.openCommunitySelection();
      } else if (btn_key === 'calculatePropertyValue') {
        this.calculatePropertyValue(true, true, true);
      } else if (btn_key === 'considerExemptionInPropertyValueToggle') {
        this.considerExemptionInPropertyValueToggle();
      }
    },

    handleTabsMoreButtonClick(handler, index) {
      if (handler === 'declarationWorkflow' && typeof index !== 'undefined') {
        this.declarationWorkflow(index);
      }
    },

    selectCity(item) {
      if (item.code === "") {
        this.errors.recipient.city.invalid = true;
      } else {
        this.errors.recipient.city.invalid = false;
      }
    },
    searchZip() {
      this.zipSearch = true;
      if (this.recipient.city === '' && this.recipient.zip !== '') {
        this.cityList = [];
        this.cityLookup(this.recipient.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.recipient.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    selectCityParticipant(item) {
      if (item.code === '') {
        this.errors.participant.city.invalid = true;
      } else {
        this.errors.participant.city.invalid = false;
      }
    },
    searchZipParticipant() {
      this.zipSearch = true;
      if (this.participant.city === '' && this.participant.zip !== '') {
        this.cityList = [];
        this.cityLookup(this.participant.zip).then((cities) => {
          this.cityList = cities;
          if (cities.length === 1) {
            this.participant.city = cities[0].code;
          }
          this.zipSearch = false;
        });
      }
    },
    hideSecondAreaInfo() {
      this.secondAreaInfoShow = false;
      this.areaOfTheLand2 = '';
      this.areaOfTheLandValue2 = '';
    },
    showSecondAreaInfo() {
      this.areaOfTheLand2 = '';
      this.areaOfTheLandValue2 = '';
      this.secondAreaInfoShow = true;
    },
    hideThirdAreaInfo() {
      this.thirdAreaInfoShow = false;
      this.areaOfTheLand3 = '';
      this.areaOfTheLandValue3 = '';
    },
    showThirdAreaInfo() {
      this.areaOfTheLand3 = '';
      this.areaOfTheLandValue3 = '';
      this.thirdAreaInfoShow = true;
    },
    hideFourthAreaInfo() {
      this.fourthAreaInfoShow = false;
      this.areaOfTheLand4 = '';
      this.areaOfTheLandValue4 = '';
    },
    showFourthAreaInfo() {
      this.areaOfTheLand4 = '';
      this.areaOfTheLandValue4 = '';
      this.fourthAreaInfoShow = true;
    },
    taxCalculationScroll(elementId) {
      let element = document.getElementById(elementId);
      if (element !== null) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
    taxCalculationNavigationLoaded(navItems) {
      this.taxCalculationNavigationExcludedElements = [];

      if (!Array.isArray(navItems) || navItems.length === 0) {
        return;
      }

      navItems.forEach(navItem => {
        if (typeof navItem.parcels === 'undefined' || navItem.parcels.length === 0) {
          return;
        }

        navItem.parcels.forEach(parcel => {
          let element = document.getElementById(parcel.elementId);
          if (element === null) {
            this.taxCalculationNavigationExcludedElements.push(parcel.elementId);
            return;
          }
        });
      });
    },
    closeAppealTypeSelectionModal() {
      this.showAppealTypeSelectionModal = false;
    },
    savedAppealType() {
      this.closeCompareDocumentsDialog();
      this.startEditing();
    }
  }
}
</script>

<style scoped lang="scss">
.hover-underline:hover {
  text-decoration: underline;
}

.property_details_text {
  display: flex;
  grid-gap: 10px;
  padding-left: 62px;
}

.property_details_text .clientsection {
  padding-left: 0px;
}

.property_details_text .clientsection p {
  padding-bottom: 15px;
}

.property_details {
  padding: 20px 15px;
}


.box {
  height: 50px;
  padding: 2px 2px 2px 2px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.clientsection {
  float: right !important;
}

.clientdetaill {
  max-width: 100%;
  width: 100%;
}

.clientdetaill .clientsection {
  padding-left: 0px !important;
}

.clientdetaill .clientsection p {
  padding-left: 0;
}

.box1 {
  height: 55px;
  padding: 2px 2px 2px 2px;
  background-color: white;
  box-sizing: border-box;
  border-left-width: 1px;
  border-left-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
}

.date2 {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;

  color: theme('colors.muted_black');
  text-align: right;
  line-height: 25px;
  font-size: 15px;
}

label.dheader {
  position: absolute;
  top: 40px;
  font-weight: bold !important;
  //padding: 0 20px;
  max-width: 50%;
}

.extra_dtl_check {
  margin-left: -6px !important;
}

.tbox {
  border-left-width: 1px;
  border-left-color: #dfdfdf;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
  border-right-width: 1px;
  border-right-color: #dfdfdf;
}

.p1 {
  height: 42px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 50px;
  font-size: 15px;
}

.p2 {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 30px;
  font-size: 15px;
}

.p3 {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  font-weight: bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 30px;
  font-size: 15px;
}

.collapse .collapse-header {
  padding: 20px 20px 20px 40px;
  background: #f7f7f7;
  border-radius: 3px;
  /* position: relative; */
}

.map {
  border: 1px solid #797979;
  background-color: #ffffff;
  box-sizing: border-box;
}

th {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}

td {

  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
}

.floating {
  float: left;
  margin: 0px;
  padding-left: 8px;
}

.text-2 {
  background-color: rgba(255, 255, 255, 0);

  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
  font-size: 15px;
}

.text-to {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;

  font-size: 15px;


}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.line {
  color: #dfdfdf;

}

.rectangle {
  height: auto;
  padding-bottom: 15px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.id {
  height: 26px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #aeaeae;
  text-align: right;
  line-height: normal;
  font-size: 15px;
  z-index: 1;
  margin-top: -80px;
  //margin-right: 15px;
}

.search {
  @include segoe-regular;
  width: 100%;
  height: 34px;
  border: 2px solid #7a7a7a;
  background-color: transparent;
  box-sizing: border-box;
  color: white;
  text-align: left;

  background-image: url('~@/assets/search-green.svg');
  background-repeat: no-repeat;
  background-position: right;
  padding: 5px;
  background-origin: content-box;

  &:focus {
    border: 2px solid $primary;
  }
}

.icon-filter {
  height: 20px;
  width: 27px;
}

.text-filter {
  height: 26px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: normal;
}


.notification-bold-text {
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
}

.avatar-wrapper {
  width: 42px;
  height: 42px;
  padding: 20px;
}

.avatar {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.message {
  display: block;
  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 16px;

  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}


.name {
  width: 80%;
  @include segoe-semi-bold;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 2px;
}

.date {

  background-color: rgba(255, 255, 255, 0);
  @include segoe-regular;
  color: #aeaeae;
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
}

.chip-container {

  //border: 2px solid #7a7a7a;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .chip {
    margin: 4px;

    padding-right: 5px;
    display: flex;
    align-items: center;


    height: 22px;
    border-radius: 15px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: 'Arial Regular', 'Arial', sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;

    img {
      cursor: pointer;
      margin-left: 8px;
      font-size: 12px;
      font-style: unset;

      width: 8px;
      height: 8px;
      box-sizing: border-box;
      font-family: 'Arial Regular', 'Arial', sans-serif;
      color: #333333;
      text-align: center;
      line-height: normal;
    }
  }

  .chip-logo {

    height: 13px;
    width: 13px;
    @include segoe-semi-bold;
    font-size: 8px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 2px;
  }

  .chip-text {


    @include segoe-regular;
    color: #7b7c7c;
    font-size: 12px;

  }

  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}

.dialog-title {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.vollmach {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.radio-button {
  text-align: left;
}

textarea.text-area {
  width: 650px;
  height: 160px;
  padding: 2px 2px 2px 2px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
}

.home-gray {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.map-icon-text a {
  color: #333;
  padding: 10px;
}

.map-icon-text img {
  max-width: 200px;
}

.home-gray-text img {
  max-width: 90px;
}

.mandant_section_inner p {
  padding: 10px;
}

.mandant_section .mandant_section_inner {
  width: 50%;
  padding: 20px 5px;
}

.mandant-title {
  padding: 10px 0px 10px 5px;

}

.einzel_field label.label-title {
  width: 40%;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  text-align: left;
  font-size: 15px;
}

.einzel_field {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  justify-content: space-between;
  min-height: 47px;
}

.einzel_field label + div {
  width: 60%;
}

.row-client-data {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  grid-gap: 20px;
  min-height: 47px;
}

.tp-space {
  padding: 15px;
}

label.vnt-checkbox.mt-4.check, label.vnt-checkbox.check {
  margin: 0 auto 0 0;
}

.einzel_field label.vnt-checkbox span.vnt-checkbox__text {
  font-size: 0;
}

.vnt-checkbox__text {
  font-size: 0;
}

.rohertrag-table {
  padding: 20px 10px;
  max-width: 650px;
  margin: 0 auto;
}

.community_modal {

  height: 400px;

}

.rohertrag-table table td.right-align-text {
  text-align: right;
}

.rohertrag-table table tr, .rohertrag-table table tr td {
  border: 0 !important;
  padding: 10px 20px;
}

.gray-color h3 {
  font-size: 20px;
  font-weight: 600;
  color: theme('colors.muted_black');
  padding-top: 15px;
}

.gray-color {
  text-align: center;
  background: #f2f2f2;
  padding: 12px;
}

.justify-center-text img {
  margin: 0 auto 20px auto;
}

.achtung {
  font-weight: 600;
}

ul.ul-list {
  padding: 0;
  margin: 20px 0 0 20px;
}

ul.ul-list li {
  font-size: 15px;
  margin-bottom: 8px;
  list-style: disc;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: left;
}

.rect {

  height: auto;
  padding: 10px;
  border: 2px solid #7a7a7a;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-left: 5px;
}

.textbold {

  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 15px;
}

.text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  //  margin-bottom: 15px;
}

input[type=radio] {
  border: 0px;
  width: 2em;
  height: 25px;
  background: #004c97;
}

.textradio {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
}

.text-h {
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: bold;
  color: #333333;
  text-align: left;
  line-height: 25px;
  font-size: 15px;
}

.long-box {
  width: 649px;
  padding: 20px;
  border: 1px solid #dfdfdf;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;

  .header {
    font-size: 15px;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    color: theme('colors.muted_black');
    text-align: left;
  }

  .main-label {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 500;
    color: theme('colors.muted_black');
    text-align: left;
    font-size: 18px;
  }

  .sub-label {
    font-family: 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    font-size: 15px;
  }

  .box2 {
    width: 95%;
    height: 105px;
    padding: 2px 2px 2px 2px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    margin: 15px;
  }
}

.text-address {
  font-family: 'Segoe UI Semibold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 600;
  color: theme('colors.muted_black');
  font-size: 18px;
}

.backdrop_class {
  z-index: 1001;
}

@media only screen and (min-width: 1220px) and (max-width: 1650px) {
  .clientsection[data-v-1b950b84] {
    width: 50%;
  }
}

.link {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

.info {
  padding: 5px 0;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}

.no-bottom {
  margin-bottom: 0px;
}

.declaration-buttons {
  width: auto;

  ::v-deep .button {
    padding: 0 15px;
  }
}

.elster-result-header {
  p {
    text-align: center;
  }
}

.elster-result-pdf {
  height: 70vh;
}

.loading-container {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 9999;
}

.spin {
  margin: auto;
  position: relative;
}

.document-review-dialog ::v-deep .modal-x-large {
  width: 95% !important;
  max-width: 95% !important;
  max-height: calc(100vh - 20px) !important;
}

.left-document, .right-document {
  overflow-y: scroll;
  height: 100vh;
}

.pdf-icon img {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.pdf-icon {
  position: absolute;
  right: 0;
  top: -20px;

  img {
    float: left;
    margin-left: 10px;
  }
}

.archived-ribbon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  .text {
    transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);

    transform-origin: -20px -40px;
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 30px;
  }
}

.help_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
}

.line_document {
  margin-top: 10px;
  width: 100%;
  height: .5px;
  border: .5px solid #797979;
  background-color: #797979;
  box-sizing: border-box;
}

.sub_text {
  font-size: 14px;
  font-family: 'Segoe UI', sans-serif;
  color: #626262;
  text-align: center;
  line-height: 21px;
}

.declink {
  color: #626262;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.doc_title {
  font-size: 15px;
  font-family: 'Segoe UI', sans-serif;
  text-decoration: underline;
  color: #242424;
  text-align: center;
  line-height: normal;
}

.pdf_image {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin-right: 10px;
}

.green-spinner {
  .pi-spinner {
    color: $primary;
  }
}

.w-full ::v-deep .p-chips-multiple-container {
  width: 100%
}

.text-heading {
  font-size: 18px;
  font-weight: normal;
}

.approver_email {
  border: 2px solid $primary;
  padding: 10px;
  min-height: 55px;
}

.error-text-red {
  color: #a4262c;
  font-size: 12px;
}

.dialog_left_side_buttons {
  display: flex;

  .flex-col {
    padding-left: 16px;
  }

  @media screen and (min-width: 1100px) {
    margin-right: calc(100% - 76em);
  }
}

.document-switch {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding-bottom: 10px;
}

.icon-expand-collapse {
  width: 6px;
  height: 10px;
}

.icon-expand-collapse.rotate {
  transform: rotate(90deg);
}

.row-icon {
  height: 1rem;
  width: auto;
}

.propertyValueMenu {
  width: 300px;
  height: auto;
  //bottom: 70px;
  //top: 150px;
  margin-left: 30px;
  text-align: center;
  padding: 2px 2px 2px 2px;
  border-radius: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  display: block;
  position: absolute;
  z-index: 99;
}

.scan-notice-header {
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 10px;
}

</style>

<!-- This is unscoped, deep or >>> is not working with the used pipeline -->
<style lang="scss">
.checkbox-text-row .container {
  label {
    margin-left: 0.5rem;
  }
}

.elster-message-attachments-upload {
  .drop {
    height: 320px;
  }
}

.info {
  padding: 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-gap-12 {
  gap: 12px;
}

@media(min-width: 1px) and (max-width: 1470px) {
  .id {
    display:none;
  }
}

.arrows {
  overflow-x: auto;
}

</style>
