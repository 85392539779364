<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1', width: 'auto', height: 'auto'}"
  >
    <template #header>
      <h3 class="text-xl font-bold text-left">
        {{ $t('declarations.detail.elster_validation.title') + elsterValidationPropertyUidTitle }}
      </h3>
    </template>

    <div class="mb-8 mt-8">
      <div class="flex justify-around space-x-1 mt-2">
        <ElsterValidationResults
          :visible="isVisible"
          :elsterValidationResultsList="elsterValidationResultsList"
          :federalStateCode="federalStateCode"
        />
      </div>
    </div>

    <template v-slot:footer>
      <div class="flex flex-row-reverse">
        <Button
          @click="handleClose"
          :text="$t('buttons.ok')"
          class="w-44"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import ElsterValidationResults from '@/components/Elster/ElsterValidationResults';

export default {
  name: 'ElsterValidationResultsModal',
  emits: ['close'],
  components: {
    Dialog,
    ElsterValidationResults
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    elsterValidationResultsList: {
      type: Array,
      default: () => []
    },
    federalStateCode: {
      type: String,
      default: ''
    },
    elsterValidationPropertyUid: {
      type: String,
      default: ''
    }
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        this.$emit('close', value);
      }
    },
    elsterValidationPropertyUidTitle() {
      if (this.elsterValidationPropertyUid.length > 0){
        return ' - ' + this.elsterValidationPropertyUid;
      }

      return '';
    }
  },
  methods: {
    handleClose() {
      this.isVisible = false;
    },
  }
};
</script>

<style scoped lang="scss">

</style>
