<template>
  <div>
    <div class="flex w-full h-screen">
      <div class=" flex justify-center items-center w-1/2 bg-white">
        <div class="flex flex-col items-center w-4/5  justify-center ">
          <img class=" mb-10 mt-20" src="../assets/logo.svg">
          <div class=" px-5 w-full">
            <div class="InputTextBox">
              <label class="label">{{ $t('forgotPassword.reset_password_info') }} </label>

              <InputText v-model="password" :isError="isPasswordError" :errorMessage="passwordError" type="password"
                         :isRequired="true" :label="$t('login.password')"
                         :disabled="verifying_code"
                         :placeholder="$t('login.passwordPlaceholder')"
                         @keyup="submit"
                         class="mt-2 mb-4"/>
              <InputText v-model="confirm_password" :isError="isConfirmPasswordError"
                         @input="resetPasswordConfirmError"
                         :errorMessage="confirmPasswordError" type="password"
                         :label="$t('forgotPassword.retype_password')" :isRequired="true"
                         :disabled="verifying_code"
                         @keyup="submit"
                         :placeholder="$t('forgotPassword.retype_password')"/>

              <Button @click="submit" :fullwidth="true" class="w-full mt-6" :text="$t('general.submit') ">
              </Button>
              <div class="mt-5">
                <span class="label__link ml-1" @click="goToLogin">{{ $t('forgotPassword.signup') }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <img class="w-1/2" src="@/assets/login_illustration.png"/>
    </div>

  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",
  mixins: [validationMixin],
  data() {
    return {
      verifying_code: true,
      code: '',
      password: '',
      confirm_password: '',
      isPasswordError: false,
      passwordError: '',
      isConfirmPasswordError: false,
      confirmPasswordError: '',
    }
  },
  validations: {
    password: {
      required
    },
    confirm_password: {
      required, sameAsPassword: sameAs('password')
    }
  },
  created() {
    let code = this.$route.query.code;
    if(typeof(code) != 'undefined' && code !== '') {
      this.$api.trigger('reset_password', {
        'code': code,
        'validate_code': 1
      }, true).then((response) => {
        if(response.data.success) {
          this.code = code;
          this.verifying_code = false;
        } else {
          this.$toast.error(response.data.message);
          this.goToLogin();
        }
      })
    } else {
      this.$toast.error(this.$t('forgotPassword.code_missing'));
      this.goToLogin();
    }
  },
  methods: {
    goToLogin() {
      this.$router.push('/');
    },
    resetPasswordConfirmError() {
      this.isConfirmPasswordError = false;
      this.confirmPasswordError = '';
    },
    submit() {
      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {

        this.isPasswordError = this.$v.password.$error
        this.passwordError = this.$v.password.$error ? this.$t('login.passwordError') : ""

        this.isConfirmPasswordError = this.$v.confirm_password.$error
        this.confirmPasswordError = this.$v.confirm_password.$error ? this.$t('forgotPassword.password_mismatch') : '';

        return; // if any of the validations fail, we don't move on
      }

      this.$api.trigger('reset_password', {
        'code': this.code,
        'password': this.$api.encrypt(this.password),
        'confirm_password': this.$api.encrypt(this.confirm_password)
      }, true).then((response) => {
        if(response.data.success) {
          this.$toast.success(this.$t('forgotPassword.password_changed'))
        } else {
          this.$toast.error(response.data.message);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.label{
  @include segoe-regular;
  &__link{
    color:$primary;
    cursor: pointer;
  }
}


</style>
