<template>
  <Dialog
    class="attachment-pdf-modal"
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{flex: '1', width: 'auto', height: 'auto'}"
  >
    <template #header>
      <h3 class="text-xl font-bold text-left">
        {{ viewFileName }}
      </h3>
    </template>

    <div>
      <div
        v-if="viewFileUrl != ''"
        class="h-full content-center"
      >
        <vue-pdf-embed :source="sourceObjectForPdfjs(viewFileUrl)"/>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

import {pdfjsCVEMixin} from '@/core/utils/PdfjsCVEMixin';

import { DeclarationService } from '@/services/declaration.service';

export default {
  name: 'elsterMessageAttachmentDetailsModal',
  emits: ['close'],
  components: {
    VuePdfEmbed,
    Dialog
  },
  mixins: [pdfjsCVEMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    viewFileData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      declarationService: new DeclarationService(),
      viewFileName: '',
      viewFileUrl: ''
    }
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        this.$emit('close', value);
      }
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.show();
        return;
      }

      this.viewFileClose();
    },
  },
  methods: {
    show() {
      this.viewFile(this.viewFileData);
    },
    async viewFile(file) {
      if (file instanceof File) {
        this.viewFileUrl = await this.getFileUrl(file);
        this.viewFileName = file.name;
        return;
      }

      this.declarationService.getFileContentFromS3(
          {
            parentId: file.parentId,
            recordId: file.recordId,
            fileKey: file.uuid
          }
      ).then(async (response) => {
        if (response.data.success) {
          let fileContent = response.data.fileContent;
          let mimeType = 'application/pdf';

          this.viewFileUrl = this.getFileUrlWithContent(fileContent, mimeType);
          this.viewFileName = file.name;
        }
      });
    },
    async getFileUrl(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    viewFileClose() {
      this.viewFileUrl = '';
      this.viewFileName = '';
    },
    getFileUrlWithContent(fileContent, mimeType) {
      return 'data:' + mimeType + ';base64,' + fileContent;
    },
  }
};
</script>

<style scoped lang="scss">
.attachment-pdf-modal :deep(.p-dialog) {
  height: 95vh;
  max-width: 1200px;
  width: 80vw;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;

    & > div {
      height: 100%;
    }
  }
}
</style>
