export const userPermissions = {
    "default": [
        "home",
        "help",
        "changelog",
        "communication",
        "deadline",
        "market_value_assessment",
    ],
    "administrator": [
        "declaration",
        "clients",
        "documents",
        "properties",
        "powers_of_attorney",
        "reports",
        "protocol",
        "system_settings",
        "user_management",
        "billing",
        "dms",
        "dmstransfer",
        "datevggw",
        "addisonggw",
        "change_management",
        "list_data_query",
    ],
    "user": [
        "declaration",
        "clients",
        "properties",
        "powers_of_attorney",
        "reports",
        "dmstransfer",
        "documents"
    ],
    "it_administrator": [
        "user_management",
        "system_settings",
        "protocol",
        "dmstransfer",
        "documents"
    ]
};

export const actionsWoutSessionCheck = ["/", "/register", "/resetPassword", "/404", "/linkDatevDevice", "/datevLoginRecovery"];
export const routesWoutSessionCheck = ["Login", "Register", "ResetPassword", "ForgotPassword", "404", "LinkDatevDevice", "datevLoginRecovery"];
