<template>
  <div class="">
    <div class="flex justify-between space-x-8">
    <div class="space-y-4 min-w-50">
      <div class="flex justify-around space-x-8 mt-3">
        <InputText class="mt-2 w-full" :isError="errors.declarationNumber.invalid"
                   :errorMessage="$t('general.errors.required')"
                   :label="$t('declarations.add_declaration.steps.step2.declaration_number')"
                   v-model="step2.declarationNumber"
                   :showHelpIconPopover="true"
                   :popoverTexts="popoverTexts_declaration_number"
                   :popoverLink="popoverLink_declaration_number"
                   :maxLimit="30"
                   placeholder=""/>
      </div>
      <div class="flex justify-around space-x-8 ">
        <InputSelect class="mt-2 w-full" :isError="errors.type.invalid" :errorMessage="$t('general.errors.required')"
                     :label="$t('declarations.add_declaration.steps.step2.type')" :isRequired="true"
                     :options="declaration_type_options"
                     v-model="step2.type"
                     :selected-value="step2.type"
                     :showHelpIconPopover="true"
                     :popoverTexts="popoverTexts_type"
                     :popoverLink="popoverLink_type"
                     :placeholder="$t('general.please_select')"/>
      </div>

      <div class="flex justify-around space-x-8 ">
        <InputSelect class="mt-2 w-full" :isError="errors.referenceDate.invalid" :errorMessage="$t('general.errors.required')"
                     :label="$t('declarations.add_declaration.steps.step2.reference_date')" :isRequired="true"
                     :options="declaration_reference_date_options"
                     v-model="step2.referenceDate"
                     :selected-value="step2.referenceDate"
                     :placeholder="$t('general.please_select')"/>
      </div>

      <div class="mt-2 w-full mt-2">
        <div class="flex flex-col justify-start items-start mt-5 mb-8">
          <label class="label-chk mb-2 ">{{ $t('declarations.add_declaration.steps.step2.extra_details') }}</label>
          <InputCheck label="" @check="showExtraDetails"/>
        </div>
      </div>
      <div class="flex justify-around space-x-8 mt-10" v-show="canShow">
        <InputText
          class="mt-2 w-full"
          :isMultiline="true"
          :label="$t('declarations.add_declaration.steps.step2.declaration_extra_details')"
          bodyClass="non-resize-textarea w-full"
          v-model="step2.extraDetails"
          :maxLimit="999"
          :errorMessage="$t('general.errors.limitDesc').replace('%length%', step2.extraDetails.length).replace('%maxLimit%', 999)"
          placeholder=""
        />
        <div class="mt-2 w-full invisible "/>
      </div>
    </div>
    <div class="space-y-4" v-show="step2.is_declaration_without_submission === '1'">
      <div class="flex justify-end space-x-8 mt-6">
        <div class="flex">
          <div class="info flex justify-start items-center">
            <img class="info__icon" src="@/assets/info_gray.svg"/>
            <label class="info__text mr-5" v-html="$t('declarations.add_declaration.steps.step2.information_about_is_declaration_without_submission')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="space-y-4">
      <div class="flex justify-end space-x-8 mb-10 ">
        <div class="flex mt-5   ">
          <div class="flex justify-end  mt-6 space-x-4 ">
            <Button class="w-56" :text="$t('buttons.back')" :secondary="true" @click="goBack()"/>
            <Button class="w-56" :text="$t('buttons.next')" @click="submitNext"/>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import {PropertyService} from "@/services/property.service";

export default {
  name: "Step2",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      propertyService: new PropertyService(),
      popoverTexts_declaration_number: [
        this.$t('declarations.add_declaration.steps.step2.tooltip.type'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverTexts_type: [
        this.$t('declarations.add_declaration.steps.step2.tooltip.declaration_number'),
        this.$t('properties.step3.tooltip.tooltip_client_text2')
      ],
      popoverLink_type: "https://hilfe.grundsteuer-digital.de/faq/grund-der-feststellung/",
      popoverLink_declaration_number: "https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/",
      isModalVisible: false,
      canShow: false,
      declaration_type_options: [],
      declaration_reference_date_options: [],
      federalStateUid: '',
      step2: {
        declarationNumber: "",
        type: '1',
        referenceDate: '0',
        extraDetails: '',
        is_declaration_without_submission: '0',
        status: 'draft'
      },
      errors: {
        "declarationNumber": {
          invalid: false
        },
        "type": {
          invalid: false
        },
        "referenceDate": {
          invalid: false
        }
      },
    }
  },
  validations: {
    step2: {
      type: {
        required
      },
      referenceDate: {
        required
      }
    }
  },
  mounted() {
    let declaration_reference_date_options = [];
    for (var i = 2022; i <= 2050; i++) {
      declaration_reference_date_options.push({
        'name': i,
        'code': i
      });
    }
    this.declaration_reference_date_options = declaration_reference_date_options;
  },
  watch: {
    '$store.state.declaration.data.declarationNumber'(newValue, oldValue) {
      if(newValue && newValue !== oldValue && this.step2.declarationNumber === '') {
        this.step2.declarationNumber = newValue
      }
      this.checkDeclarationType();
      this.initDeclarationType();
    },
    '$store.state.declaration.data.extraDetails'(newValue, oldValue) {
      if(newValue && newValue !== oldValue && this.step2.extraDetails === '') {
        this.step2.extraDetails = newValue
      }
    },
    '$store.state.declaration.declarationRelevantMarkedByUser'(newValue, oldValue) {
      this.initDeclarationType();
    },
    'step2.type'(newValue, oldValue) {
      if (newValue === '1001') {
        this.step2.is_declaration_without_submission = '1';
      } else {
        this.step2.is_declaration_without_submission = '0';
      }
    }
  },
  computed: {
    ...mapGetters("property", ["getCurrentTaxModel"]),
    ...mapGetters("declaration", ["getSteps", "getCurrentClientId", "getCurrentPropertyId", "getFederalStateId", "getDeclarationRelevantMarkedByUser", "getNextDeclarationType", "getNextDeclarationReferenceDate"])
  },
  methods: {
    ...mapMutations("declaration", ["saveSteps"]),
    showExtraDetails() {
      this.canShow = !this.canShow
    },
    submitNext() {
      this.errors = this.resetErrors(this.errors);

      this.$v.$touch() // we check the form to see if it's valid
      if (this.$v.$invalid) {
        for (let x in this.$v.step2) {
          if(this.$v.step2[x].$error) {
            this.errors[x].invalid = this.$v.step2[x].$error;
          }
        }
        return;
      }

      if (this.step2.type === "0" || this.step2.type === "") {
        this.errors.type.invalid = true
        return;
      }

      if (this.step2.referenceDate === "0" || this.step2.referenceDate === "") {
        this.errors.referenceDate.invalid = true
        return;
      }

      if (this.step2.declarationNumber.length > 30) {
        this.errors.declarationNumber.invalid = true;
        return;
      } else {
        this.errors.declarationNumber.invalid = false;
      }

      if (this.step2.extraDetails.length > 999) {
        return;
      }

      this.saveSteps(this.step2);
      this.$emit("goToNextStep");
    },
    checkDeclarationType() {
      if(this.getCurrentPropertyId && parseInt(this.getCurrentPropertyId) > 0) {
        this.propertyService.get(this.getCurrentPropertyId).then((data) => {
          if (data.id) {
            let federalStateid = data.step2.federalStateUid;
            if (federalStateid) {
              if (federalStateid == 6 || federalStateid == 2 || federalStateid == 9) {
                //types_bayern
                this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_bayern');
              } else if (federalStateid == 1) {
                //types_baden
                this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_baden');
              } else if (federalStateid == 7) {
                //types_hessen
                this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_hessen');
              } else {
                this.declaration_type_options = this.$t('declarations.add_declaration.type_options.types_all');
              }

              this.declaration_type_options =  this.declaration_type_options.filter(
                (value) => value.code !== '1002' // Zurechnungsfortschreibung
              );
            }
          }
        });
      }
    },
    handleModalClose() {
      this.isModalVisible = false
      this.setSecondStepData(this.step2)
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("goBack");
    },

    initDeclarationType() {
      if (parseInt(this.getDeclarationRelevantMarkedByUser)) {
        if (this.getNextDeclarationType === '') {
          this.step2.type = '1';
        } else {
          this.step2.type = this.getNextDeclarationType;
        }
      } else {
        this.step2.type = '1';
      }

      if (this.step2.type === '1') {
        this.step2.referenceDate = '2022';
      } else {
        this.step2.referenceDate = '2023';
        if (this.getNextDeclarationReferenceDate !== '') {
          this.step2.referenceDate = this.getNextDeclarationReferenceDate;
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">


.label {
  @include segoe-regular;
  color: white;

  &__link {
    color: $primary;
  }
}

.dtext {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 20px;
}

.dheader {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-weight: 700;
  color: theme('colors.muted_black');
  text-align: left;
  line-height: 25px;
  font-size: 20px;
}

.warning {
  width: 58px;
  height: 52px;
  box-sizing: border-box;
}

.info {
  padding: 5px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 20px;

  &__icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__text {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
    color: theme('colors.muted_black');
    text-align: left;
    line-height: 20px;
    font-size: 14px
  }
}
.min-w-50 {
  min-width: 50%;
}
</style>
