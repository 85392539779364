<template>
    <div class="main flex border-right" :class="disabled?'no-pointer':'pointer'" @click="handleClick($event)" v-show="canShow">
      <div
        class="flex items-center relative"
        :class="disabled ? 'disabled_menu' : 'pointer'"
        :id="'user-menu-wrapper-' + _uid"
      >
        <img
          class="menu-icon"
          :class="disabled ? 'no-pointer menu_disable' : 'pointer'"
          :src="getIcon()"
        />

        <label
          class="menu-label"
          :class="disabled ? 'no-pointer' : 'pointer'"
        >
          {{ title }}
        </label>

        <Menu
          ref="refActionMenu"
          :model="actionMenuItems"
          :popup="true"
          :appendTo="'user-menu-wrapper-' + _uid"
        />
      </div>
    </div>


</template>

<script>
import Menu from 'primevue/menu';

export default {
  name: "UserMenu",
  components: {
    Menu
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    disabledIcon: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canShow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    sub_menu: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      actionMenuItems: [],
    }
  },
  methods: {
    handleClick(event) {
      if(this.disabled) {
        return ;
      }

      this.actionMenuItems = [];
      if (this.sub_menu) {
        this.sub_menu.items = this.sub_menu.items.filter((o) => {
          return !(typeof o.visible !== 'undefined' && o.visible === false);
        });

        this.actionMenuItems = this.sub_menu.items.map(o => {
          o.icon += ' grid_action_icon';
          o.command = () => {
            this.$emit('submenu-click', o.id)
          }
          return o;
        });
        if(this.$refs.refActionMenu) {
          this.$refs.refActionMenu.toggle(event);
        }
      } else {
        this.$emit('click', this.slug)
      }
    },
    getIcon() {
      return this.disabled ? this.disabledIcon : this.icon;
    }
  }
}
</script>

<style scoped lang="scss">
.p-menu.p-menu-overlay {
  min-width: 200px;
  width: auto !important;
  top: 40px !important;
  left: 0px !important;

  .p-menuitem-text {
    white-space: nowrap;
  }
}

.menu-icon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: #333333;
  text-align: center;
  line-height: normal;
  cursor: pointer;
  margin-left:10px
}

.disabled_menu label {
  color: #b0b0b0;
  cursor: default;
}

.menu-label {
  font-family: 'Segoe UI', sans-serif;
  color: theme('colors.muted_black');
  font-size: 15px;
  text-align: left;
  margin-left: 8px;
  //cursor: pointer;
  word-wrap: inherit;
  margin-right: 10px;
}

.user_submenus {
  z-index: 99;
}

.no-pointer {
  cursor: default;
}

.pointer {
  cursor: pointer;
}
.border-right{
  border-right:1px solid #d7d7d7;
  box-sizing: border-box;
}
.border-right:first-child{
   margin-left:-10px
}
.border-right:last-child{
  border-right:0;
}

</style>
