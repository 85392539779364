import {i18n} from '@/plugins/i18n';

export const available_reports = [
    {
        "id": "CLIENT_WITHOUT_PROPERTY",
        "type": "LIST",
        "account_type": "tax_consultant",
        "default_sort_attr": "client_id",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": true,
        "columns": [
            {
                field: 'client_id',
                header: i18n.t('clients.attrs.id'),
                width: 200,
                clickable: true
            }, {
                field: 'client_type_icon',
                exportField: 'client_type',
                header: i18n.t('clients.attrs.type'),
                image: true,
                tooltip: (row) => {
                    return row.client_type_lbl ? row.client_type_lbl : '';
                },
                bodyClass: 'client_type',
                headerClass: 'text-center-imp',
                width: 150
            }, {
                field: 'client_name',
                header: i18n.t('clients.attrs.name'),
                autoWidth: true,
                clickable: true
            }, {
                field: 'status',
                header: i18n.t('clients.attrs.status'),
                width: 200
            }
        ]
    },
    {
        "id": "PENDING_DECLARATION_APPROVAL",
        "type": "LIST",
        "account_type": "tax_consultant",
        "default_sort_attr": "uid",
        "default_sort_order": 1,
        "show_filters": true,
        "show_search": false,
        "columns": [
            {
                field: 'uid',
                width: 200,
                header: i18n.t('declarations.grid.id'),
                clickable: true
            }, {
                field: 'client_name',
                header: i18n.t('declarations.grid.client'),
                clickable: true,
                width: 300,
            }, {
                field: 'property_name',
                header: i18n.t('declarations.grid.address'),
                autoWidth: true,
                clickable: true
            }, {
                field: 'assigned_user_name',
                header: i18n.t('declarations.grid.assigned_user'),
                width: 200,
                sortable: false
            }
        ]
    },
    {
        "id": "CLIENT_INVITATION_PENDING",
        "type": "LIST",
        "account_type": "tax_consultant",
        "default_sort_attr": "client_id",
        "show_search": false,
        "show_filters": true,
        "default_sort_order": 1,
        "columns": [
            {
                field: 'client_id',
                header: i18n.t('clients.attrs.id'),
                width: 200,
                clickable: true
            }, {
                field: 'client_type_icon',
                exportField: 'client_type',
                header: i18n.t('clients.attrs.type'),
                image: true,
                tooltip: (row) => {
                    return row.client_type_lbl ? row.client_type_lbl : '';
                },
                bodyClass: 'client_type',
                headerClass: 'text-center-imp',
                width: 150
            }, {
                field: 'client_name',
                header: i18n.t('clients.attrs.name'),
                autoWidth: true,
                clickable: true
            }, {
                field: 'status',
                header: i18n.t('clients.attrs.status'),
                width: 200
            }
        ]
    },
    {
        "id": "PROPERTY_WITHOUT_DECLARATION",
        "type": "LIST",
        "default_sort_attr": "uid",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": true,
        "columns": [
            {
                field: 'uid',
                header: i18n.t('components.properties_table_col.col1'),
                clickable: true
            }, {
                field: 'client_name',
                header: i18n.t('clients.attrs.name'),
                clickable: true
            }, {
                field: 'street',
                header: i18n.t('components.properties_table_col.col3')
            }, {
                field: 'zip',
                header: i18n.t('components.properties_table_col.col6')
            }, {
                field: 'city',
                header: i18n.t('components.properties_table_col.col4')
            }, {
                field: 'federal_state_name',
                header: i18n.t('components.properties_table_col.col5')
            }
        ]
    },
    {
        "id": "CLIENT_BY_CLIENT_TYPE",
        "type": "CHART",
        "chart_type": "pie",
        "account_type": "tax_consultant",
        "export_attributes": ["client_type", "count"]
    },
    {
        "id": "PROPERTY_BY_CLIENT",
        "type": "LIST",
        "account_type": "tax_consultant",
        "default_sort_attr": "client_id",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": true,
        "columns": [
            {
                field: 'client_id',
                header: i18n.t('clients.attrs.id'),
                clickable: true
            }, {
                field: 'client_name',
                header: i18n.t('clients.attrs.name'),
                clickable: true
            }, {
                field: 'count',
                header: i18n.t('general.count')
            }
        ]
    },
    {
        "id": "PROPERTY_BY_STATE",
        "type": "LIST",
        "default_sort_attr": "state",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": false,
        "columns": [
            {
                field: 'name',
                header: i18n.t('components.properties_table_col.col5')
            }, {
                field: 'count',
                header: i18n.t('general.count')
            }
        ]
    },
    {
        "id": "OVERVIEW_OF_PROCESSING_STATUS",
        "type": "LIST",
        "show_search": false,
        "show_filters": true,
        "columns": [
            {
                field: 'name',
                header: i18n.t('components.reports_table1_col.designation'),
                sortable: false
            }, {
                field: 'count',
                header: i18n.t('general.count'),
                sortable: false
            }
        ]
    },
    {
        "id": "DECLARATION_BY_STAGE",
        "type": "CHART",
        "chart_type": "pie",
        "export_attributes": ["status", "count"]
    },
    {
        "id": "SUBMITTED_DECLARATIONS_OF_DETERMINATION_WITHOUT_ACCOUNTING",
        "type": "LIST",
        "account_type": "tax_consultant",
        "default_sort_attr": "uid",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": false,
        "columns": [
            {
                field: 'uid',
                width: 200,
                header: i18n.t('declarations.grid.id'),
                clickable: true,
            }, {
                field: 'client_id',
                header: i18n.t('declarations.grid.client_uid'),
                clickable: true,
                width: 250
            }, {
                field: 'client_name',
                width: 300,
                header: i18n.t('declarations.grid.client'),
                filterLbl: i18n.t('clients.attrs.name') + ' ' + i18n.t('general.contains'),
                clickable: true,
            }, {
                field: 'property_name',
                width: 300,
                header: i18n.t('declarations.grid.address'),
                filterLbl: i18n.t('declarations.grid.address') + ' ' + i18n.t('general.contains'),
                clickable: true,
            }, {
                field: 'declaration_number',
                width: 200,
                header: i18n.t('declarations.grid.declaration_number'),
                sortable: false
            }, {
                field: 'assigned_user_name',
                width: 200,
                header: i18n.t('declarations.grid.assigned_user'),
                clickable: true,
            }, {
                field: 'property_value',
                width: 200,
                header: i18n.t('declarations.detail.tab1.property_value'),
                clickable: true,
                sortable: false
            }, {
                field: 'elster_transferred',
                width: 200,
                header: i18n.t('declarations.detail.elster_transferred'),
                clickable: true,
                sortable: true
            }, {
                field: 'internal_note',
                width: 600,
                header: i18n.t('declarations.grid.internal_note'),
                clickable: true,
                sortable: false
            }
        ]
    },
    {
        "id": "TRANSMITTED_DECLARATIONS_WITH_SETTLEMENT",
        "type": "LIST",
        "default_sort_attr": "declaration_uid",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": false,
        "columns": [
            {
                field: 'declaration_uid',
                width: 200,
                header: i18n.t('declarations.grid.id'),
                sortable: false
            }, {
                field: 'client_name',
                width: 300,
                header: i18n.t('declarations.grid.client'),
                sortable: false
            }, {
                field: 'client_uid',
                width: 300,
                header: i18n.t('declarations.grid.client_uid'),
                sortable: false
            }, {
                field: 'property_type',
                width: 300,
                header: i18n.t('components.ggw.columns.propertyType'),
                sortable: false
            }, {
                field: 'property_name',
                width: 200,
                header: i18n.t('components.ggw.columns.property'),
                sortable: false
            }, {
                field: 'declaration_number',
                width: 200,
                header: i18n.t('declarations.grid.declaration_number'),
                sortable: false
            }, {
                field: 'assigned_user_name',
                width: 200,
                header: i18n.t('declarations.grid.assigned_user'),
                sortable: false
            }, {
                field: 'property_value',
                width: 300,
                header: i18n.t('declarations.detail.tab1.property_value'),
                sortable: false
            }, {
                field: 'elster_transferred',
                width: 300,
                header: i18n.t('components.ggw.declaration_transmitted_time'),
                sortable: false
            }, {
                field: 'last_object_values_success',
                width: 300,
                header: i18n.t('components.ggw.object_transmitted_time'),
                sortable: false
            }
        ]
    },
    {
        "id": "DECLARATION_LIST_WITH_STAGE",
        "type": "LIST",
        "account_type": "tax_consultant",
        "default_sort_attr": "uid",
        "default_sort_order": 1,
        "show_search": false,
        "show_filters": false,
        "columns": [
            {
                field: 'uid',
                width: 200,
                header: i18n.t('declarations.grid.id'),
                clickable: true,
            }, {
                field: 'client_name',
                width: 300,
                header: i18n.t('declarations.grid.client'),
                filterLbl: i18n.t('clients.attrs.name') + ' ' + i18n.t('general.contains'),
                clickable: true,
            }, {
                field: 'property_name',
                width: 300,
                header: i18n.t('declarations.grid.address'),
                filterLbl: i18n.t('declarations.grid.address') + ' ' + i18n.t('general.contains'),
                clickable: true,
            }, {
                field: 'declaration_number',
                width: 200,
                header: i18n.t('declarations.grid.declaration_number'),
                sortable: false
            }, {
                field: 'assigned_user_name',
                width: 200,
                header: i18n.t('declarations.grid.assigned_user'),
                clickable: true,
            }, {
                field: 'branch',
                width: 200,
                header: i18n.t('declarations.grid.branch'),
                clickable: true,
                sortable: true
            }, {
                field: 'status',
                width: 200,
                header: i18n.t('declarations.grid.status'),
                clickable: true,
                sortable: true
            }
        ]
    },
];
