<template>
  <div class="h-full bg-white pl-7 w-full ">
    <PageHeader :title="$t('properties.title')" :breadcrumbs="breadcrumbs"/>

    <div class="w-full flex flex-col pr-7 ">
      <h2 class="my-4 text-lg font-semibold">{{ $t('properties.import.aareon.title') }}</h2>

      <div class="flex justify-start items-center bg-gray_light rounded-sm p-4 w-full">
        <img
          class="image"
          src="@/assets/info_gray.svg"
        />
        <label class="text ml-2">
          {{ $t('properties.import.aareon.info_1') }}<br/>
          <br/>
          {{ $t('properties.import.aareon.info_2') }}
        </label>
      </div>

      <div
        v-if="importedPropertyIds.length > 0"
        class="flex justify-start items-center bg-gray_light rounded-sm p-4 mt-4 w-full"
      >
        <img
          class="image"
          src="@/assets/success.svg"
        />
        <div class="text ml-2">
          <h4 class="text-lg font-semibold">
            {{ $t(
              'properties.import.aareon.import_result_success',
              {
                importedAmount: importedPropertyIds.length,
                totalAmount: importResult.result.properties_amount
              }
            ) }}
          </h4>
        </div>
      </div>

      <div
        v-if="showImportErrors"
        class="flex justify-start items-center bg-gray_light rounded-sm p-4 mt-4 w-full"
      >
        <img
          class="size-6"
          src="@/assets/warning.svg"
        />
        <div class="text ml-2">
          <h4 class="text-red text-lg font-semibold mb-2">
            {{ $t('properties.import.aareon.import_result_errors') }}
          </h4>

          <ul class="list-disc ml-6">
            <li
              v-for="(error, index) in importResult.result.errors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <div class="flex justify-around space-x-10 mt-6">
        <InputCheck
          :text="$t('properties.import.aareon.already_transfered')"
          :isChecked="alreadyTransfered"
          :showHelpIcon="true"
          :helpText="$t('properties.import.aareon.info_3')"
          @check="(isChecked) => alreadyTransfered = isChecked"
        />
      </div>

      <div class="flex justify-around space-x-10 mt-6">
        <InputSelect
          class="w-1/2"
          v-model="selectedClient"
          :label="$t('properties.step1.client')"
          :options="clientsOptionList"
          :search-fn="clientListSearch"
          :total-record-count="totalClients"
          :sort="true"
          :filterable="true"
          :isRequired=true
          placeholder=""
        />

        <InputUpload
          ref="uploadInput"
          class="w-1/2"
          :label="$t('properties.import.aareon.file_select')"
          @select="fileSelect"
          accept=".xls,.xlsx"
          :isRequired="true"
        />
      </div>

      <div class="flex justify-end space-x-4 mt-10 ">
        <Button
          class="w-52"
          :disabled="selectedFile === null || selectedClient === 0"
          :text="$t('properties.import.aareon.submit')"
          @click="submitNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InputCheck from '@/components/inputs/InputCheck';
import InputUpload from '@/components/inputs/InputUpload';

import { ClientService } from '@/services/client.service';
import { PropertyService } from '@/services/property.service';
import { S3Service } from '@/services/s3.service';

export default {
  name: 'AareonImport',
  components: {
    InputCheck,
    InputUpload
  },
  data() {
    return {
      propertyService: new PropertyService(),
      clientService: new ClientService(),
      s3Service: new S3Service(),
      breadcrumbs: [
        {
          title: this.$i18n.t('breadcrumbs.land_units'),
          link: ''
        }, {
          title: this.$i18n.t('breadcrumbs.add_property'),
          link: ''
        }
      ],

      clients: [],
      totalClients: 0,

      alreadyTransfered: false,
      selectedClient: null,
      selectedFile: null,

      importRunning: false,
      checkImportTimer: null,

      importResult: null,
      showImportErrors: false,
      showImportedProperties: false,
    }
  },
  mounted() {
    this.initClients();
  },
  computed: {
    clientsOptionList() {
      return this.generateClientOptionsListForClients(this.clients);
    },
    importedPropertyIds() {
      if (
        this.importResult === null
        || typeof this.importResult.result === 'undefined'
        || typeof this.importResult.result.imported_property_ids === 'undefined'
      ) {
        return [];
      }

      return Object.values(this.importResult.result.imported_property_ids);
    }
  },
  watch: {
    importRunning(newValue) {
      if (newValue) {
        this.$loading.show();
        this.checkImportTimer = null;
        return;
      }

      this.$loading.hide();

      this.$refs.uploadInput.clear();
    },
    importResult(newValue) {
      if (newValue === null) {
        this.showImportErrors = false;
        this.showImportedProperties = false;
        return;
      }

      let errors = Object.values(newValue.result.errors);
      if (errors.length > 0) {
        this.showImportErrors = true;
      }

      if (this.importedPropertyIds.length > 0) {
        this.showImportedProperties = true;
      }
    },
  },
  methods: {
    ...mapGetters('property', ['getFilePath']),
    async initClients() {
      let clients = await this.fetchClients();

      if (clients && clients.list) {
        this.clients = clients.list;
      }

      if (clients && clients.totalRecordCount) {
        this.totalClients = clients.totalRecordCount;
      }
    },
    fetchClients(params = []) {
      return this.clientService.listUserClient('current', params).then((clients) => {
        return clients;
      });
    },
    async clientListSearch(params) {
      let clients = await this.fetchClients(params);
      if (clients && clients.list) {
        return this.generateClientOptionsListForClients(clients.list);
      }

      return false;
    },
    generateClientOptionsListForClients(clients) {
      return clients.map((o) => {
        return {
          name: o.client_id + ' - ' + o.client_name,
          code: o.prim_uid
        }
      });
    },
    async uploadFile() {
      this.importRunning = true;

      this.importResult = null;

      this.s3Service.upload({
        data: this.selectedFile,
        fileName: 'property_data_aareon.xlsx',
        prefix: 'properties/',
        contentType: this.selectedFile.type
      })
        .then((uploadResponse) => {
          if (uploadResponse.success !== true) {
            this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
            return;
          }

          this.propertyService.aareonImport(
            this.selectedClient,
            uploadResponse.s3Path,
            this.alreadyTransfered
          )
            .then((response) => {
              if (response.data.success !== true ) {
                this.$api.showToast(response.data.message, 'error');
                return;
              }

              this.checkImportTimer = setTimeout(() => {
                this.checkImportStatus(response.data.jobId);
              }, 10000);
            })
            .catch((error) => {
              this.$api.showToast(error, 'error');
            })
            .finally(() => {
              if (this.checkImportTimer === null) {
                this.importRunning = false;
              }
            });
        })
        .catch(() => {
          this.importRunning = false;
          this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
        });
    },
    checkImportStatus(jobId) {
      this.importRunning = true;
      this.importResult = null;

      this.$api.trigger(
        'property/check_import_status',
        {jobId: jobId}
      )
        .then((response) => {
          if (response.data.success !== true) {
            this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
            return;
          }

          if (response.data.status !== 'completed') {
            this.checkImportTimer = setTimeout(() => {
              this.checkImportStatus(jobId);
            }, 10000);
            return;
          }

          this.checkImportTimer = null;

          if (typeof response.data.importResult === 'undefined') {
            this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
            return;
          }

          this.importResult = response.data.importResult;
          if (this.importResult.success !== true) {
            this.$api.showToast(this.importResult.message ?? this.$t('general.errors.unknownError'), 'error');
            return;
          }
        })
        .finally(() => {
          if (this.checkImportTimer === null) {
            this.importRunning = false;
          }
        });
    },
    submitNext() {
      this.uploadFile();
    },
    cancelImport() {
      this.$router.go(-1)
    },
    fileSelect(e) {
      if (e.files.length > 0) {
        let file = e.files[0];

        this.selectedFile = file;
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
