<template>
  <div>
    <Dialog
      :modal="true"
      :visible.sync="isVisible"
      :contentStyle="{ flex: '1' }"
    >
      <template #header>
        <h3 class="text-xl font-bold">
          {{ $t('settings.billing.cancellation.title') }}
        </h3>
      </template>

      <div v-if="step === 1">
        <div>
          <img
            class="w-1/5 m-auto mb-8"
            :src="getAssetPath('plan_cancellation_intro_emoji.svg')"
          />
        </div>

        <div>
          <h4 class="text-3xl font-semibold whitespace-pre-line mb-12">{{ $t('settings.billing.cancellation.intro.title') }}</h4>
          <p class="text-lg font-semibold">{{ $t('settings.billing.cancellation.intro.subtitle') }}</p>
        </div>
      </div>

      <div
        v-else-if="step === 2"
        class="text-left"
      >
        <h4 class="font-semibold text-3xl mb-4 text-orange">{{ $t('settings.billing.cancellation.did_you_know.title') }}</h4>
        <p class="font-semibold text-lg mb-4">{{ $t('settings.billing.cancellation.did_you_know.info') }}</p>

        <ul class="text-lg mx-6">
          <li
            v-for="(advantage, index) in $t('settings.billing.cancellation.did_you_know.advantages')"
            :key="index"
            class="mb-6"
          >
            <div class="flex items-start">
              <img
                class="ul-icon flex-none inline-block mr-2"
                :src="require('@/assets/arrow-right-orange.svg')"
              />
              <label
                class="flex-1 text-left"
                v-html="advantage.label"
              />
            </div>

            <ul
              v-if="advantage.list"
              class="list-disc mx-12"
            >
              <li v-for="listEntry in advantage.list">{{ listEntry }}</li>
            </ul>
          </li>
        </ul>
      </div>

      <div
        v-else-if="step === 3 && choseAppointment === false"
        class="text-left"
      >
        <h4 class="font-semibold text-3xl mb-8 text-orange">{{ $t('settings.billing.cancellation.phone_callback.title') }}</h4>

        <div class="flex items-center mb-8">
          <img
            class="phone-icon flex-none inline-block mr-4"
            :src="require('@/assets/phone.svg')"
          />
          <p class="flex-1 text-lg">
            {{ $t('settings.billing.cancellation.phone_callback.line1') }}<br/>
            {{ $t('settings.billing.cancellation.phone_callback.line2') }}
          </p>
        </div>

        <div class="flex justify-start items-center bg-gray_light rounded-sm p-4 w-full">
          <img
            class="image"
            src="@/assets/info_gray.svg"
          />
          <label class="text ml-2">
            {{ $t('settings.billing.cancellation.phone_callback.info') }}
          </label>
        </div>
      </div>

      <div
        v-else-if="step === 3 && choseAppointment"
        class="px-8"
      >
        <div>
          <img
            v-if="$isBusiness"
            class="w-1/6 m-auto mt-10 mb-8"
            :src="getAssetPath('calendar_yellow_check.svg')"
          />
          <img
            v-else
            class="w-1/6 m-auto mt-10 mb-8"
            :src="getAssetPath('calendar_check.svg')"
          />
        </div>

        <div>
          <h4 class="text-2xl font-semibold mb-6">{{ $t('settings.billing.cancellation.appointment.title') }}</h4>
          <p class="text-lg">{{ $t('settings.billing.cancellation.appointment.subtitle') }}</p>
        </div>

        <Button
          v-show="choseAppointment"
          class="items-center mt-12"
          :text="$t('buttons.next')"
          @click="closeModal"
        />
      </div>

      <div
        v-if="step === 4"
        class="text-left"
      >
        <h4 class="font-semibold text-4xl mb-8 text-red">{{ $t('settings.billing.cancellation.losing_data.title') }}</h4>

        <p class="text-lg mb-6">{{ $t('settings.billing.cancellation.losing_data.subtitle') }}</p>

        <div>
          <img
            class="w-1/3 m-auto mb-6"
            :src="getAssetPath('losing_data_warning.svg')"
          />
        </div>

        <div class="text-center mb-12">
          <p class="font-semibold text-lg">{{ $t('settings.billing.cancellation.losing_data.icon_title') }}</p>
        </div>

        <p
          v-html="$t('settings.billing.cancellation.losing_data.info')"
          class="text-lg text-center mb-6"
        />
      </div>

      <div v-if="step === 5">
        <h4 class="font-semibold text-4xl mb-8 text-orange">{{ $t('settings.billing.cancellation.reason.title') }}</h4>

        <div class="flex flex-col mt-5">
          <InputSelect
            class="mt-2 w-full"
            :label="$t('settings.billing.cancellation.reason.reason_label')"
            :options="$t('settings.billing.cancellation.reason.reasons')"
            :isError="terminationReasonError"
            :errorMessage="$t('general.errors.required')"
            :isRequired="true"
            v-model="terminationReason"
            :selectedValue="terminationReason"
            placeholder=""
          />

          <InputText
            class="mt-5 w-full"
            :label="$t('settings.billing.cancellation.reason.reason_text_label')"
            bodyClass="non-resize-textarea w-full"
            v-model="terminationReasonText"
            :isMultiline="true"
            :isRequired="isTerminationReasonRequired"
            :infoText="terminationReasonTextInfo"
            :maxLength="9999"
            placeholder=""
          />
        </div>
      </div>

      <div
        v-if="step === 6"
        class="text-left"
      >
        <h4 class="font-semibold text-4xl mb-8 text-orange">{{ $t('settings.billing.cancellation.confirmation.title') }}</h4>

        <div class="text-lg">
          <p class="mb-5">
            <span v-html="$t('settings.billing.cancellation.confirmation.line1')"/>
          </p>

          <p>
            <span v-html="$t('settings.billing.cancellation.confirmation.line2')"/>
          </p>
        </div>

        <div class="flex flex-col mt-5">
          <InputText
            class="mt-5 w-full"
            bodyClass="non-resize-textarea w-full"
            v-model="terminationConfirmation"
            :maxLength="13"
            placeholder=""
          />
        </div>
      </div>

      <div v-if="step === 7">
        <div>
          <img
            v-if="$isBusiness"
            class="w-1/6 m-auto mt-10 mb-8"
            :src="getAssetPath('success-yellow.svg')"
          />

          <img
            v-else
            class="w-1/6 m-auto mt-10 mb-8"
            :src="getAssetPath('success.svg')"
          />
        </div>

        <div>
          <h4 class="text-2xl font-semibold mb-6">{{ $t('settings.billing.cancellation.success.title') }}</h4>
          <p
            class="text-lg mb-6"
            v-html="$t('settings.billing.cancellation.success.info')"
          />
          <p
            class="text-lg"
            v-html="$t('settings.billing.cancellation.success.explanation')"
          />
        </div>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <Button
            v-show="step > 1 && ![3, 7].includes(step)"
            class="mb-4 mr-4"
            :text="$t('settings.billing.cancellation.buttons.keep')"
            @click="closeModal"
          />

          <Button
            v-show="!choseAppointment && ![6, 7].includes(step)"
            class="mb-4 mr-4"
            :text="continueButtonLabel"
            :secondary="true"
            :disabled="step === 5 && (terminationReasonError || terminationReasonTextError)"
            @click="continueProcess"
          />

          <Button
            v-show="step === 3 && choseAppointment === false"
            class="mb-4 mr-4"
            :text="$t('settings.billing.cancellation.buttons.appointment')"
            @click="makeAppointment"
          />

          <Button
            v-show="step === 6"
            class="mb-4 mr-4"
            :text="$t('settings.billing.cancellation.buttons.terminate')"
            :secondary="true"
            :disabled="terminationConfirmation !== 'Daten löschen'"
            @click="terminateAccount"
          />

          <Button
            v-show="step === 7"
            class="mb-4 mr-4"
            :text="continueButtonLabel"
            @click="closeModal"
          />
        </div>
      </template>
    </Dialog>

    <NotEligibleForTerminationModal
      :visible="showNotEligibleForTerminationModal"
      :terminationDate="terminationDate"
      @close="showNotEligibleForTerminationModal = false"
    />
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';

import NotEligibleForTerminationModal from '@/components/Billing/NotEligibleForTerminationModal.vue';

import { BillingService } from '@/services/billing.service';

export default {
  name: 'PlanCancellationModal',
  emits: ['close', 'continue'],
  components: {
    Dialog,
    NotEligibleForTerminationModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      billingService: new BillingService(),

      step: 1,
      choseAppointment: false,
      terminationReason: '-',
      terminationReasonText: '',
      terminationConfirmation: '',

      showNotEligibleForTerminationModal: false,
      terminationDate: '',
    }
  },
  mounted() {
    this.billingService.get().then((response) => {
      if (response.data.success) {
        let planDetail = response.data.data;
        this.terminationDate = planDetail.end_date;
      }
    });
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (value === false) {
          this.step = 1;
          this.choseAppointment = false;
          this.terminationReason = '-';
          this.terminationReasonText = '';
          this.terminationConfirmation = '';
        }

        this.$emit('close', value);
      },
    },
    isTerminationReasonRequired() {
      if (this.terminationReason === 'no_reason') {
        return false;
      }

      return true;
    },
    terminationReasonError() {
      if (this.terminationReason === '-') {
        return true;
      }

      return false;
    },
    terminationReasonTextError() {
      if (this.terminationReason === '-') {
        return false;
      }

      if (!this.isTerminationReasonRequired) {
        return false;
      }

      if (this.terminationReasonText.length >= 100) {
        return false;
      }

      return true;
    },
    terminationReasonTextInfo() {
      let infoText = this.$t('general.errors.limitDesc').replace('%length%', this.terminationReasonText.length).replace('%maxLimit%', 9999);
      let errorText = this.$t('settings.billing.cancellation.reason.reason_text_error');

      if (this.terminationReasonTextError) {
        return errorText + ' – ' + infoText;
      }

      return infoText;
    },
    continueButtonLabel() {
      if (this.step === 3) {
        return this.$t('settings.billing.cancellation.buttons.no_appointment');
      }

      return this.$t('buttons.next');
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    continueProcess() {
      this.step++;
    },
    makeAppointment() {
      const bookingUrl = this.$t('settings.billing.cancellation.phone_callback.booking_url');
      window.open(bookingUrl, '_blank');

      this.choseAppointment = true;
    },
    terminateAccount() {
      this.billingService.after_end_term({
        status: 'termination',
        after_plan_uid: '',
        termination_reason_category: this.terminationReason,
        termination_reason: this.terminationReasonText,
        plan_uid: this.getCurrentUser().plan_uid
      }, true).then(async (response) => {
        if (response.data.success) {
          if (typeof response.data.endDate != 'undefined' && response.data.endDate != '') {
            this.terminationDate = response.data.endDate;
          }

          if (response.data.error_key === 'not_eligible_for_termination') {
            this.showNotEligibleForTerminationModal = true;
            this.closeModal();
            return;
          }

          if (response.data.error_key === 'already_terminated') {
            this.$api.showToast(
              this.$t('settings.billing.cancellation.not_eligible.already', {date: this.terminationDate}),
              'error'
            );
            this.closeModal();
            return;
          }

          this.continueProcess();
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  max-width: 960px;
  min-height: 640px;

  .p-dialog-content {
    @include segoe-regular;
    color: theme('colors.muted_black');

    height: 100%;
    width: 100%;
    overflow: scroll;

    .ul-icon {
      height: 15px;
      margin-top: 6px;
      width: 18px;
    }

    .phone-icon {
      width: 50px;
    }
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
