<template>
  <Dialog
    :modal="true"
    :visible.sync="isVisible"
    :contentStyle="{ flex: '1' }"
  >
    <template #header>
      <h3 class="text-xl font-bold">
        {{ $t('properties.move_parcels.title') }}
      </h3>
    </template>

    <div class="text-left text-lg flex flex-col">
      <div class="w-full">
        <div class="flex w-fit justify-start items-center text-sm p-3 my-4 mx-auto bg-gray_light rounded">
          <img
            class="image"
            :src="getAssetPath('info_gray.svg')"
          />
          <label
            class="text-left ml-3"
            v-html="$t('properties.move_parcels.info')"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full">
      <InputSelect
        class="w-full"
        :isCategory="true"
        :label="$t('properties.move_parcels.to_property')"
        :options="propertyList"
        v-model="selectedPropertyUid"
        :selectedValue="selectedPropertyUid"
        :filterable="true"
        placeholder=""
      />
    </div>
    <template v-slot:footer>
      <div class="flex justify-end space-x-6 w-full">
        <Button
          :text="$t('buttons.cancel')"
          :secondary="true"
          @click="isVisible = false"
        />
        <Button
          class="mb-4"
          :text="$t('properties.move_parcels.buttons.move_parcels', {amount: parcelUids.length})"
          @click="movePropertyParcels"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import { PropertyService } from '@/services/property.service';

export default {
  name: 'MoveLandAndForestryParcelModal',
  emits: ['close'],
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    fromPropertyUid: {
      type: Number,
      default: 0
    },
    propertyFederalStateId: {
      type: Number,
      default: 0
    },
    parcelUids: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      propertyService: new PropertyService(),
      properties: [],
      selectedPropertyUid: 0
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.show();
        return;
      }

      this.selectedPropertyUid = 0;
    }
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        this.$emit('close', value);
      },
    },
    propertyList() {
      let propertiesSortedByClient = this.properties.sort((firstProperty, secondProperty) => {
        return firstProperty.client_name.localeCompare(secondProperty.client_name);
      });

      let propertyList = [];
      propertiesSortedByClient.forEach((property) => {
        let lastPropertyInList = propertyList[propertyList.length - 1];

        if (
          lastPropertyInList === undefined
          || lastPropertyInList.client_uid !== property.client_uid
        ) {
          propertyList.push({
            name: property.client_name,
            code: '',
            client_uid: property.client_uid,
          });
        }

        propertyList.push({
          name: `${property.property_id} - ${property.name}`,
          code: property.prim_uid,
          client_uid: property.client_uid,
        });
      });

      return propertyList;
    }
  },
  methods : {
    show() {
      this.fetchProperties();
    },
    fetchProperties() {
      this.propertyService.listAll({
        federal_state_uids: [this.propertyFederalStateId],
        economic_entity_types: [3], // LuF
        sortField: 'name',
        rows: -1
      }).then((response) => {
        if (typeof response === 'undefined' || typeof response.list === 'undefined') {
          this.properties = [];
          return;
        }

        this.properties = response.list.filter((property) => {
          if (parseInt(property.prim_uid) === this.fromPropertyUid) {
            return false;
          }

          return true;
        });
      });
    },
    movePropertyParcels() {
      this.propertyService.moveLandAndForestryParcels(
        this.fromPropertyUid,
        this.selectedPropertyUid,
        this.parcelUids
      ).then((response) => {
        if (response.success) {
          if (typeof response.totalMovedParcelsCount !== 'undefined') {
            this.$api.showToast(
              this.$t('properties.move_parcels.move_parcel_ok', {amount: response.totalMovedParcelsCount})
            );
          }

          this.isVisible = false;
          return;
        }

        if (typeof response.message !== 'undefined') {
          this.$api.showToast(response.message, 'error');
          return;
        }

        this.$api.showToast(this.$t('general.errors.unknownError'), 'error');
      });
    },
  }
}
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  max-width: 960px;
  min-height: 640px;

  .p-dialog-content {
    @include segoe-regular;
    color: theme('colors.muted_black');

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}
</style>
