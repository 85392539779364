<template>
  <div>
    <Dialog
      :modal="true"
      :visible.sync="isVisible"
      :contentStyle="{flex: '1'}"
    >
      <template #header>
        <h3 class="text-xl font-bold">
          {{ $t('declarations.detail.elster_message.attachments_view_title') }}
        </h3>
      </template>

      <Listbox
        :options="elsterMessageAttachmentsOptionsList"
        optionLabel="name"
        @input="viewFile"
      >
        <template #option="slotProps">
          <div class="flex flex-row justify-between">
            <span>{{ slotProps.option.name }}</span>
            <img
              src="@/assets/eye_green.svg"
              class="image ml-2"
            />
          </div>
        </template>
      </Listbox>

      <template v-slot:footer>
        <div class="flex flex-row-reverse gap-3">
          <Button
            :text="$t('buttons.cancel')"
            :secondary="true"
            @click="isVisible = false"
          />
        </div>
      </template>
    </Dialog>

    <ElsterMessageAttachmentDetailsModal
      :visible="showElsterMessageAttachmentDetailsModal"
      :viewFileData="elsterMessageAttachmentDetailsModalFileData"
      @close="closeElsterMessageAttachmentDetailsModal"
    />
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';

import ElsterMessageAttachmentDetailsModal from '@/components/declarations/components/ElsterMessageAttachmentDetailsModal.vue';

import { DeclarationService } from '@/services/declaration.service';

export default {
  name: 'ElsterMessageAttachmentsModal',
  emits: ['close'],
  components: {
    Dialog,
    Listbox,
    ElsterMessageAttachmentDetailsModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    declarationUid: {
      type: Number,
      default: 0
    },
    elsterMessageId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      declarationService: new DeclarationService(),
      files: [],
      showElsterMessageAttachmentDetailsModal: false,
      elsterMessageAttachmentDetailsModalFileData: null
    }
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        this.$emit('close', value);
      }
    },
    elsterMessageAttachmentsOptionsList() {
      return this.files.map((file) => {
        let formattedSize = this.formatBytes(file.size, 'mb', 2);

        return {
          code: file.recordId,
          name: `${file.name} (${formattedSize})`,
          file: file,
        }
      });
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.show();
      }
    },
  },
  methods: {
    show() {
      this.files = [];

      this.declarationService.get_elster_message(this.declarationUid, this.elsterMessageId).then((elsterMessage) => {
        if (elsterMessage === false) {
          return;
        }

        if (typeof elsterMessage.details.attachments === 'undefined') {
          return;
        }

        Object.values(elsterMessage.details.attachments).forEach((attachment) => {
          this.files.push({
            name: attachment.description,
            parentId: this.declarationUid,
            recordId: this.elsterMessageId,
            size: attachment.size,
            uuid: attachment.fileName
          });
        });
      });
    },
    formatBytes(bytes, to, decimals) {
      const byteBase = 1024;

      var regex = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimals || -1) + '})?');

      if (to === 'kb') {
        return (bytes / byteBase).toString().replace('.', ',').match(regex)[0] + 'kB';
      } else if (to === 'mb') {
        return (bytes / byteBase ** 2).toString().replace('.', ',').match(regex)[0] + 'MB';
      }  else {
        return bytes;
      }
    },
    viewFile(option) {
      this.elsterMessageAttachmentDetailsModalFileData = option.file;
      this.showElsterMessageAttachmentDetailsModal = true;
    },
    closeElsterMessageAttachmentDetailsModal() {
      this.showElsterMessageAttachmentDetailsModal = false;
      this.elsterMessageAttachmentDetailsModalFileData = null;
    }
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dialog) {
  height: fit-content;
  max-height: 95vh;
  max-width: 80vw;
  min-width: 500px;
  width: fit-content;

  .p-dialog-content {
    @include segoe-regular;

    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .p-dialog-footer {
    @apply p-3;
  }
}

:deep(.p-listbox) {
  border-color: theme('colors.gray_df');

  .p-listbox-filter {
    border-color: theme('colors.gray_df');

    @apply rounded;

    &:focus {
      border-width: 1px;
    }
  }

  .p-listbox-item {
    &:focus {
      box-shadow: none;
    }

    &.p-highlight {
      background-color: theme('colors.green');
      color: theme('colors.white')
    }
  }
}
</style>
