<template>
  <MainLayout value="documents" v-if="!verifyingAccess">
    <template>
      <component :is="comp" @click="handleComponentClick"/>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import list from "@/components/documents/list";
import upload_notice from "@/components/documents/uploadNotice";
import review_notice from "@/components/documents/reviewNotice"
import market_value_assessment from '@/components/documents/MarketValueAssessment';
import {appMixin} from "@/core/utils/mixin";
import {mapGetters} from "vuex";

export default {
  name: "Documents",
  components: {MainLayout, list, upload_notice, review_notice, market_value_assessment},
  mixins: [appMixin],
  data() {
    return {}
  },
  computed: {
    comp() {
      return this.$route.params.comp
    }
  },
  created() {
    this.verifyingAccess = true;
    if((this.$route.params.comp === 'review_notice' || this.$route.params.comp === 'upload_notice') && !(this.getCurrentUser().plan_features && Boolean(this.getCurrentUser().plan_features.feature_mass_notice_review))) {
      this.showPackageLimitWarning();
      this.$router.push('/home');
      return false;
    }

    this.checkAccess('documents');
  },
  methods: {
    ...mapGetters("user", ["getCurrentUser"]),
    handleComponentClick(data) {

    }
  }
}
</script>

<style scoped lang="scss">

</style>
